import { Component, inject, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subject } from 'rxjs';
import {
	DadosAgrupamento,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
	RelatorioVECResponse,
} from '../../../interfaces';
import { RelatoriosService } from '../../../services';
import { Content, TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';
import { corrigeDuplicacaoNome } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';

@Component({
	selector: 'seira-tabela-vec',
	templateUrl: './tabela-vec.component.html',
	styleUrls: ['./tabela-vec.component.scss'],
})
export class TabelaVecComponent implements OnDestroy, InstanciaRelatorio {
	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloTabela = 'Valores extremos de chuva';
	tituloColunaAgrupamento = 'Agrupamento';
	descricaoRelatorio =
		'Os valores extremos de chuva se referem aos valores máximos de chuva registrados de um determinado período, local ou região.';

	carregando = true;
	disabledSelectList = false;

	colunasVEC: ADTColumns[] = [];
	resultados: RelatorioVECResponse[] = [];
	colunasSelecionadas: string[] = [];

	periodoTitulo = '';

	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.carregando = true;
		this.inputs.setLoading(true);

		this.colunasVEC = [];
		this.resultados = [];
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		);

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};

		this.relatoriosService.buscarRelatorioVECPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				this.resultados = ndcAgrupados;
				this.atualizarTitulosTabela();
				this.altualizarColunasTabelaVEC();
			},
			error: err => {
				this.toastrService.error(
					'Erro ao gerar tabela de Volumes Extremos de Chuva, tente novamente'
				);
				this.carregando = false;
				this.inputs.setLoading(false);
			},
			complete: () => {
				this.inputs.setLoading(false);
				this.carregando = false;
			},
		});
	}

	altualizarColunasTabelaVEC() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-left',
					render: (nome: string) => corrigeDuplicacaoNome(nome),
				},

				{
					data: 'id',
					title: 'Id',
					type: 'number',
					className: 'text-center',
				},

				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (latitude: number) => latitude ?? '-',
				},

				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (longitude: number) => longitude ?? '-',
				},

				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (altitude: number) => altitude ?? '-',
				},

				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (regiao: string) => (regiao !== '' ? regiao : '-'),
				},

				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (bacia: string) => (bacia !== '' ? bacia : '-'),
				},

				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
					render: (subbacia: string) => (subbacia !== '' ? subbacia : '-'),
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunasVEC = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunasVEC = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-center',
					render: (nome: string) => corrigeDuplicacaoNome(nome),
				},
			];
		}

		this.colunasVEC.push(
			...[
				{
					data: 'dataColeta',
					title: 'Data',
					type: 'text',
					className: 'text-center',
					render: (dataColeta: string) => {
						return format(new Date(dataColeta), 'dd/MM/yyyy');
					},
				},
				{
					data: 'precipitacaoMaxima',
					title: 'Precipitação Máxima (mm)',
					className: 'text-center',
					render: (precipitacao: number) => {
						return precipitacao
							? precipitacao.toFixed(1).replace('.', ',')
							: '-';
					},
				},
			]
		);
	}

	atualizarTitulosTabela() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				this.tituloColunaAgrupamento = 'Município/Posto';
				break;
			case 'MICRORREGIAO':
				this.tituloColunaAgrupamento = 'Microrregião';
				break;
			case 'MUNICIPIO':
				this.tituloColunaAgrupamento = 'Município';
				break;
			case 'MESORREGIAO':
				this.tituloColunaAgrupamento = 'Mesorregião';
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.tituloColunaAgrupamento = 'Região Pluviométrica';
				break;
			case 'BACIA':
				this.tituloColunaAgrupamento = 'Bacia';
				break;
			case 'SUB_BACIA':
				this.tituloColunaAgrupamento = 'Sub bacia';
				break;
		}
	}

	async exportarPDF() {
		let documentDefinition: TDocumentDefinitions;

		const content: Content[] = [
			{
				text: `Relatório - ${this.tituloTabela} - ${this.periodoTitulo}`,
				fontSize: 12,
				alignment: 'center',
				margin: [0, 0],
			},
		];

		if (!this.resultados) {
			content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 0],
			});
		}
		const tableData: (string | number | TableCell)[][] = [];

		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			tableData.push([
				{
					text: this.tituloColunaAgrupamento,
					fillColor: '#DCDCDC',
				},
				{
					text: 'Data',
					fillColor: '#DCDCDC',
				},
				{
					text: 'Precipitação máxima (mm)',
					fillColor: '#DCDCDC',
				},
				...this.colunasSelecionadas
					.filter(coluna => coluna !== this.tituloColunaAgrupamento)
					.map<TableCell>(value => ({ text: value, fillColor: '#DCDCDC' })),
			]);

			const dynamicColumns = [
				...new Set(
					this.resultados.flatMap(dado =>
						Object.keys(dado).filter(key =>
							this.colunasVEC.some(
								column =>
									column.data === key &&
									column.title !== this.tituloColunaAgrupamento
							)
						)
					)
				),
			];

			this.resultados.forEach(dadoRelatorio => {
				const dynamicValues = Object.entries(dadoRelatorio)
					.map(([key, value]) => {
						if (dynamicColumns.includes(key)) {
							if (value === null || value === '') {
								return '-';
							}
							if (!isNaN(value)) {
								if (
									key !== 'latitude' &&
									key !== 'longitude' &&
									key !== 'altitude'
								) {
									return value.toFixed(1).replace('.', ',');
								}
								return Number(value).toString().replace('.', ',');
							}
							try {
								return format(new Date(value), 'dd/MM/yyyy');
							} catch (error) {
								/* empty */
							}
							return value;
						}
					})
					.filter(dados => dados !== undefined);

				tableData.push([
					corrigeDuplicacaoNome(dadoRelatorio.nome),
					...dynamicValues,
				]);
			});
			documentDefinition = await pdfseira.documentDefinitions(
				'landscape',
				this.colunasSelecionadas.length > 8 ? 'A3' : 'A4'
			);
		} else {
			tableData.push([
				{
					text: this.tituloColunaAgrupamento,
					fillColor: '#DCDCDC',
				},
				{
					text: 'Data',
					fillColor: '#DCDCDC',
				},
				{
					text: 'Precipitação máxima (mm)',
					fillColor: '#DCDCDC',
				},
			]);

			this.resultados.forEach(dadoRelatorio => {
				tableData.push([
					corrigeDuplicacaoNome(dadoRelatorio.nome),
					format(new Date(dadoRelatorio.dataColeta), 'dd/MM/yyyy'),
					dadoRelatorio.precipitacaoMaxima.toFixed(1).replace('.', ','),
				]);
			});
			documentDefinition = await pdfseira.documentDefinitions('portrait');
		}

		content.push({
			table: {
				body: tableData,
			},
			margin: [0, 15, 0, 0],
		});

		documentDefinition.content = content;
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData: (string | number)[][] = [];

		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			tableData.push([
				this.tituloColunaAgrupamento,
				'Data',
				'Precipitação máxima (mm)',
				...this.colunasSelecionadas.filter(
					coluna => coluna !== this.tituloColunaAgrupamento
				),
			]);

			const dynamicColumns = [
				...new Set(
					this.resultados.flatMap(dado =>
						Object.keys(dado).filter(key =>
							this.colunasVEC.some(
								column =>
									column.data === key &&
									column.title !== this.tituloColunaAgrupamento
							)
						)
					)
				),
			];

			this.resultados.forEach(dadoRelatorio => {
				const dynamicValues = Object.entries(dadoRelatorio)
					.map(([key, value]) => {
						if (value === null || value === '') {
							return '-';
						}
						if (dynamicColumns.includes(key)) {
							if (!isNaN(value)) {
								if (
									key !== 'latitude' &&
									key !== 'longitude' &&
									key !== 'altitude'
								) {
									return value.toFixed(1).replace('.', ',');
								}
								return Number(value).toString().replace('.', ',');
							}
							try {
								return format(new Date(value), 'dd/MM/yyyy');
							} catch (error) {
								/* empty */
							}
							return value;
						}
					})
					.filter(dados => dados !== undefined);

				tableData.push([
					corrigeDuplicacaoNome(dadoRelatorio.nome),
					...dynamicValues,
				]);
			});
		} else {
			tableData.push([
				this.tituloColunaAgrupamento,
				'Data',
				'Precipitação máxima (mm)',
			]);

			this.resultados.forEach(dadoRelatorio => {
				tableData.push([
					corrigeDuplicacaoNome(dadoRelatorio.nome),
					format(new Date(dadoRelatorio.dataColeta), 'dd/MM/yyyy'),
					dadoRelatorio.precipitacaoMaxima.toFixed(1).replace('.', ','),
				]);
			});
		}

		DocumentExporter.gerarCSV(tableData, this.tituloTabela);
	}

	exportarTXT() {
		let txtData = '';

		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.resultados.forEach(dadoRelatorio => {
				txtData += `${this.tituloColunaAgrupamento}: ${corrigeDuplicacaoNome(
					dadoRelatorio.nome
				)}\n`;

				txtData += this.colunasVEC
					.flatMap(coluna => {
						const foundedData = Object.entries(dadoRelatorio)
							.map(([key, value]) => {
								if (
									key === coluna.data &&
									coluna.title !== this.tituloColunaAgrupamento
								) {
									if (value === null || value === '') {
										return `${coluna.title}: - \n`;
									}
									if (!isNaN(value)) {
										if (
											key !== 'latitude' &&
											key !== 'longitude' &&
											key !== 'altitude'
										) {
											return `${coluna.title}: ${value
												.toFixed(1)
												.replace('.', ',')} \n`;
										}
										return `${coluna.title}: ${Number(value)
											.toString()
											.replace('.', ',')} \n`;
									}
									try {
										return `${coluna.title}: ${format(
											new Date(value),
											'dd/MM/yyyy'
										)} \n`;
									} catch (error) {
										/* empty */
									}
									return `${coluna.title}: ${value === '' ? '-' : value} \n`;
								}
								return '';
							})
							.filter(value => value !== undefined && value !== '');
						return foundedData;
					})
					.reduce((prev, curr) => prev + curr);
				txtData += '\n\n';
			});
		} else {
			this.resultados.forEach(dadoRelatorio => {
				txtData +=
					`${this.tituloColunaAgrupamento}: ${corrigeDuplicacaoNome(
						dadoRelatorio.nome
					)}\n` +
					`Data: ${format(
						new Date(dadoRelatorio.dataColeta),
						'dd/MM/yyyy'
					)}\n` +
					`Precipitação máxima (mm): ${dadoRelatorio.precipitacaoMaxima
						.toFixed(1)
						.replace('.', ',')}\n\n`;
			});
		}

		DocumentExporter.gerarTXT(txtData, this.tituloTabela);
	}
}
