import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { TableComponent } from '@componentes/table/table.component';
import {
	Estacao,
	Municipio,
} from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	PostosRelatorios,
	RelatorioPrecipitacaoAcumulada,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subscription } from 'rxjs';

@Component({
	selector: 'seira-pluviometro-tabela-precipitacao-acumulada',
	templateUrl: './pluviometro-tabela-precipitacao-acumulada.component.html',
	styleUrls: ['./pluviometro-tabela-precipitacao-acumulada.component.scss'],
})
export class PluviometroTabelaPrecipitacaoAcumuladaComponent
	implements InstanciaRelatorio, OnDestroy
{
	descricaoRelatorio =
		'Define-se como precipitação acumulada a soma da quantidade de chuva registrada em um período específico, como semanas ou meses, em uma determinada área.';

	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaPrecipitacaoAcumulada?: RelatorioPrecipitacaoAcumulada;
	carregandoRelatorio = true;
	private subscription = new Subscription();
	microrregioes: Select[] = [];
	periodo = '';
	@ViewChild('tabela-precipitacao-acumulada') tabela!: TableComponent;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportarPDF(this.dadosTabelaPrecipitacaoAcumulada!);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV(this.dadosTabelaPrecipitacaoAcumulada!);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT(this.dadosTabelaPrecipitacaoAcumulada!);
			},
		},
	];

	form?: FormGroup<any> | undefined;
	regioes?: Select<string>[] | undefined;
	mesorregioes?: Select<string>[] | undefined;
	municipios?: Municipio[] | undefined;
	precipitacao?: Select<string>[] | undefined;
	agrupamento?: Select<string>[] | undefined;

	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		this.setValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.subscription.unsubscribe();
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}

	public gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}

		this.carregandoRelatorio = true;
		this.inputs.setLoading(true);
		const postosIds = this.postos.map(posto => posto.id);

		const dataFim = this.getFormItemValue('periodo');

		this.periodo = DateTimeUtils.formatarDataPeriodo(dataFim, '', '', true);

		this.relatoriosService
			.relatorioPrecipitacaoAcumulada(postosIds, null, dataFim.toISOString())
			.subscribe({
				next: prec => {
					this.dadosTabelaPrecipitacaoAcumulada = prec;
					this.dadosTabelaPrecipitacaoAcumulada.dataBusca = dataFim;
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.carregandoRelatorio = false;
				},
			});
	}

	getPeriodo() {
		return DateTimeUtils.formatarDataPeriodo(
			new Date(this.inputs.form.get('periodo')?.value),
			'',
			'anual',
			true
		);
	}

	async exportarPDF(dados: RelatorioPrecipitacaoAcumulada) {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Relatório - Precipitação acumulada (mm) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dados) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Posto',
			'Tipo de Estação',
			'Precipitação  (mm)',
		]);
		dados.chuvasAcumuladas.forEach((dadoRelatorio: any) => {
			tableData.push([
				dadoRelatorio.detalhesPosto.municipio,
				dadoRelatorio.detalhesPosto.posto,
				dadoRelatorio.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
					? 'Pluviômetro convencional'
					: 'PCD',
				isNotNuloOuUndefined(dadoRelatorio.precipitacaoAcumulada)
					? numberToBrNumber(dadoRelatorio.precipitacaoAcumulada)
					: '-',
			]);
		});
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(dados: RelatorioPrecipitacaoAcumulada) {
		const tableData = this.setupTabelaPrecipitacao(dados);
		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-precipitacao-acumulada-${this.getPeriodo()}`
		);
	}

	exportarTXT(dados: RelatorioPrecipitacaoAcumulada) {
		let txtData = '';

		dados.chuvasAcumuladas.forEach((dadoRelatorio: any) => {
			txtData +=
				`Município/Posto: ${dadoRelatorio.detalhesPosto.municipio}/${dadoRelatorio.detalhesPosto.posto}\n` +
				`Tipo de estação: ${
					dadoRelatorio.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
						? 'Pluviômetro convencional'
						: 'PCD'
				}\n` +
				`Precipitação Acumulada: ${
					isNotNuloOuUndefined(dadoRelatorio.precipitacaoAcumulada)
						? dadoRelatorio.precipitacaoAcumulada
						: '-'
				}\n\n`;
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-precipitacao-acumulada-${this.getPeriodo()}`
		);
	}

	setupTabelaPrecipitacao(dados: RelatorioPrecipitacaoAcumulada) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Posto',
			'Tipo de Estação',
			'Precipitação Acumulada',
		]);
		dados.chuvasAcumuladas.forEach((dadoRelatorio: any) => {
			tableData.push([
				dadoRelatorio.detalhesPosto.municipio,
				dadoRelatorio.detalhesPosto.posto,
				dadoRelatorio.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
					? 'Pluviômetro convencional'
					: 'PCD',
				isNotNuloOuUndefined(dadoRelatorio.precipitacaoAcumulada)
					? dadoRelatorio.precipitacaoAcumulada
					: '-',
			]);
		});
		return tableData;
	}
}
