import * as Highcharts from 'highcharts/highstock';
import { QuantisCategoriasMapColors } from '../../../interfaces';

export const optionsChartClimatologia = (): Highcharts.Options => ({
	chart: {
		type: 'column',
	},
	title: {
		text: 'Climatologia',
		align: 'center',
	},
	subtitle: {
		text: '',
		align: 'left',
	},
	xAxis: {
		categories: [],
		title: {
			text: 'Meses',
		},
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Climatologia (mm)',
		},
	},
	tooltip: {
		valueSuffix: ' mm',
	},
	plotOptions: {
		column: {
			borderWidth: 0,
		},
	},
	series: [],
});
export const relatorioClimatologia: Array<
	Partial<Highcharts.SeriesOptionsType> & Record<string, any>
> = [
	{
		name: 'Climatologia',
		type: 'column',
		color: QuantisCategoriasMapColors.get('Normal'),
		data: [],
		pointPadding: 0.2,
	},
];
