import {
	AfterViewInit,
	Component,
	ComponentFactoryResolver,
	HostListener,
	Injector,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import {
	OpcaoPagina,
	PublicPageOptionsComponent,
} from '@componentes/public-page-options/public-page-options.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	ComponenteRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Select } from '@layout/interfaces/select';
import { FormGroup } from '@angular/forms';
import { Estacao } from '@modulos/meteorologia/submodulos/estacao/interfaces/estacao';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { TipoPrecipitacao } from '@shared/enum';
import { Mensagens } from '@core/enums/mensagens';

@Component({
	selector: 'seira-estacoes',
	templateUrl: './estacoes.component.html',
	styleUrls: ['./estacoes.component.scss'],
})
export class EstacoesComponent implements AfterViewInit {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	@ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
	dynamicComponentContainer!: ViewContainerRef;
	relatorioAtual?: ComponenteRelatorio;
	relatorioAnterior?: ComponenteRelatorio;
	instanceRelatorio?: InstanciaRelatorio;

	isDesktop = window.innerWidth > 1200;
	opcoesDaPagina: OpcaoPagina<any>[] = [];
	microrregioes: Select[] = [{ value: '0', name: 'Estado completo' }];
	form!: FormGroup;
	estacoes: Estacao[] = [];
	postos: PostosRelatorios[] = [];
	informacoesGerais: TextoInformativo = {
		label: Mensagens.INFORMACOES_GERAIS,
		texts: [
			{
				title: 'O que é uma estação?',
				text: 'Uma estação é um local onde são instalados sensores e equipamentos que tem a capacidade de medir, gravar e recolher dados sobre o tempo. Eles medem vários parâmetros metereológicos, como por exemplo: temperatura, pressão atmosférica, radiação, chuva, pressão atmosférica, direção e velocidade do vento.',
			},
			{
				title: 'O que você vai encontrar nesta página?',
				text: 'Aqui você pode visualizar no mapa os pluviômetros existentes na rede do Estado. É possível visualizar tanto as PCD (Plataforma de Coleta de Dados) quanto os pluviômetros convencionais (de envio manual por humano) acumulado em 24 horas.',
			},
		],
	};

	constructor(
		private readonly modalService: BsModalService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {}

	setInjector() {
		this.inputsRelatorioInjector = Injector.create({
			providers: [
				{
					provide: INPUTS_RELATORIOS,
					useValue: {
						form: this.form,
						postos: this.postos,
						estacoes: this.estacoes,
						microrregioes: this.microrregioes,
						setLoading: this.setLoading,
					},
				},
			],
		});
	}
	setLoading(state: boolean) {
		this.form.get('loadingGerar')?.setValue(state);
	}
	loadDynamicComponent(component: any): void {
		if (this.relatorioAtual !== this.relatorioAnterior) {
			if (this.dynamicComponentContainer.get(0)) {
				this.dynamicComponentContainer.remove(0);
			}
			if (component) {
				const componentFactory =
					this.componentFactoryResolver.resolveComponentFactory(component);
				const componentRef = this.dynamicComponentContainer.createComponent(
					componentFactory,
					undefined,
					this.inputsRelatorioInjector
				);
				this.instanceRelatorio = componentRef.instance as InstanciaRelatorio;
				this.botoesDeExportacao =
					this.instanceRelatorio.botoesDeExportacao || [];
				if (
					!this.instanceRelatorio.postos?.length ||
					!this.instanceRelatorio.estacoes?.length ||
					!this.instanceRelatorio.microrregioes?.length ||
					!this.instanceRelatorio.precipitacao?.length
				) {
					this.instanceRelatorio.postos = this.postos;
					this.instanceRelatorio.microrregioes = this.microrregioes;
					this.instanceRelatorio.precipitacao = this.precipitacoes;
				}
			} else {
				this.instanceRelatorio = undefined;
			}
		}
		this.relatorioAnterior = this.relatorioAtual;
	}
	ngAfterViewInit() {
		this.adicionarOpcoesDaPagina();
	}

	precipitacoes: Select[] = Object.values(TipoPrecipitacao).map(value => ({
		value,
		name: value.charAt(0).toUpperCase() + value.slice(1),
	}));

	botoesDeExportacao: GroupButton[] = [];
	inputsRelatorioInjector!: Injector;
	adicionarOpcoesDaPagina() {
		this.opcoesDaPagina = [
			{
				label: 'Legenda',
				template: this.legendaMapaMobile,
			},
		];
	}

	abrirModalOpcoesDaPagina(event: Event) {
		event.preventDefault();
		this.modalService.show(PublicPageOptionsComponent, {
			class: 'modal-dialog-centered',
			initialState: {
				opcoes: this.opcoesDaPagina,
			},
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any): void {
		this.isDesktop = window.innerWidth > 1200;
	}
}
