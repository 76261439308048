import { Component, inject, OnDestroy } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Validators } from '@angular/forms';
import {
	LabelsHistoricoVariaveisMeteorologicas,
	TiposPeriodosVariaveisMeteorologicas,
	VariaveisMeteorologicasPorPeriodo,
	VariaveisMeteorologicasPorPeriodoETipoVariavel,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { ValuesVariaveis } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { LegendaOptions } from '@home/submodulos/dados-meteorologicos/componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import {
	getBase64ImageFromURL,
	isNotNuloOuUndefined,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment/moment';
import { Moment } from 'moment';
import {
	CorLegendaChuvaAnualPastel,
	CorLegendaChuvaDiariaPastel,
	CorLegendaChuvaMensalPastel,
} from '@home/submodulos/dados-meteorologicos/enum/Variaveis-metereologica';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DateTimeUtils } from '@utils/datetime-util';

@Component({
	selector: 'seira-variaveis-metorologicas-mapa',
	templateUrl: './variaveis-metorologicas-mapa.component.html',
	styleUrls: ['./variaveis-metorologicas-mapa.component.scss'],
})
export class VariaveisMetorologicasMapaComponent
	implements OnDestroy, InstanciaRelatorio
{
	carregando = true;
	resultados: VariaveisMeteorologicasPorPeriodoETipoVariavel[] = [];
	periodoTitulo = '';
	funcaoCoresMapa?: (valor: number) => string;
	inputs = inject(INPUTS_RELATORIOS);

	descricaoRelatorio =
		'Define-se como mosaico de chuvas o conjunto de mapas gerados para representar a distribuição espacial da precipitação acumulada em um período específico, seja por dia, mês ou ano, com a coloração variando de acordo com os volumes de chuva registrados em cada área.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportPdf().then();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	variaveisMeteorologicasPorPeriodo: VariaveisMeteorologicasPorPeriodo[] = [];
	legenda: LegendaOptions = {
		label: LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO,
		items: [],
	};

	constructor(
		private relatorioService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.VARIAVEL_MAPA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}

	gerarRelatorio() {
		const dataInicio: Moment = this.inputs.form.get(
			FormularioRelatorio.DATA_INICIO
		)?.value;
		const dataFim: Moment = this.inputs.form.get(FormularioRelatorio.DATA_FIM)
			?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio.toISOString(),
			dataFim.toISOString(),
			periodo.toUpperCase()
		);

		if (dataInicio && dataFim && periodo.toUpperCase() == 'MENSAL') {
			this.inputs.setLoading(true);
			this.carregando = true;

			this.relatorioService
				.listarVariaveisMeteorologicas(
					periodo.toUpperCase(),
					dataInicio.toISOString(),
					dataFim.toISOString()
				)
				.subscribe({
					next: res => {
						this.relatorioService.verificaExistenciaDados(res);
						this.variaveisMeteorologicasPorPeriodo = res;
						this._resultados = res;
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
						this.carregando = false;
					},
					complete: () => {
						this.inputs.setLoading(false);
						this.carregando = false;
					},
				});
		} else if (periodo.toUpperCase() == 'ANUAL') {
			this.inputs.setLoading(true);
			this.carregando = true;
			this.relatorioService
				.listarVariaveisMeteorologicas(
					periodo.toUpperCase(),
					dataInicio?.toISOString(),
					dataFim?.toISOString()
				)
				.subscribe({
					next: res => {
						this.relatorioService.verificaExistenciaDados(res);
						this.variaveisMeteorologicasPorPeriodo = res;
						this._resultados = res;
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
						this.carregando = false;
					},
					complete: () => {
						this.inputs.setLoading(false);
						this.carregando = false;
					},
				});
		} else if (
			(dataInicio && dataFim && periodo.toUpperCase() == 'PERIODO') ||
			(dataInicio && dataFim && periodo.toUpperCase() == 'DIARIO')
		) {
			this.inputs.setLoading(true);
			this.carregando = true;
			this.relatorioService
				.listarVariaveisMeteorologicas(
					TiposPeriodosVariaveisMeteorologicas.DIARIO,
					dataInicio.toISOString(),
					dataFim.toISOString()
				)
				.subscribe({
					next: res => {
						this.relatorioService.verificaExistenciaDados(res);
						this.variaveisMeteorologicasPorPeriodo = res;
						this._resultados = res;
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
						this.carregando = false;
					},
					complete: () => {
						this.inputs.setLoading(false);
						this.carregando = false;
					},
				});
		}
	}

	set _resultados(value: VariaveisMeteorologicasPorPeriodo[]) {
		this.legenda.label = LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO;

		this.resultados = value
			.map(el => this.resultadoPorTipoPeriodo(el))
			.filter(el => el.historico.length);

		this.setCoresLegenda();
	}

	setCoresLegenda() {
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		this.legenda.items = [];

		switch (periodo) {
			case 'periodo':
				const valoresDiarios = [0, 0.1, 5.1, 15.1, 25.1, 50.1];
				const valoresDiariosString = valoresDiarios.map(valor =>
					new Intl.NumberFormat('pt-BR').format(valor)
				);
				const coresDiarias = Object.values(CorLegendaChuvaDiariaPastel);
				this.configurarLegenda(
					valoresDiarios,
					coresDiarias,
					valoresDiariosString
				);
				break;

			case 'mensal':
				const valoresMensais = [0, 0.1, 50.1, 100.1, 200.1, 300.1, 400.0];
				const valoresDiariosStrings = valoresMensais.map(valor =>
					new Intl.NumberFormat('pt-BR').format(valor)
				);
				const coresMensais = Object.values(CorLegendaChuvaMensalPastel);
				this.configurarLegenda(
					valoresMensais,
					coresMensais,
					valoresDiariosStrings
				);
				break;

			case 'anual':
				const valoresAnuais = [0, 200.1, 400.1, 700.1, 1000.1, 1300.1, 1600.0];
				const valoresDiariosStringss = valoresAnuais.map(valor =>
					new Intl.NumberFormat('pt-BR').format(valor)
				);
				const coresAnuais = Object.values(CorLegendaChuvaAnualPastel);
				this.configurarLegenda(
					valoresAnuais,
					coresAnuais,
					valoresDiariosStringss
				);
				break;

			default:
				console.warn(`Período desconhecido: ${periodo}`);
				break;
		}

		this.funcaoCoresMapa = (valor: number) => {
			for (let i = 0; i < this.legenda.items.length; i++) {
				const itemDaLegenda = this.legenda.items[i];
				const nextItem = this.legenda.items[i + 1];

				if (nextItem) {
					if (
						valor >= itemDaLegenda.numberCurrentAndNext &&
						valor < nextItem.numberCurrentAndNext
					) {
						return itemDaLegenda.color;
					}
				} else {
					// Caso especial para o último item da legenda
					if (valor >= itemDaLegenda.numberCurrentAndNext) {
						return itemDaLegenda.color;
					}
				}
			}

			return ''; // Retorna vazio se o valor não estiver em nenhum intervalo
		};
	}

	private configurarLegenda(
		valores: number[],
		cores: string[],
		valoresString: string[]
	): void {
		this.legenda.items = valores.map((valor, index) => ({
			numberCurrentAndNext: valor,
			title: valoresString[index],
			color: cores[index],
		}));
	}

	resultadoPorTipoPeriodo(
		resultado: VariaveisMeteorologicasPorPeriodo
	): VariaveisMeteorologicasPorPeriodoETipoVariavel {
		// const periodoForm = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
		// 	?.value;

		const periodo: keyof typeof TiposPeriodosVariaveisMeteorologicas =
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
		const tipoVariavel: ValuesVariaveis = this.inputs.form.get(
			FormularioRelatorio.VARIAVEL_MAPA
		)?.value;
		const historico: VariaveisMeteorologicasPorPeriodoETipoVariavel['historico'] =
			[];
		resultado.historico.forEach(el => {
			if (
				isNotNuloOuUndefined(el['precipitacaoAcumulada']) &&
				el.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
			) {
				const indexHistoricoMesmoPosto = historico.findIndex(
					medicao => medicao.geocodigo == el.geocodigo
				);
				if (
					historico[indexHistoricoMesmoPosto] &&
					historico[indexHistoricoMesmoPosto].valor <
						el['precipitacaoAcumulada']
				) {
					historico[indexHistoricoMesmoPosto] = {
						geocodigo: el.geocodigo,
						periodo: periodo,
						valor: el['precipitacaoAcumulada'],
						nomeEstacao: el.nomeEstacao,
						tipoEstacao: el.tipoEstacao,
						nomeMunicipio: el.nomeMunicipio,
					};
				} else {
					historico.push({
						geocodigo: el.geocodigo,
						periodo: periodo,
						valor: el['precipitacaoAcumulada'],
						nomeEstacao: el.nomeEstacao,
						tipoEstacao: el.tipoEstacao,
						nomeMunicipio: el.nomeMunicipio,
					});
				}
			}
		});

		return { data: resultado.data, historico, tipoVariavel };
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.VARIAVEL_MAPA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	getDadosTabelaParaExportacao(
		dados: VariaveisMeteorologicasPorPeriodo[],
		isPdf: boolean
	) {
		const tableData: any[][] = [];

		const colunas = [
			{ text: 'Município', fillColor: '#DCDCDC' },
			{ text: 'Geocódigo', fillColor: '#DCDCDC' },
			{ text: 'Precipitação acumulada', fillColor: '#DCDCDC' },
			{ text: 'Temperatura média', fillColor: '#DCDCDC' },
			{ text: 'Nome da estação', fillColor: '#DCDCDC' },
			{ text: 'Tipo da estação', fillColor: '#DCDCDC' },
			{ text: 'Período', fillColor: '#DCDCDC' },
			{ text: 'Data', fillColor: '#DCDCDC' },
		];

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: VariaveisMeteorologicasPorPeriodo) => {
			item.historico.forEach(historico => {
				const rowData = [
					historico.nomeMunicipio,
					historico.geocodigo,
					numberToBrNumber(
						historico.precipitacaoAcumulada
							? historico.precipitacaoAcumulada.toFixed(1)
							: 0
					),
					numberToBrNumber(
						historico.temperaturaMedia
							? historico.temperaturaMedia.toFixed(1)
							: ''
					),
					historico.nomeEstacao,
					historico.tipoEstacao,
					historico.periodo,
					DateTimeUtils.formatarData(item.data.toString(), 'dd/MM/yyyy'),
				];

				tableData.push(rowData);
			});
		});

		return tableData;
	}

	async exportPdf() {
		const pdfDefinitions: any = await pdfseira.documentDefinitions();
		const tipoPeriodo: 'MENSAL' | 'ANUAL' | 'DIARIO' | 'PERIODO' =
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
		const tipoVariavelLabel =
			LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO;
		const promiseImagens = this.resultados.map(async (historico, index) => {
			const imagem: any = document.getElementById('mapa-' + index + '-imagem');

			if (imagem) {
				const canvas = document.createElement('canvas');
				canvas.width = imagem.width * 1.5;
				canvas.height = imagem.height * 1.4;
				canvas.style.background = 'color: black';
				canvas?.getContext('2d')?.drawImage(imagem, 0, 0);

				const url = canvas.toDataURL('image/png');
				const imageData = await getBase64ImageFromURL(url);
				return { imagem: imageData, historico };
			}

			return null;
		});
		const legendaHtml = document.getElementById('legenda');
		if (legendaHtml) {
			const img = await html2canvas(legendaHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			}).then(canvas => canvas.toDataURL('image/png', 1));
			pdfDefinitions.content.push({
				text: `Mosaico de chuvas - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			});
			pdfDefinitions.content.push({
				text: [{ text: 'Formato: ', bold: true }, 'Mapa'],
				fontSize: 10,
				alignment: 'start',
			});
			pdfDefinitions.content.push({
				text: [
					{ text: 'Tipo de variável: ', bold: true },
					`${tipoVariavelLabel}`,
				],
				fontSize: 10,
				alignment: 'start',
			});
			pdfDefinitions.content.push({
				text: [
					{ text: 'Agrupado por: ', bold: true },
					tipoPeriodo.toUpperCase() === 'ANUAL'
						? 'Anual'
						: tipoPeriodo.toUpperCase() === 'MENSAL'
						? 'Mensal'
						: tipoPeriodo.toUpperCase() === 'PERIODO'
						? 'Período'
						: '',
				],
				fontSize: 10,
				alignment: 'start',
			});

			pdfDefinitions.content.push({
				image: img,
				margin: [0, 10, 0, 10],
				alignment: 'center',
				width: 500,
			});
		}

		Promise.all(promiseImagens).then(imagens => {
			if (imagens.every(imagem => imagem === null)) {
				this.toastr.error(
					'Não é possível gerar um pdf pois nenhum mapa foi gerado.'
				);
				return;
			}

			const columns: any = [[], [], []]; // Dividindo em 3 colunas

			const formatData = (data: Date, periodo: string): string => {
				switch (periodo.toUpperCase()) {
					case 'ANUAL':
						return moment(data).format('yyyy').toString();
					case 'MENSAL':
						return moment(data).format('MM/yyyy').toString();
					case 'PERIODO':
						return moment(data).format('DD/MM/yyyy').toString();
					default:
						return 'MM/yyyy';
				}
			};

			imagens.forEach((resultadoProcessamentoDaImagem, index) => {
				if (resultadoProcessamentoDaImagem) {
					const dataFormatada = formatData(
						resultadoProcessamentoDaImagem.historico.data,
						resultadoProcessamentoDaImagem.historico.historico[0].periodo
					);

					const dateText = {
						text: dataFormatada,
						fontSize: 12,
						alignment: 'center',
						margin: [0, 10],
					};
					const image = {
						image: resultadoProcessamentoDaImagem.imagem,
						alignment: 'center',
						width: 160,
					};

					columns[index % 3].push(dateText);
					columns[index % 3].push(image);
				}
				if ((index < 15 && index + 1 === 15) || (index - 14) % 18 === 0) {
					columns[0].push({ text: '', pageBreak: 'after' });
					columns[1].push({ text: '', pageBreak: 'after' });
					columns[2].push({ text: '', pageBreak: 'after' });
				}
			});

			pdfDefinitions.content.push({
				columns: columns.map((column: any) => ({
					width: '33.3%',
					stack: column,
				})),
			});

			const tableData = this.getDadosTabelaParaExportacao(
				this.variaveisMeteorologicasPorPeriodo,
				true
			);

			pdfDefinitions.content.push(
				{
					text: '',
					alignment: 'center',
					fontSize: 10,
				},
				{
					table: {
						body: tableData,
					},

					fontSize: 9,
					marginTop: 50,
					marginLeft: -10,
				}
			);

			pdfseira.generatePdf(pdfDefinitions);
		});
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.variaveisMeteorologicasPorPeriodo,
			false
		);

		DocumentExporter.gerarCSV(
			tableData,
			`Mosaico de Chuva - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.variaveisMeteorologicasPorPeriodo,
			false
		);
		const txtContent = tableData.map(row => row.join('\t')).join('\n');
		DocumentExporter.gerarTXT(
			txtContent,
			`Mosaico de Chuva - ${this.periodoTitulo}`
		);
	}

	getMosaicosChuva(): Array<{
		data: string | number | Date;
		geocodigo: number;
		periodo: string;
		precipitacaoAcumulada: number | string;
		temperaturaMedia: number | string;
		nomeEstacao: string;
		nomeMunicipio: string;
	}> {
		const mosaicos: Array<{
			data: string | number | Date;
			geocodigo: number;
			periodo: string;
			precipitacaoAcumulada: number | string;
			temperaturaMedia: number | string;
			nomeEstacao: string;
			nomeMunicipio: string;
			tipoEstacao: string;
		}> = [];

		this.variaveisMeteorologicasPorPeriodo.map(mosaico => {
			const periodo = this.inputs.form.get(FormularioRelatorio.TIPO_PERIODO)
				?.value;

			for (let index = 0; index < mosaico.historico.length; index++) {
				mosaicos.push({
					nomeMunicipio: mosaico.historico[index].nomeMunicipio,
					geocodigo: mosaico.historico[index].geocodigo,
					precipitacaoAcumulada: (mosaico.historico[index]
						.precipitacaoAcumulada as string | number)
						? (numberToBrNumber(
								mosaico.historico[index].precipitacaoAcumulada.toFixed(1)
						  ) as string | number)
						: '',
					temperaturaMedia: (mosaico.historico[index].temperaturaMedia as
						| string
						| number)
						? (numberToBrNumber(
								mosaico.historico[index].temperaturaMedia.toFixed(1)
						  ) as string | number)
						: '',
					nomeEstacao: mosaico.historico[index].nomeEstacao,
					tipoEstacao: mosaico.historico[index].tipoEstacao,
					periodo: mosaico.historico[index].periodo,

					data:
						periodo === 'DIARIO' || periodo === 'PERIODO'
							? DateTimeUtils.formatarData(
									mosaico.data.toString(),
									'dd/MM/yyyy'
							  )
							: periodo === 'MENSAL'
							? DateTimeUtils.getMesNumericoEAno(
									new Date(
										DateTimeUtils.formatToZonedDateTime(mosaico.data.toString())
									)
							  )
							: periodo === 'ANUAL'
							? new Date(
									DateTimeUtils.formatToZonedDateTime(mosaico.data.toString())
							  ).getFullYear()
							: '',
				});
			}
		});

		return mosaicos;
	}

	async exportarXLSX() {
		const nomeArquivo = `Mosaico de Chuva - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'nomeMunicipio',
				text: 'Municipio',
				size: 48,
			},
			{ label: 'geocodigo', text: 'Geocódigo', size: 24 },
			{
				label: 'precipitacaoAcumulada',
				text: 'Precipitação Acumulada',
				size: 24,
			},
			{ label: 'temperaturaMedia', text: 'Temperatura Média', size: 24 },
			{ label: 'nomeEstacao', text: 'Nome da Estação', size: 24 },
			{ label: 'tipoEstacao', text: 'Tipo da Estação', size: 24 },
			{ label: 'periodo', text: 'Período', size: 24 },
			{ label: 'data', text: 'Data', size: 24 },
		];

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.getMosaicosChuva(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${nomeArquivo}`,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
