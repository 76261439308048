import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-legenda-pluviometria-observada',
	templateUrl: './legenda-pluviometria-observada.component.html',
	styleUrls: ['./legenda-pluviometria-observada.component.scss'],
})
export class LegendaPluviometriaObservadaComponent {
	@Input() legendaInfo: any;

	constructor() {}

	getKeys() {
		return Object.keys(this.legendaInfo).map(Number);
	}
	getLegendas = () => {
		const legendas = [];
		const sortedKeys = this.getKeys()
			.map(Number)
			.sort((a, b) => a - b);
		for (const key in sortedKeys) {
			legendas.push({
				valor: sortedKeys[key],
				cor: this.legendaInfo[sortedKeys[key]],
			});
		}

		return legendas;
	};

	get width() {
		return `${100 / this.getLegendas().length}%`;
	}

	borderRadius(index: number) {
		const isFirst = index === 0;
		const isLast = index === this.getLegendas().length - 1;

		const topLeft = isFirst ? '10px' : '0';
		const topRight = isFirst ? '10px' : '0';
		const bottomRight = isLast ? '10px' : '0';
		const bottomLeft = isLast ? '10px' : '0';

		return `border-radius: ${topRight} ${bottomRight} ${bottomLeft} ${topLeft} ;`;
	}
}
