export const optionsChartDesvio: Highcharts.Options = {
	chart: {
		type: 'column',
	},
	yAxis: {
		title: {
			text: 'Desvio',
		},
	},
	tooltip: {
		valueSuffix: ' mm',
		valueDecimals: 1,
	},
	plotOptions: {
		column: {
			borderWidth: 0,
		},
	},
};
