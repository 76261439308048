import {
	AfterViewChecked,
	AfterViewInit,
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	Injector,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
	EstacaoMonitorada,
	FiltrosEstacaoMonitordada,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { EstacoesService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/services/estacoes.service';
import { OpcaoFiltroEstacao } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/monitoramento-estacoes';
import * as Leaflet from 'leaflet';
import { LayerGroup } from 'leaflet';
import { PublicEstacaoPopupComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/public-estacao-popup/public-estacao-popup.component';
import { DataMarker } from '@utils/leaflet';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalFitroMapaMonitoramentoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/estacoes/componentes/modal-fitro-mapa-monitoramento/modal-fitro-mapa-monitoramento.component';
import { ValoresFormularioService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/estacoes/services/valoresFormulario.service';
import { Observable } from 'rxjs';
import { StatusEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/status-estacao';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import {
	capitalizeFirstLetter,
	isNuloOuUndefined,
	numberToBrNumber,
} from '@utils';
import { DataTablesModule } from 'angular-datatables';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { MapaParaibaLeafletComponent } from '@componentes/mapa-paraiba-leaflet/mapa-paraiba-leaflet.component';
import { DocumentExporter } from '@utils/document-exporter';

@Component({
	selector: 'seira-estacoes-monitoramento-mapa',
	templateUrl: './estacoes-monitoramento-mapa.component.html',
	styleUrls: ['./estacoes-monitoramento-mapa.component.scss'],
})
export class EstacoesMonitoramentoMapaComponent
	implements OnInit, AfterViewInit, DataTablesModule, AfterViewChecked
{
	@ViewChild('tabelaMonitoramento', { static: false })
	tabela!: PublicTableComponent;
	@Input() homePage = false;
	@ViewChild(MapaParaibaLeafletComponent, { static: false })
	mapaParaibaRef?: MapaParaibaLeafletComponent;
	@Input()
	class = 'col col-xl-9 col-12 d-flex flex-column';
	markersLayer: LayerGroup;
	map!: Leaflet.Map;
	isDesktop = window.innerWidth > 1200;
	mostrarCampos = false;
	loadingFiltrando = false;
	possuiFiltrosAtivos = false;
	private readonly opcaoTodosTabela = 'TODOS';
	markersEstacoes: EstacaoMonitorada[] = [];
	estacoes: OpcaoFiltroEstacao[] = [];
	municipios: OpcaoFiltroEstacao[] = [];
	mesorregioes: OpcaoFiltroEstacao[] = [];
	bacias: OpcaoFiltroEstacao[] = [];
	regioes: OpcaoFiltroEstacao[] = [];
	microrregioes: OpcaoFiltroEstacao[] = [];
	status: { label: StatusEstacao; value: keyof typeof StatusEstacao }[] = [];
	show = 'block';
	tiposEstacao: { label: TipoEstacao; value: keyof typeof TipoEstacao }[] = [];

	formEstacao: FormGroup;
	estacoesMonitoradas: EstacaoMonitorada[] = [];
	estacoesMonitoradasFiltradas: EstacaoMonitorada[] = [];
	estacoesMonitoradasBox: EstacaoMonitorada[] = [];
	tableColumns: ADTColumns[] = [];

	OpcoesTipoVisualizacao: OptionRadio<'mapa' | 'tabela'>[] = [
		{ label: 'Mapa', value: 'mapa' },
		{ label: 'Tabela', value: 'tabela' },
	];

	centerDefault: Leaflet.LatLngExpression = [-7.2605416, -36.7290255];
	zoomDefault = 8;

	modalFiltro?: BsModalRef<ModalFitroMapaMonitoramentoComponent>;
	filtroModalAtivo$: Observable<string>;
	filtroModalAtivo = '';
	tabelaRenderizada = false;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarcsv();
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT();
			},
		},
	];

	getNameEstacao(estacaoMonitorada: EstacaoMonitorada) {
		return estacaoMonitorada.nomeMunicipio === estacaoMonitorada.nomePosto
			? capitalizeFirstLetter(estacaoMonitorada.nomeMunicipio)
			: capitalizeFirstLetter(estacaoMonitorada.nomeMunicipio) +
					'/' +
					capitalizeFirstLetter(estacaoMonitorada.nomePosto);
	}
	getTipo_Estacao(EstacaoMonitorada: EstacaoMonitorada) {
		return EstacaoMonitorada.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
			? 'PCO'
			: EstacaoMonitorada.tipoEstacao;
	}
	exportarTXT() {
		const colunas = [
			'Município/Posto',
			'Latitude',
			'Longitude',
			'Altitude',
			'Tipo de estação',
		];

		let txtData = '';

		this.markersEstacoes.forEach((estacao: EstacaoMonitorada) => {
			const rowData = [
				this.getNameEstacao(estacao),
				estacao.latitude,
				estacao.longitude,
				estacao.altitude ? estacao.altitude.toFixed(1).replace('.', ',') : '-',
				estacao.tipoEstacao,
			];

			rowData.forEach((data, index) => {
				txtData += `${colunas[index]}: ${data}\n`;
			});

			txtData += '\n';
		});

		DocumentExporter.gerarTXT(txtData, `Rede de monitoramento de estações`);
	}
	async exportarPDF() {
		const img = await this.mapaParaibaRef?.getMapImage();
		const dataFormatada = format(
			new Date(),
			"dd 'de' MMMM 'de' yyyy 'às' HH'h'mm",
			{
				locale: ptBrLocale,
			}
		);

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'portrait'
		);

		documentDefinition.content.push([
			{
				text: `Rede de monitoramento de estações agroclimáticas da Paraíba`,
				alignment: 'center',
				margin: [0, 0, 5, 5],
			},
			{
				text: `Relatório exportado em: ${dataFormatada}`,
				alignment: 'center',
				margin: [0, 5, 5, 5],
				fontSize: 12,
			},
		]);

		if (img !== 'data:,') {
			documentDefinition.content.push({
				image: img,
				width: 515,
				heigth: 250,
				margin: [0, 15, 0, 30],
				alignment: 'center',
			});
		}

		if (this.markersEstacoes) {
			documentDefinition.content.push({
				table: {
					headerRows: 1,
					widths: ['*', 'auto', 'auto', 'auto', 'auto'],
					body: this.getDadosTabelaRedeMonitoramento(this.markersEstacoes).map(
						(row, rowIndex) =>
							row.map(cell => ({
								text: cell,
								alignment: 'center',
								fillColor: rowIndex === 0 ? '#8a8a8a' : null,
							}))
					),
					alignment: 'center',
					layout: 'fullWidth',
					fontSize: 12,
				},
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}
	async exportarcsv() {
		const header = [
			['Agência Executiva de Gestão das Águas do Estado da Paraíba\n\n'],
		];

		const tableData = this.getDadosTabelaRedeMonitoramento(
			this.markersEstacoes
		);
		const csvContent = header.concat(tableData);
		const csvString = csvContent.map(row => row.join(';')).join('\n');

		DocumentExporter.gerarCSV(csvString, `Rede de monitoramento`);
	}

	getDadosTabelaRedeMonitoramento(dados: EstacaoMonitorada[]) {
		const tableData: any[][] = [];
		const colunas = [
			'Município/Posto',
			'Tipo de estação',
			'Latitude',
			'Longitude',
			'Altitude',
		];
		tableData.push(colunas);

		dados.forEach((item: EstacaoMonitorada) => {
			const rowData = [
				this.getNameEstacao(item),
				this.getTipo_Estacao(item),
				numberToBrNumber(item.latitude),
				numberToBrNumber(item.longitude),
				isNuloOuUndefined(item.altitude)
					? '-'
					: `${item.altitude!.toFixed(1).replace('.', ',')}`,
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	constructor(
		private formBuilder: FormBuilder,
		private readonly modalService: BsModalService,
		private estacoesService: EstacoesService,
		private resolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private valoresFormularioService: ValoresFormularioService,
		private cdr: ChangeDetectorRef
	) {
		for (const tipoEstacaoKey in TipoEstacao) {
			const key = tipoEstacaoKey as keyof typeof TipoEstacao;
			if (TipoEstacao[key] !== TipoEstacao.FLUVIOMETRO) {
				this.tiposEstacao.push({ value: key, label: TipoEstacao[key] });
			}
		}
		for (const statusEstacaoKey in StatusEstacao) {
			const key = statusEstacaoKey as keyof typeof StatusEstacao;
			this.status.push({ value: key, label: StatusEstacao[key] });
		}

		this.filtroModalAtivo$ = this.valoresFormularioService.filtroModalAtivo$;

		this.formEstacao = this.formBuilder.group({
			nomePosto: new FormControl<number | null>(null),
			tipoEstacao: new FormControl<TipoEstacao | null>(null),
			mesorregiao: new FormControl<number | null>(null),
			municipio: new FormControl<number | null>(null),
			periodo: new FormControl(''),
			statusEstacao: new FormControl<StatusEstacao | null>(null),
			bacia: new FormControl<number | null>(null),
			regiao: new FormControl<number | null>(null),
			microrregiao: new FormControl<number | null>(null),
			tipoVisualizacao: new FormControl('mapa'),
			opcoesTabela: new FormControl(this.opcaoTodosTabela),
		});
		this.markersLayer = Leaflet.layerGroup();
	}

	ngOnInit() {
		this.getFormItemValue('opcoesTabela');
		this.setEstacoesMonitoradas();
		this.observeMesorregioes();
		this.observeRegioes();
		this.observeMicrorregioes();
		this.observeMunicipios();
		this.observeFiltrosAtivos();
		this.observefiltroModalAtivo();
		this.observeTipoEstacao();
	}
	ngAfterViewChecked() {
		if (this.tabela && this.tabelaRenderizada) {
			this.tabelaRenderizada = false;
		}
	}

	ngAfterViewInit() {
		this.loadTabela();
		this.cdr.detectChanges();
	}

	getFormItemValue(formValue: string) {
		return this.formEstacao.get(formValue)!.value;
	}

	loadTabela() {
		this.tableColumns = [
			{
				title: 'Município/Posto',
				data: null,
				className: 'text-tertiary  text-center',
				render: (row: EstacaoMonitorada) => {
					return this.getNameEstacao(row);
				},
			},

			{
				title: 'Tipo de estação',
				data: 'tipoEstacao',
				className: 'text-tertiary  text-center',
				render: function (data) {
					return data === 'PLUVIOMETRO_CONVENCIONAL' ? 'PCO' : data;
				},
			},
			{
				title: 'Latitude',
				data: 'latitude',
				className: 'text-tertiary  text-center',
				render: function (data) {
					return isNuloOuUndefined(data) ? '-' : data.toFixed(4);
				},
			},
			{
				title: 'Longitude',
				data: 'longitude',
				className: 'text-tertiary  text-center',
				render: function (data) {
					return isNuloOuUndefined(data) ? '-' : data.toFixed(4);
				},
			},
			{
				title: 'Altitude',
				data: 'altitude',
				className: 'text-tertiary  text-center',
				render: function (data) {
					return isNuloOuUndefined(data)
						? '-'
						: data.toFixed(1).replace('.', ',');
				},
			},
		];
	}

	async setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
		this.setupMapEvents();
	}

	setupMapEvents() {
		this.map?.on('moveend', () => {
			const markers = this.getMarkersInView();
			const markerIds = markers.map(marker => marker?.data?.id);

			this.estacoesMonitoradasBox = this.markersEstacoes.filter(estacao =>
				markerIds.includes(estacao.id)
			);
		});

		this.map?.on('zoomend', () => {
			const zoomToIconSizeMap: Record<number, number> = {
				8: 15,
				9: 25,
				10: 35,
				11: 45,
			};
			const markers = this.getMarkers();
			const zoom = this.map.getZoom();

			for (const marker of markers) {
				if (marker.data) {
					let newIconSize = zoomToIconSizeMap[zoom] || 15;

					if (zoom < 8) {
						newIconSize = 15;
					} else if (zoom > 11) {
						newIconSize = 45;
					}

					marker.setIcon(this.setIcon(marker.data, newIconSize));
				}
			}
		});
	}

	getMarkers() {
		const markerList: DataMarker<EstacaoMonitorada>[] = [];
		this.map?.eachLayer(layer => {
			markerList.push(layer as DataMarker<EstacaoMonitorada>);
		});
		return markerList;
	}

	getMarkersInView() {
		const markerList: DataMarker<EstacaoMonitorada>[] = [];
		this.map?.eachLayer(layer => {
			if (
				layer instanceof Leaflet.Marker &&
				this.map?.getBounds().contains(layer.getLatLng())
			) {
				markerList.push(layer as DataMarker<EstacaoMonitorada>);
			}
		});
		return markerList;
	}

	limparFiltroModal(variavel: string) {
		const arrayVal = this.filtroModalAtivo
			? this.filtroModalAtivo
					.split(', ')
					.filter((item: string) => item.trim().toLowerCase() !== variavel)
			: [];

		this.valoresFormularioService.atualizarDados(arrayVal.join(', '));
	}

	observefiltroModalAtivo() {
		this.filtroModalAtivo$.subscribe({
			next: (value: string) => {
				this.filtroModalAtivo = value;
			},
		});
	}

	filtraVariavel(listaVariavel: number[]) {
		const variaveis = [
			{ id: 'mesorregiaoId', variavel: 'mesorregiao', nome: 'nomeMesorregiao' },
			{ id: 'regiaoId', variavel: 'regiao', nome: 'nomeRegiao' },
			{
				id: 'microrregiaoId',
				variavel: 'microrregiao',
				nome: 'nomeMicrorregiao',
			},
			{ id: 'municipioId', variavel: 'municipio', nome: 'nomeMunicipio' },
			{ id: 'baciaId', variavel: 'bacia', nome: 'nomeBacia' },
			{ id: 'id', variavel: 'nomePosto', nome: 'nomePosto' },
		];
		this.estacoesMonitoradasFiltradas = this.estacoesMonitoradas;
		listaVariavel.forEach((n: number) => {
			const id: string = variaveis[n]?.id;
			const alteraId: string = variaveis[n + 1]?.id;
			const variavel: string = variaveis[n]?.variavel;
			const nome: string = variaveis[n + 1]?.nome;
			const setVariavel: Set<number> = new Set();
			const variavelLista: OpcaoFiltroEstacao[] = [];

			if (this.formEstacao.get(variavel)?.value) {
				this.estacoesMonitoradasFiltradas = this.estacoesMonitoradas.filter(
					(em: EstacaoMonitorada) => {
						return em[id] === this.formEstacao.get(variavel)?.value;
					}
				);
			}

			this.estacoesMonitoradasFiltradas.forEach(
				(estacao: EstacaoMonitorada) => {
					const nomePosto = `${estacao.nomePosto}/ ${estacao.nomeMunicipio} (${
						estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
							? 'Convencional'
							: estacao.tipoEstacao
					})`;
					if (estacao[alteraId] && !setVariavel.has(estacao[alteraId])) {
						variavelLista.push({
							id: estacao[alteraId],
							nome: variavel === 'municipio' ? nomePosto : estacao[nome],
						});
						setVariavel.add(estacao[alteraId]);
					}
				}
			);

			variavelLista?.sort((a, b) => a.nome.localeCompare(b.nome));

			if (variavel === 'mesorregiao') {
				this.regioes = variavelLista;
			}
			if (variavel === 'regiao') {
				this.microrregioes = variavelLista;
			}
			if (variavel === 'microrregiao') {
				this.municipios = variavelLista;
			}
			if (variavel === 'municipio') {
				this.estacoes.push(...variavelLista);
			}
		});
	}

	observeMesorregioes() {
		this.formEstacao.get('mesorregiao')?.valueChanges.subscribe({
			next: () => {
				this.filtraVariavel([0]);
				this.formEstacao.get('regiao')?.reset();
			},
		});
	}

	observeRegioes() {
		this.formEstacao.get('regiao')?.valueChanges.subscribe({
			next: () => {
				this.filtraVariavel([0, 1]);
				this.formEstacao.get('microrregiao')?.reset();
			},
		});
	}

	observeMicrorregioes() {
		this.formEstacao.get('microrregiao')?.valueChanges.subscribe({
			next: () => {
				this.filtraVariavel([0, 1, 2]);
				this.formEstacao.get('municipio')?.reset();
				this.limparFiltroModal('municipio');
			},
		});
	}
	observeTipoEstacao() {
		this.formEstacao.get('tipoEstacao')?.valueChanges.subscribe({
			next: (tipoEstacao: TipoEstacao) => {
				if (tipoEstacao) {
					this.filtrarPostosPorTipoEstacao(tipoEstacao);
				} else {
					this.filtrarPostosPorTipoEstacao(tipoEstacao);
				}
			},
		});
	}

	observeMunicipios() {
		this.formEstacao.get('municipio')?.valueChanges.subscribe({
			next: val => {
				const municipioId = this.formEstacao.get('municipio')?.value;

				if (municipioId) {
					const postosFiltrados = this.estacoesMonitoradas.filter(
						estacao => estacao.municipioId === municipioId
					);
					const filteredPostos = postosFiltrados.map(estacao => ({
						id: estacao.id,
						nome: `${estacao.nomeMunicipio}/${estacao.nomePosto} (${
							estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
								? 'Convencional'
								: estacao.tipoEstacao
						})`,
					}));
					this.estacoes = filteredPostos;
				} else {
					this.estacoes = this.estacoesMonitoradas.map(estacao => ({
						id: estacao.id,
						nome: `${estacao.nomeMunicipio}/${estacao.nomePosto} (${
							estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
								? 'Convencional'
								: estacao.tipoEstacao
						})`,
					}));
				}
				this.filtraVariavel([0, 1, 2]);
			},
		});
	}

	setSelects(estacoesMonitoradas: EstacaoMonitorada[]) {
		const setMesorregiao: Set<number> = new Set();
		const setRegiao: Set<number> = new Set();
		const setMicrorregiao: Set<number> = new Set();
		const setMunicipio: Set<number> = new Set();
		const setBacia: Set<number> = new Set();
		const mesorregioes: OpcaoFiltroEstacao[] = [];
		const regioes: OpcaoFiltroEstacao[] = [];
		const microrregioes: OpcaoFiltroEstacao[] = [];
		const municipios: OpcaoFiltroEstacao[] = [];
		const bacias: OpcaoFiltroEstacao[] = [];
		const postos: OpcaoFiltroEstacao[] = [];

		estacoesMonitoradas.forEach((estacao: EstacaoMonitorada) => {
			if (estacao.mesorregiaoId && !setMesorregiao.has(estacao.mesorregiaoId)) {
				mesorregioes.push({
					id: estacao.mesorregiaoId,
					nome: estacao.nomeMesorregiao,
				});
				setMesorregiao.add(estacao.mesorregiaoId);
			}
			if (estacao.regiaoId && !setRegiao.has(estacao.regiaoId)) {
				regioes.push({
					id: estacao.regiaoId,
					nome: estacao.nomeRegiao,
				});
				setRegiao.add(estacao.regiaoId);
			}
			if (
				estacao.microrregiaoId &&
				!setMicrorregiao.has(estacao.microrregiaoId)
			) {
				microrregioes.push({
					id: estacao.microrregiaoId,
					nome: estacao.nomeMicrorregiao,
				});
				setMicrorregiao.add(estacao.microrregiaoId);
			}
			if (estacao.municipioId && !setMunicipio.has(estacao.municipioId)) {
				municipios.push({
					id: estacao.municipioId,
					nome: estacao.nomeMunicipio,
				});
				setMunicipio.add(estacao.municipioId);
			}
			if (estacao.baciaId && !setBacia.has(estacao.baciaId)) {
				bacias.push({
					id: estacao.baciaId,
					nome: estacao.nomeBacia,
				});
				setBacia.add(estacao.baciaId);
			}

			if (
				estacao.tipoEstacao === 'PCD' ||
				estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
			) {
				postos.push({
					id: estacao.id,
					nome: `${estacao.nomeMunicipio}/${estacao.nomePosto} (${
						estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
							? 'Convencional'
							: estacao.tipoEstacao
					})`,
				});
			}
		});

		mesorregioes.sort((a, b) => a.nome.localeCompare(b.nome));
		regioes.sort((a, b) => a.nome.localeCompare(b.nome));
		microrregioes.sort((a, b) => a.nome.localeCompare(b.nome));
		municipios.sort((a, b) => a.nome.localeCompare(b.nome));
		bacias.sort((a, b) => a.nome.localeCompare(b.nome));
		postos.sort((a, b) => a.nome.localeCompare(b.nome));

		if (this.mesorregioes.length < 1) {
			this.mesorregioes = mesorregioes;
			this.regioes = regioes;
			this.microrregioes = microrregioes;
			this.municipios = municipios;
			this.bacias = bacias;
			this.estacoes = postos;
		}
	}

	get filtros() {
		const {
			nomePosto,
			tipoEstacao,
			mesorregiao,
			municipio,
			bacia,
			regiao,
			microrregiao,
		} = this.formEstacao.getRawValue();
		return new FiltrosEstacaoMonitordada(
			null,
			tipoEstacao,
			mesorregiao,
			municipio,
			null,
			bacia,
			regiao,
			microrregiao
		);
	}

	observeFiltrosAtivos() {
		this.formEstacao.valueChanges.subscribe(filtros => {
			let possuiFiltrosAtivos = false;
			Object.values(filtros).forEach((filtro: any) => {
				if (filtro) {
					possuiFiltrosAtivos = true;
				}
			});
			this.possuiFiltrosAtivos = possuiFiltrosAtivos;
		});
	}

	setEstacoesMonitoradas(filtrarSelect = true) {
		this.loadingFiltrando = true;
		this.estacoesService.estacoesMonitoramento(this.filtros).subscribe({
			next: estacoes => {
				const estacoesSemTipoFluviometro = estacoes.filter(
					e => e.tipoEstacao !== 'FLUVIOMETRO'
				);
				this.estacoesMonitoradasFiltradas = estacoesSemTipoFluviometro;
				this.markersEstacoes = this.estacoesMonitoradasFiltradas;

				if (filtrarSelect) {
					this.setSelects(estacoesSemTipoFluviometro);
				}
				this.loadingFiltrando = false;
				if (
					this.filtros.estacaoId ||
					this.filtros.tipoEstacao ||
					this.filtros.mesorregiaoId ||
					this.filtros.municipioId ||
					this.filtros.statusEstacao ||
					this.filtros.baciaId ||
					this.filtros.regiaoId ||
					this.filtros.microrregiaoId
				) {
					this.setupMarkers(true);
				} else {
					this.setupMarkers(false);
				}
			},
			error: () => {
				this.loadingFiltrando = false;
			},
		});
	}

	setupMarkers(zoomIn: boolean) {
		this.markersLayer.clearLayers();
		this.markersEstacoes
			.filter(estacao => estacao.tipoEstacao !== 'FLUVIOMETRO')
			.forEach(markerPoint => {
				const marker = new DataMarker<EstacaoMonitorada>(
					[markerPoint.latitude, markerPoint.longitude],
					{
						icon: this.setIcon(markerPoint),
						data: markerPoint,
					}
				);
				const markerPopup = this.compilePopup(c => {
					c.instance.markerData = markerPoint;
				});

				marker.bindPopup(markerPopup, {
					closeButton: false,
				});
				marker.addTo(this.markersLayer);
			});
		if (zoomIn) {
			const markerPositions: Leaflet.LatLngBoundsLiteral =
				this.markersEstacoes.map(m => [m.latitude, m.longitude]);
			this.map.fitBounds(markerPositions, {
				maxZoom: 15,
			});
		}

		this.map.setView(this.centerDefault, this.zoomDefault);
	}

	setIcon(estacao: EstacaoMonitorada, size = 15) {
		return Leaflet.icon({
			iconUrl: `assets/images/estacoes/${this.setIconName(
				estacao.tipoEstacao
			)}-${this.setIconStatus(estacao.ultimaColeta)}.svg`,
			iconSize: [size, size],
		});
	}

	setIconName(tipoEstacao: keyof typeof TipoEstacao) {
		let tipoEstacaoIcone: 'pcd' | 'pluviometro-convencional';
		if (tipoEstacao === 'PCD') {
			tipoEstacaoIcone = 'pcd';
		} else {
			tipoEstacaoIcone = 'pluviometro-convencional';
		}
		return tipoEstacaoIcone;
	}

	setIconStatus(ultimaColeta: Date) {
		const dataAtual = new Date();
		dataAtual.setHours(0, 0, 0, 0);
		const dataUltimaColeta = new Date(ultimaColeta);
		dataUltimaColeta.setHours(0, 0, 0, 0);

		const dadosEnviadosNoDia =
			dataAtual.getTime() === dataUltimaColeta.getTime();

		if (dadosEnviadosNoDia) {
			return '1';
		} else {
			return '2';
		}
	}

	compilePopup(
		onAttach: (el: ComponentRef<PublicEstacaoPopupComponent>) => void
	) {
		const compFactory = this.resolver.resolveComponentFactory(
			PublicEstacaoPopupComponent
		);
		const compRef = compFactory.create(this.injector);

		// onAttach allows you to assign
		if (onAttach) onAttach(compRef);

		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		const div = document.createElement('div');
		div.appendChild(compRef.location.nativeElement);
		return div;
	}

	limparFiltros() {
		if (this.possuiFiltrosAtivos) {
			this.formEstacao.setValue({
				nomePosto: null,
				tipoEstacao: null,
				mesorregiao: null,
				municipio: null,
				bacia: null,
				regiao: null,
				microrregiao: null,
				periodo: '',
				statusEstacao: null,
				opcoesTabela: this.opcaoTodosTabela,
				tipoVisualizacao: 'mapa',
			});

			this.map.setZoom(8);

			this.estacoes = this.estacoesMonitoradas.map(posto => ({
				id: posto.id,
				nome: `${posto.nomeMunicipio}/${posto.nomePosto} (${
					posto.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
						? 'convencional'
						: posto.tipoEstacao
				})`,
			}));
			this.setEstacoesMonitoradas();
			this.valoresFormularioService.atualizarDados('');
			this.possuiFiltrosAtivos = false;
		}
	}

	mostrarCamposAdicionais() {
		this.mostrarCampos = !this.mostrarCampos;
		return this.mostrarCampos;
	}

	adicionarFiltro(filtro: FiltrosEstacaoMonitordada) {
		this.formEstacao.patchValue({
			estacaoId: null,
			tipoEstacao: filtro.tipoEstacao,
			mesorregiao: filtro.mesorregiaoId,
			bacia: filtro.baciaId,
			regiao: filtro.regiaoId,
			microrregiao: filtro.microrregiaoId,
			municipio: filtro.municipioId,
		});
	}

	abrirModalFiltros(event: Event) {
		event.preventDefault();
		const config = {
			class: 'modal-dialog-centered',
			initialState: {
				filtro: this.filtros,
				intervaloMonitoramento: this.formEstacao.get('statusEstacao')?.value,
				regioes: this.regioes,
				bacias: this.bacias,
				microrregioes: this.microrregioes,
				municipios: this.municipios,
				tiposEstacao: this.tiposEstacao,
				mesorregioes: this.mesorregioes,
				estacoesMonitoradas: this.estacoesMonitoradas,
				enviarFiltro: (filtro: any) => this.adicionarFiltro(filtro),
			},
		};

		this.modalFiltro = this.modalService.show(
			ModalFitroMapaMonitoramentoComponent,
			config as ModalOptions
		);
	}

	filtrarPostosPorTipoEstacao(tipoEstacao: TipoEstacao | null) {
		let postosFiltrados: EstacaoMonitorada[];

		if (tipoEstacao !== null) {
			postosFiltrados = this.estacoesMonitoradas.filter(
				estacao => estacao.tipoEstacao === tipoEstacao
			);
		} else {
			postosFiltrados = this.estacoesMonitoradas;
		}

		this.estacoes = postosFiltrados.map(posto => ({
			id: posto.id,
			nome: `${posto.nomeMunicipio}/${posto.nomePosto} (${
				posto.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
					? 'convencional'
					: posto.tipoEstacao
			})`,
		}));

		this.formEstacao.get('nomePosto')?.setValue(null);
	}
}
