import { Injectable } from '@angular/core';
import {
	FormularioRelatorio,
	ListaRelatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { EvapotranspiracaoTabelaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/evapotranspiracao-tabela/evapotranspiracao-tabela.component';
import { PluviometroTabelaPrecipitacaoDiariaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-precipitacao-diaria/pluviometro-tabela-precipitacao-diaria.component';
import { VariaveisMeteorologicasTabelaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/variaveis-meteorologicas-tabela/variaveis-meteorologicas-tabela.component';
import { PluviometricoTabelaMediaHistoricaComponent } from '../componentes/relatorios/pluviometrico-tabela-media-historica/pluviometrico-tabela-media-historica.component';
import { MapaQuantisComponent } from '../componentes/relatorios/mapa-quantis/mapa-quantis.component';
import { PluviometroTabelaPluviometricoPorPostoComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-pluviometrico-por-posto/pluviometro-tabela-pluviometrico-por-posto.component';
import {
	PERIODO_MAXIMO_SPI_EM_ANOS,
	PERIODO_MINIMO_SPI_EM_ANOS,
	PluviometroGraficoSpiComponent,
} from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { MapaPluviometriaInterpoladaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-pluviometria-interpolada/mapa-pluviometria-interpolada.component';
import { PluviometroTabelaPrecipitacaoAnualComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-precipitacao-anual/pluviometro-tabela-precipitacao-anual.component';
import { PluviometroTabelaPrecipitacaoAcumuladaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-precipitacao-acumulada/pluviometro-tabela-precipitacao-acumulada.component';
import { MapaIapmComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-iapm/mapa-iapm.component';
import { GraficoVariaveisMeteorologicasComponent } from '../componentes/relatorios/grafico-variaveis-meteorologicas/grafico-variaveis-meteorologicas.component';
import { VariaveisMetorologicasMapaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/variaveis-metorologicas-mapa/variaveis-metorologicas-mapa.component';
import { PluviometroTabelaPluviometricoDoEstadoComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-pluviometrico-do-estado/pluviometro-tabela-pluviometrico-do-estado.component';
import { MapaPluviometriaObservadaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-pluviometria-observada/mapa-pluviometria-observada.component';
import { MapaDesvioComponent } from '../componentes/relatorios/mapa-desvio/mapa-desvio.component';
import { GraficoPluviometriaObservadaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada/grafico-pluviometria-observada.component';
import { MapaInterpolacaoEstacoesPCDSComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-interpolacao-estacoes-pcds/mapa-interpolacao-estacoes-pcds.component';
import { MapaClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-climatologia/mapa-climatologia.component';
import { GraficoClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-climatologia/grafico-climatologia.component';
import { TabelaRelatorioSudeneComponent } from '../componentes/tabela-relatorio-sudene/tabela-relatorio-sudene.component';
import { GraficoNdcComponent } from '../componentes/relatorios/grafico-ndc/grafico-ndc.component';
import { TabelaNdcComponent } from '../componentes/relatorios/tabela-ndc/tabela-ndc.component';
import { PluviometroTabelaQuantisComponent } from '../componentes/relatorios/pluviometro-tabela-quantis/pluviometro-tabela-quantis.component';
import { PluviometroGraficoQuantisComponent } from '../componentes/relatorios/pluviometro-grafico-quantis/pluviometro-grafico-quantis.component';
import { GraficoDesvioComponent } from '../componentes/relatorios/grafico-desvio/grafico-desvio.component';
import { TabelaVecComponent } from '../componentes/relatorios/tabela-vec/tabela-vec.component';
import { TabelaRelatorioDesvioComponent } from '../componentes/tabela-relatorio-desvio/tabela-relatorio-desvio.component';
import { GraficoPluviometriaObservadoDesvioClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada-desvio-climatologia/grafico-pluviometria-observado-desvio-climatologia.component';

import { TabelaClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/tabela-climatologia/tabela-climatologia.component';
import { TabelaSPIComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/tabela-spi/tabela-spi.component';
import { TabelaPluviometriaObservadaComponent } from '../componentes/relatorios/tabela-pluviometria-observada/tabela-pluviometria-observada.component';
import { PluviometroMapaNdcComponent } from '../componentes/relatorios/pluviometro-mapa-ndc/pluviometro-mapa-ndc.component';
import {
	PERIODO_MAXIMO_IAPM_EM_ANOS,
	PERIODO_MAXIMO_IAPM_EM_MESES,
	PERIODO_MINIMO_IAPM_EM_ANOS,
	PERIODO_MINIMO_IAPM_EM_MESES,
	PluviometriaGraficoIapmComponent,
} from '../componentes/relatorios/pluviometria-grafico-iapm/pluviometria-grafico-iapm.component';
import { PluviometriaTabelaIapmComponent } from '../componentes/relatorios/pluviometria-tabela-iapm/pluviometria-tabela-iapm.component';
import { EPeriodosBusca } from '../enum';
import { ValoresExtremosTabelaComponent } from '../componentes/relatorios/valores-extremos-tabela/valores-extremos-tabela.component';

@Injectable({
	providedIn: 'root',
})
export class ComponentesRelatoriosService {
	getRelatorios(): ListaRelatorios {
		return {
			PLUVIOMETRICO: {
				GRAFICO: {
					PLUVIOMETRIA_OBSERVADA: {
						componente: GraficoPluviometriaObservadaComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
						},
					},
					NUMERO_DIAS_COM_CHUVA: {
						componente: GraficoNdcComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
						},
					},
					QUANTIS_MENSAL: {
						componente: PluviometroGraficoQuantisComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_MENSAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
						},
					},
					QUANTIS_ANUAL: {
						componente: PluviometroGraficoQuantisComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_ANUAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
						},
					},
					QUANTIS_PLUVIOMETRICOS: {
						componente: PluviometroGraficoQuantisComponent,
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_CHUVOSO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_PLUVIOMETRICOS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_PLUVIOMETRICOS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
						},
					},
					CLIMATOLOGIA: {
						componente: GraficoClimatologiaComponent,
						periodos: {
							mensal_por_nome: {},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
						},
					},
					DESVIO_MILIMETRO: {
						componente: GraficoDesvioComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO'
								);
							},
						},
					},
					DESVIO_PORCENTAGEM: {
						componente: GraficoDesvioComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO'
								);
							},
						},
					},
					IAPM: {
						componente: PluviometriaGraficoIapmComponent,
						periodos: {
							mensal: {
								unit: 'month',
								minAmount: PERIODO_MINIMO_IAPM_EM_MESES,
								amount: PERIODO_MAXIMO_IAPM_EM_MESES,
							},
							anual: {
								unit: 'year',
								minAmount: PERIODO_MINIMO_IAPM_EM_ANOS,
								amount: PERIODO_MAXIMO_IAPM_EM_ANOS,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
					SPI: {
						componente: PluviometroGraficoSpiComponent,
						periodos: {
							anual: {
								unit: 'year',
								amount: PERIODO_MAXIMO_SPI_EM_ANOS,
								minAmount: PERIODO_MINIMO_SPI_EM_ANOS,
							},
						},
					},
					PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA: {
						componente: GraficoPluviometriaObservadoDesvioClimatologiaComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
					PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA: {
						componente: GraficoPluviometriaObservadoDesvioClimatologiaComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
				},
				MAPA: {
					PLUVIOMETRIA_OBSERVADA: {
						componente: MapaPluviometriaObservadaComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					PLUVIOMETRIA_INTERPOLADA: {
						componente: MapaPluviometriaInterpoladaComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.MAPA_PRECIPITACOES]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'PLUVIOMETRIA_INTERPOLADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					MOSAICO_CHUVAS: {
						componente: VariaveisMetorologicasMapaComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'MOSAICO_CHUVAS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
							[FormularioRelatorio.VARIAVEIS_PLUVIOMETRICAS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'MOSAICO_CHUVAS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					NUMERO_DIAS_COM_CHUVA: {
						componente: PluviometroMapaNdcComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					QUANTIS_MENSAL: {
						componente: MapaQuantisComponent,
						periodos: {
							mensal_unico: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_MENSAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					QUANTIS_ANUAL: {
						componente: MapaQuantisComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_ANUAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					CLIMATOLOGIA: {
						componente: MapaClimatologiaComponent,
						periodos: {
							mensal_por_nome: {},
						},
					},
					DESVIO_MILIMETRO: {
						componente: MapaDesvioComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
						},
					},
					DESVIO_PORCENTAGEM: {
						componente: MapaDesvioComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
						},
					},
					IAPM: {
						componente: MapaIapmComponent,
						periodos: {
							periodo: {
								unit: 'month',
								minAmount: PERIODO_MINIMO_IAPM_EM_MESES,
								amount: PERIODO_MAXIMO_IAPM_EM_MESES,
							},
						},
					},
				},
				TABELA: {
					PLUVIOMETRIA_OBSERVADA: {
						componente: TabelaPluviometriaObservadaComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
						},
					},
					NUMERO_DIAS_COM_CHUVA: {
						componente: TabelaNdcComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					QUANTIS_ANUAL: {
						componente: PluviometroTabelaQuantisComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_ANUAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					QUANTIS_MENSAL: {
						componente: PluviometroTabelaQuantisComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS_MENSAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					CLIMATOLOGIA: {
						componente: TabelaClimatologiaComponent,
						periodos: {
							mensal_por_nome: {},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					DESVIO: {
						componente: TabelaRelatorioDesvioComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO'
								);
							},
						},
					},
					IAPM: {
						componente: PluviometriaTabelaIapmComponent,
						periodos: {
							mensal: {
								unit: 'month',
								minAmount: PERIODO_MINIMO_IAPM_EM_MESES,
								amount: PERIODO_MAXIMO_IAPM_EM_MESES,
							},
							anual: {
								unit: 'year',
								minAmount: PERIODO_MINIMO_IAPM_EM_ANOS,
								amount: PERIODO_MAXIMO_IAPM_EM_ANOS,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
					SPI: {
						componente: TabelaSPIComponent,
						periodos: {
							anual: {
								unit: 'year',
								amount: PERIODO_MAXIMO_SPI_EM_ANOS,
								minAmount: PERIODO_MINIMO_SPI_EM_ANOS,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'SPI' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'SPI' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] ===
										'REGIAO_PLUVIOMETRICA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'SPI' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
						},
					},
					HISTORICO_DA_SUDENE: {
						componente: TabelaRelatorioSudeneComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'HISTORICO_DA_SUDENE' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.POSTO_SUDENE]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'HISTORICO_DA_SUDENE' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					MEDIA_HISTORICA: {
						componente: PluviometricoTabelaMediaHistoricaComponent,
						periodos: {
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'MEDIA_HISTORICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PRECIPITACAO_DIARIA: {
						componente: PluviometroTabelaPrecipitacaoDiariaComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PRECIPITACAO_DIARIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PRECIPITACAO_ANUAL: {
						componente: PluviometroTabelaPrecipitacaoAnualComponent,
						periodos: {
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PRECIPITACAO_ANUAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PRECIPITACAO_ACUMULADA: {
						componente: PluviometroTabelaPrecipitacaoAcumuladaComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PRECIPITACAO_ACUMULADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PLUVIOMETRICO_POR_POSTO: {
						componente: PluviometroTabelaPluviometricoPorPostoComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRICO_POR_POSTO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PLUVIOMETRICO_DO_ESTADO: {
						componente: PluviometroTabelaPluviometricoDoEstadoComponent,
						periodos: {
							mensal_unico: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
						},
					},
					VALORES_EXTREMOS_PLUVIOMETRIA: {
						componente: TabelaVecComponent,
						periodos: {
							periodo: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'VALORES_EXTREMOS_PLUVIOMETRIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'VALORES_EXTREMOS_PLUVIOMETRIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
				},
			},
			EVAPOTRANSPIRACAO: {
				TABELA: {
					componente: EvapotranspiracaoTabelaComponent,
				},
			},
			VARIAVEL_METEOROLOGICA: {
				TABELA: { componente: VariaveisMeteorologicasTabelaComponent },
				GRAFICO: { componente: GraficoVariaveisMeteorologicasComponent },
				MAPA: {
					HISTORICO: { componente: VariaveisMetorologicasMapaComponent },
					ISOLINHA: { componente: MapaInterpolacaoEstacoesPCDSComponent },
				},
			},
			VALORES_EXTREMOS: {
				TABELA: {
					componente: ValoresExtremosTabelaComponent,
				},
			},
		};
	}
}
