import * as moment from 'moment';
import { format } from 'date-fns-tz';
import { DateTime, DateTimeFormatOptions, DateTimeOptions } from 'luxon';
import ZonedDateTime from '@utils/date/zoned-datetime';
import { months } from './date/nomes.mock';

import { PeriodosLabel } from '@modulos/home/submodulos/dados-meteorologicos/interfaces';
const MASCARA_SISTEMA_24H = 'dd/MM/yyyy HH:mm';
export function formatar(date: any, formato: any): any {
	return moment(date, formato);
}
export interface Periodo {
	diaInicio: ZonedDateTime;
	diaFim: ZonedDateTime;
}

export const DEFAULT_OPTIONS = <DateTimeOptions>{
	locale: 'pt',
	zone: 'America/Sao_Paulo',
	setZone: true,
};

export class DateTimeUtils {
	/**
	 * Verifica se uma data é antes da outra
	 * @param date1 a data a ser verificada
	 * @param date2 a data a ser comparada
	 * @returns boleana que termina se a data1 é antes da data2
	 */
	static ehAntes(date1: Date, date2: Date): boolean {
		return date1.getTime() < date2.getTime();
	}

	/**
	 * Verifica se uma data é depois da outra
	 * @param date1 a data a ser verificada
	 * @param date2 a data a ser comparada
	 * @returns boleana que termina se a data1 é depois da data2
	 */
	static ehDepois(date1: Date, date2: Date): boolean {
		return date1.getTime() > date2.getTime();
	}

	/**
	 * Obtem string da data formatada em um formato específico
	 * @param dataOriginal a string da data original
	 * @param formatoSaida o formato de saída
	 * @returns a data formatada
	 */
	static formatarData(dataOriginal: string, formatoSaida: string): string {
		if (dataOriginal) {
			const dataMoment = moment(dataOriginal);
			const dataFormatada = format(dataMoment.toDate(), formatoSaida, {
				timeZone: 'America/Sao_Paulo',
			});
			return dataFormatada;
		}
		return '';
	}

	/**
	 * Obtem uma string que representa um período entre duas datas
	 * @param dataInicial O início do intervalo
	 * @param dataFinal O fim do intervalo
	 * @param periodo O tipo do intervalo PERÍODO, MENSAL ou ANUL
	 * @param isPeriodoUnico boleana que determina se o período enviado é composto por apenas uma data
	 * @returns
	 */
	static formatarDataPeriodo(
		dataInicial: string | number | Date,
		dataFinal: string | number | Date,
		periodo?: string | PeriodosLabel,
		isPeriodoUnico = false
	): string {
		const dataInicialFormatada = new Date(dataInicial);
		const dataFinalFormatada = new Date(dataFinal);
		if (periodo == 'MENSAL' || periodo == 'mensal') {
			if (isPeriodoUnico) {
				return `${format(dataInicialFormatada, 'MM/yyyy')}`;
			}
			return `${format(dataInicialFormatada, 'MM/yyyy')} a ${format(
				dataFinalFormatada,
				'MM/yyyy'
			)}`;
		}

		if (periodo?.toLowerCase() == 'periodo') {
			if (isPeriodoUnico) {
				return `${format(dataInicialFormatada, 'dd/MM/yyyy')}`;
			}
			return `${format(dataInicialFormatada, 'dd/MM/yyyy')} a ${format(
				dataFinalFormatada,
				'dd/MM/yyyy'
			)}`;
		}

		if (isPeriodoUnico) {
			return `${format(dataInicialFormatada, 'yyyy')}`;
		}
		return `${format(dataInicialFormatada, 'yyyy')} a ${format(
			dataFinalFormatada,
			'yyyy'
		)}`;
	}

	/**
	 * A partir de uma data ISO obtem uma string no formato dd/MM/yyyy HH:mm
	 * @param value A data ISO
	 * @returns A string convertida
	 */
	static formatarISOParaFormato24hrs(value: string): string {
		const date = DateTime.fromISO(value, DEFAULT_OPTIONS);
		return date.toFormat(MASCARA_SISTEMA_24H);
	}

	/**
	 * Converte um valor em milissegundos para uma string formatada como data.
	 * @param milli O tempo em milissegundos a ser formatado.
	 * @param formato  As opções de formatação da data.
	 * @returns Uma string representando a data formatada de acordo com o formato especificado.
	 */
	static formatarMilli(
		milli: number,
		formato: DateTimeFormatOptions = DateTime.DATETIME_SHORT
	): string {
		return DateTime.fromMillis(milli, DEFAULT_OPTIONS)
			.toLocaleString(formato)
			.trim();
	}

	/**
	 * Converte uma string de data para o formato ZonedDateTime, ajustado para o fuso horário de São Paulo.
	 * @param dateString - A data em formato de string a ser convertida.
	 * @returns Uma string da data convertida para o formato ZonedDateTime
	 */
	static formatToZonedDateTime(dateString: string): string {
		const inputDate = new Date(dateString);
		inputDate.setDate(inputDate.getDate() + 1);
		const formattedDate = format(inputDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX", {
			timeZone: 'America/Sao_Paulo',
		});
		return formattedDate;
	}

	/**
	 * Obtem o número inteiro correspondente ao ano da data fornecida
	 * @param dataString A data
	 * @returns Ano da data fornecida
	 */
	static getAno(dataString: string | Date): number {
		return new Date(dataString).getFullYear();
	}

	/**
	 * Obtem o número inteiro correspondente ao ano em UTC da data fornecida
	 * @param dataString A data
	 * @returns Ano da data fornecida
	 */
	static getAnoUTC(dataString: string | Date): number {
		return new Date(dataString).getUTCFullYear();
	}

	/**
	 * Obtem uma data com horas adicionais fornecidas
	 * @param dataLocal Data a ser incrementada com as horas adicionais
	 * @param horas horas adicionais
	 * @returns A data com as horas adicionais
	 */
	static getDataComHorasAdicionais(data: Date, horas: number): Date {
		const result = new Date(data);
		result.setHours(result.getHours() + horas);
		return result;
	}

	/**
	 * Obtem a diferença de horas de uma localidade especifica pro UTC
	 * @param dataLocal Data atual da localidade
	 * @returns A diferença de horas da hora recebida pro UTC
	 */
	static getDiferencaHorasUTC(dataLocal: Date): number {
		const diferencaMinutos = new Date(dataLocal).getTimezoneOffset();
		const diferencaHoras = diferencaMinutos / 60;
		return diferencaHoras;
	}

	/**
	 * Calcula um período de tempo com base em um número de horas retroativas a partir do momento atual.
	 * @param horas - A quantidade de horas para definir o intervalo de tempo.
	 * @returns Um objeto `Periodo` contendo duas datas:
	 *          - `diaInicio`: A data e hora calculada subtraindo as horas especificadas da data final.
	 *          - `diaFim`: A data e hora atual no momento da chamada.
	 */
	static getPeriodoPorHoras(horas: number): Periodo {
		const diaFim = new ZonedDateTime();
		const diaInicio = new ZonedDateTime();
		diaInicio.dateInstance.setHours(diaFim.dateInstance.getHours() - horas);
		return { diaInicio, diaFim };
	}

	/**
	 * Obtem a diferença entre data em milisegundos
	 * @param date1 minuendo
	 * @param date2 subtrator
	 * @returns resultado da subtração em milisegundos
	 */
	static subtrairEmMilisegundos(date1: Date, date2: Date): number {
		return Math.abs(date1.getTime() - date2.getTime());
	}

	/**
	 * Retorna uma nova data com meses subtraídos
	 * @param data A data a ser reduzida
	 * @param meses Os meses a serem reduzidos
	 * @returns A nova data
	 */
	static subtrairMes(data: Date, meses: number): Date {
		const novaData = new Date(data);
		novaData.setMonth(novaData.getMonth() - meses);
		return novaData;
	}

	static getMesNumericoEAno(dataOriginal: Date) {
		return dataOriginal.getMonth() + 1 < 10
			? `0${dataOriginal.getMonth() + 1 + '/' + dataOriginal.getFullYear()}`
			: `${dataOriginal.getMonth() + 1 + '/' + dataOriginal.getFullYear()}`;
	}

	static getMesPorExtensoEAno(dataOriginal: Date) {
		return (
			months[dataOriginal.getMonth() + 1].label +
			'/' +
			dataOriginal.getFullYear()
		);
	}

	/**
	 * Obtem o datetime do inicio do ano
	 * @param ano número inteiro correspondente ao ano
	 * @returns O datetime no início do ano
	 */
	static getDataNoInicioAno(ano: number): Date {
		return new Date(ano, 0, 1);
	}

	/**
	 * Obtem o datetime do fim do ano
	 * @param ano número inteiro correspondente ao ano
	 * @returns O datetime no fim do ano
	 */
	static getDataNoFimAno(ano: number): Date {
		return new Date(ano, 11, 31, 23, 59, 59, 999);
	}
}
