import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { DadosMeteorologicosComponent } from '@home/submodulos/dados-meteorologicos/pages/index/dados-meteorologicos.component';
import { ParaVoceComponent } from '@home/submodulos/para-voce/para-voce.component';
import { PrevisaoTempoComponent } from './submodulos/previsao-tempo/pages/index/previsao-tempo.component';
import { PrevisaoClimaticaComponent } from '@home/submodulos/previsao-climatica/pages/index/previsao-climatica.component';
import { DadosHidrologicosComponent } from '@home/submodulos/dados-hidrologicos/pages/index/dados-hidrologicos.component';

@NgModule({
	exports: [RouterModule],
	imports: [
		RouterModule.forChild([
			{
				path: '',
				loadChildren: () =>
					import('../landing-page/landing-page.module').then(
						m => m.LandingPageModule
					),
			},
			{
				path: '',
				component: HomePageComponent,
				title: 'Home - SEIRA',
				children: [
					{
						path: 'dados-meteorologicos',
						component: DadosMeteorologicosComponent,
						loadChildren: () =>
							import(
								'@home/submodulos/dados-meteorologicos/dados-meteorologicos.module'
							).then(m => m.DadosMeteorologicosModule),
					},
					{
						path: 'dados-hidrologicos',
						component: DadosHidrologicosComponent,
						loadChildren: () =>
							import(
								'@home/submodulos/dados-hidrologicos/dados-hidrologicos.module'
							).then(m => m.DadosHidrologicosModule),
					},
					{
						path: 'previsao-tempo',
						component: PrevisaoTempoComponent,
						loadChildren: () =>
							import('./submodulos/previsao-tempo/previsao-tempo.module').then(
								m => m.PrevisaoTempoModule
							),
					},
					{
						path: 'previsao-climatica',
						component: PrevisaoClimaticaComponent,
						loadChildren: () =>
							import(
								'./submodulos/previsao-climatica/previsao-climatica.module'
							).then(m => m.PrevisaoClimaticaModule),
					},

					// {
					// 	path: 'zoneamentos',
					// 	component: ZoneamentosComponent,
					// 	loadChildren: () =>
					// 		import('./submodulos/zoneamentos/zoneamentos.module').then(
					// 			m => m.ZoneamentosModule
					// 		),
					// },
					// { path: 'imagens-satelite', component: ImagensSateliteComponent },
					{ path: 'para-voce', component: ParaVoceComponent },
				],
			},
		]),
	],
})
export class HomeRoutingModule {}
