import { PublicSelectComponent } from '@componentes/public-select/public-select.component';
import {
	Component,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ClimaService } from '@home/submodulos/previsao-climatica/services/clima.service';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { Select } from '@layout/interfaces/select';

@Component({
	selector: 'seira-public-select-execucao-clima',
	templateUrl: './public-select.component.html',
	styleUrls: ['./public-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => PublicSelectExecucaoClimaComponent),
		},
	],
})
export class PublicSelectExecucaoClimaComponent
	extends PublicSelectComponent<Select<number>, number>
	implements OnChanges, OnInit
{
	override optionValue = 'value';
	override optionLabel = 'name';
	override carregando = true;
	override carregandoLabel = 'Carregando as execuções';
	override label = 'Execução';
	override placeholder = 'Informe o tempo de execução';
	FORMATACAO_TEMPO = "dd 'de' MMMM 'de' yyyy HH:mm OOOO";
	@Input()
	execucao?: number;

	@Output() dataExecucao?: Date;
	constructor(private climaService: ClimaService) {
		super();
	}
	ngOnInit() {
		this.climaService.listExecucoes().subscribe(execucoes => {
			this.options = execucoes.map(({ dataExecucao, idResultadoModelo }) => ({
				value: idResultadoModelo,
				name: `REGCM4 - ${format(
					new Date(dataExecucao),
					this.FORMATACAO_TEMPO,
					{
						locale: ptBrLocale,
					}
				)}`,
			}));
			this.value = this.options[0].value;
			this.onValueChange(this.value);
			this.carregando = false;
			this.carregandoChange.emit(this.carregando);
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes['execucao']) {
			const execucao = changes['execucao'].currentValue;

			if (execucao !== null && execucao !== undefined) {
				this.buscarOptions(changes['execucao'].currentValue);
			}
		}
	}

	buscarOptions(idExecucao: number): void {
		this.carregando = true;
		this.carregandoChange.emit(this.carregando);
		this.climaService
			.listPrevisaoClimatica(idExecucao)
			.subscribe(municipios => {
				const oldOptions = this.options;
				this.options = municipios.dadosMunicipio;
				this.carregando = false;
				this.carregandoChange.emit(this.carregando);
			});
	}
}
