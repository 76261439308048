import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Meses } from '@home/submodulos/dados-meteorologicos/enum';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { SPI } from '@home/submodulos/dados-meteorologicos/interfaces/SPI';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { formatISO, startOfMonth, subYears } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { PERIODO_MINIMO_SPI_EM_ANOS } from '../pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { filterSelectOptions, formataValorPrecipitacao } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { Select } from '@layout/interfaces/select';
import { Agrupamento } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'seira-tabela-spi',
	templateUrl: './tabela-spi.component.html',
	styleUrls: ['./tabela-spi.component.scss'],
})
export class TabelaSPIComponent implements OnInit, OnDestroy {
	inputs = inject(INPUTS_RELATORIOS);

	periodoTitle = '';

	carregando = true;
	descricaoRelatorio =
		'O Relatório de Índice de Precipitação Padronizado (SPI) dispõe informações a respeito do monitoramento de seca em um determinado período para as regiões pluviométricas';

	colunasSPI: ADTColumns[] = [];
	resultados: SPI[] = [];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];
	agrupamento: Select<string>[];
	private _destroyed = new Subject();

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.MESORREGIAO,
			Agrupamento.MICRORREGIAO,
			Agrupamento.MUNICIPIO,
			Agrupamento.BACIA,
			Agrupamento.SUBBACIA
		);
		this.setValidators();
	}

	ngOnInit() {
		this.prencherValoresIniciais();

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.clearValidators();
		this._destroyed.next(undefined);
	}

	prencherValoresIniciais() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);

		const inicioDefault = startOfMonth(
			subYears(new Date(), PERIODO_MINIMO_SPI_EM_ANOS)
		);
		const fimDefault = new Date();

		this.inputs.form.patchValue({
			dataInicio: inicioDefault,
			dataFim: fimDefault,
		});
	}

	setColunasTabela() {
		this.colunasSPI = [
			{
				data: 'nomeRegiao',
				title: this.setTituloAgrupamento(),
			},
			{
				data: null,
				title: 'Mês/Ano',
				className: 'text-center',
				render: {
					_: (spi: SPI) => `${Meses[spi.mes - 1]}/${spi.ano}`,
					sort: (spi: SPI) => `${spi.ano}${spi.mes}`,
				},
			},
			{
				data: 'spi',
				title: 'Valor SPI ',
				type: 'string',
				className: 'text-center',
				render: (spi: number) => {
					return formataValorPrecipitacao(spi);
				},
			},

			{
				data: 'spi',
				title: 'SPI',
				type: 'string',
				className: 'text-center',
				render: (spi: number) => {
					const valorSPIformatado = spi.toFixed(1);
					const valorSPI = this.getIndiceDeSeca(valorSPIformatado);

					return valorSPI;
				},
			},
		];
	}

	private setTituloAgrupamento(): string {
		return this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)?.value ===
			'MUNICIPIO_POSTO'
			? 'Município/Posto'
			: 'Região Pluviométrica';
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					?.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				break;
		}
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const regiaoId = this.getDataFromForm(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		)?.value;
		const postoId = this.getDataFromForm(FormularioRelatorio.ESTACAO)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (this.inputs.form.invalid) {
			return;
		}

		this.periodoTitle = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			'anual'
		);
		this.carregando = true;
		this.inputs.setLoading(true);

		this.relatoriosService
			.buscarRelatorioSPI(
				formatISO(new Date(diaInicio)),
				formatISO(new Date(diaFim)),
				agrupamento === 'MUNICIPIO_POSTO' ? postoId : regiaoId,
				agrupamento
			)
			.subscribe({
				next: SPIResult => {
					this.setColunasTabela();
					this.resultados = SPIResult;
				},
				error: () => {
					this.toastrService.error(
						'Erro ao gerar tabela de SPI, tente novamente'
					);
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const tituloTabela = `Relatório - Índice de precipitação padronizada (SPI) - ${this.periodoTitle}`;
		documentDefinition.content.push({
			text: tituloTabela,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.resultados) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData: (string | number)[][] = [];

		tableData.push([
			this.setTituloAgrupamento(),
			'Mês/Ano',
			'Valor SPI',
			'SPI',
		]);

		this.resultados.forEach(dadoRelatorio => {
			tableData.push([
				dadoRelatorio.nomeRegiao,
				Meses[dadoRelatorio.mes - 1] + '/' + dadoRelatorio.ano,
				!isNaN(dadoRelatorio.spi)
					? formataValorPrecipitacao(dadoRelatorio.spi)
					: '-',
				this.getIndiceDeSeca(dadoRelatorio.spi.toFixed(1)),
			]);
		});
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData: (string | number)[][] = [];

		tableData.push([
			this.setTituloAgrupamento(),
			'Mês/Ano',
			'Valor SPI',
			'SPI',
		]);

		this.resultados.forEach(dadoRelatorio => {
			tableData.push([
				dadoRelatorio.nomeRegiao,
				Meses[dadoRelatorio.mes - 1] + '/' + dadoRelatorio.ano,
				!isNaN(dadoRelatorio.spi)
					? formataValorPrecipitacao(dadoRelatorio.spi)
					: '-',
				this.getIndiceDeSeca(dadoRelatorio.spi.toFixed(1)),
			]);
		});

		DocumentExporter.gerarCSV(
			tableData,
			`Relatório - Índice de precipitação padronizada (SPI) - ${this.periodoTitle}`
		);
	}

	getIndiceDeSeca(valor: string): string {
		for (const key of Object.keys(spiInfo)
			.map(Number)
			.sort((a, b) => b - a)) {
			if (Number(valor) >= Number(key)) {
				return spiInfo[key];
			}
		}
		return '';
	}
	exportarTXT() {
		let txtData = '';

		this.resultados.forEach(dadoRelatorio => {
			txtData +=
				`${this.setTituloAgrupamento()}: ${dadoRelatorio.nomeRegiao}\n` +
				`Mês/Ano: ${dadoRelatorio.mes}/${dadoRelatorio.ano}\n` +
				`Valor SPI: ${
					!isNaN(dadoRelatorio.spi)
						? formataValorPrecipitacao(dadoRelatorio.spi)
						: '-'
				}\n` +
				`SPI: ${this.getIndiceDeSeca(dadoRelatorio.spi.toFixed(1))}\n`;
		});

		DocumentExporter.gerarTXT(
			txtData,
			`Relatório - Índice de precipitação padronizada (SPI) - ${this.periodoTitle}`
		);
	}
}

export enum IndiceSeca {
	EXTREMAMENTE_UMIDO = 'Extremamente úmido',
	SEVERAMENTE_UMIDO = 'Severamente úmido',
	MODERAMENTE_UMIDO = 'Moderamente úmido',
	NORMAL_SPI = 'Normal SPI',
	MODERADAMENTE_SECO = 'Moderadamente seco',
	SEVERAMENTE_SECO = 'Severamente Seco',
	EXTREMAMENTE_SECO = 'Extremamente Seco',
}
export const spiInfo: { [key: string]: string } = {
	'-2': IndiceSeca.EXTREMAMENTE_SECO,
	'-1.5': IndiceSeca.SEVERAMENTE_SECO,
	'-1': IndiceSeca.MODERADAMENTE_SECO,
	'1': IndiceSeca.NORMAL_SPI,
	'1.5': IndiceSeca.MODERAMENTE_UMIDO,
	'2': IndiceSeca.SEVERAMENTE_UMIDO,
	'2.5': IndiceSeca.EXTREMAMENTE_UMIDO,
};
