<seira-sem-dados
	*ngIf="!dados || dados.length === 0; else mapa"></seira-sem-dados>

<ng-template #mapa>
	<div class="d-flex">
		<div class="col-12 vh-100 pe-lg-0 pe-1" style="max-height: 500px">
			<seira-mapa-paraiba-leaflet
				(mapaEmitter)="setupMap($event)"
				[zoomOnlyWithCtrl]="true"
				[monitoramentoMapa]="map!" />
		</div>
	</div>

	<div>
		<seira-legenda-ndc></seira-legenda-ndc>
	</div>
</ng-template>
