import { Component, EventEmitter, inject, OnDestroy } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import * as Highcharts from 'highcharts/highstock';

import { Validators } from '@angular/forms';
import {
	optionsChartClimatologia,
	relatorioClimatologia,
} from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-climatologia/chart-options';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Agrupamento } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { gerarFilenameGrafico } from '../../../utils';
import { Meses } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { capitalizeFirstLetter, compararStrings } from '@utils';
import { corrigeDuplicacaoNome } from '../../../utils';
import { Chart } from 'highcharts';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
	selector: 'seira-climatologia-grafico',
	templateUrl: './grafico-climatologia.component.html',
	styleUrls: ['./grafico-climatologia.component.scss'],
})
export class GraficoClimatologiaComponent
	implements OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	chart?: Highcharts.Chart;
	resultado: ClimatologiaResponseDTO[] = [];
	loading = true;
	gerou = false;
	private subscription = new Subscription();
	_destroyed = new Subject();
	tipoAgrupamento: Agrupamento = Agrupamento.MUNICIPIO_POSTO;
	descricaoRelatorio =
		'Define-se como climatologia pluviométrica, a média histórica de pluviometria para períodos longos, preferencialmente de 30 anos.';

	height = '190px';
	chartInstance = new EventEmitter<Chart>();
	chartOptions = optionsChartClimatologia();

	series: Array<Partial<Highcharts.SeriesOptionsType> & Record<string, any>> =
		relatorioClimatologia;

	periodoTitulo = '';

	constructor(private climatologiaService: RelatoriosService) {
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.MES_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MES_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();

		this.subscription.unsubscribe();
		this._destroyed.complete();
		this.chartInstance.complete();
		this.resetarGrafico();
	}

	resetarGrafico() {
		if (this.chart) {
			while (this.chart.series?.length) {
				this.chart.series[0].remove(false);
			}
			this.chart = undefined;
		}
		this.series = [...relatorioClimatologia];
		this.chartOptions = optionsChartClimatologia();
	}

	gerarRelatorio(): void {
		if (this.inputs.form.invalid) {
			return;
		}

		this.resetarGrafico();

		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);
		const idMunicipio = this.inputs.form.get('municipio')?.value;
		const idMicrorregiao = this.inputs.form.get('microrregiao')?.value;
		const idPosto = this.inputs.form.get('estacao')?.value;

		const mesInicioFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesInicio)
		);
		const mesFimFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesFim)
		);
		this.periodoTitulo = ` ${mesInicioFormatado} a ${mesFimFormatado}`;

		this.tipoAgrupamento = this.inputs.form.get('agrupamento')?.value;

		this.climatologiaService
			.buscarClimatologiaPorMunicipio(
				mesInicio.toLowerCase(),
				mesFim.toLowerCase(),
				this.tipoAgrupamento,
				idMunicipio,
				idPosto,
				idMicrorregiao
			)
			.subscribe({
				next: climatologias => {
					this.resultado = climatologias;

					this.resultado = this.ordenarPorMes(this.resultado);

					this.chartOptions = {
						...this.chartOptions,
						exporting: {
							enabled: true,
							buttons: {
								contextButton: {
									menuItems: [
										'viewFullscreen',
										'separator',
										'downloadPNG',
										'downloadJPEG',
									],
								},
							},
							filename: gerarFilenameGrafico(
								`grafico_climatologia_${this.inputs.form
									.get(FormularioRelatorio.AGRUPAMENTO)
									?.value.toLocaleLowerCase()}`
							),
						},
					};
				},
				error: () => {
					this.inputs.setLoading(false);
					this.loading = false;
					this.gerou = false;
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.loading = false;
					this.gerou = true;
				},
			});
	}

	onChartCreated(chart: Chart) {
		if (this.resultado) {
			this.loading = false;
			this.chart = chart;
			this.chartInstance.emit(chart);

			this.setSeries();
		}
	}

	lidarComAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO);
		const municipioPosto = this.inputs.form.get(FormularioRelatorio.ESTACAO);

		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.lidarComAgrupamento();
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: () => {
					this.lidarComAgrupamento();
				},
			});
		this.inputs.form
			.get(FormularioRelatorio.MES_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MES_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setSeries() {
		this.chart?.series.forEach(el => el.remove());
		this.series?.map(serie => {
			serie.data = [];
			return serie;
		});

		this.resultado = this.ordenarPorMes(this.resultado);
		const meses: string[] = this.extrairMeses();
		const agrupamentoUnicoGrafico = [
			'MUNICIPIO',
			'MUNICIPIO_POSTO',
			'MICRORREGIAO',
		];

		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		const mesInicioFormatado = this.verificaMes(mesInicio).toLowerCase();
		const mesFimFormatado = this.verificaMes(mesFim).toLowerCase();

		const periodoExibido =
			mesInicioFormatado === mesFimFormatado
				? capitalizeFirstLetter(mesInicioFormatado)
				: `${capitalizeFirstLetter(
						mesInicioFormatado
				  )} a ${capitalizeFirstLetter(mesFimFormatado)}`;

		if (agrupamentoUnicoGrafico.includes(<Agrupamento>this.tipoAgrupamento)) {
			const nomeAgrupamento = this.resultado[0]?.nome
				? this.resultado[0]?.nome
				: '-';
			const nomeMunicipio =
				this.tipoAgrupamento === agrupamentoUnicoGrafico[1] && this.resultado[0]
					? this.resultado[0]?.dadosPosto?.municipio
					: '';

			const nomeFinal =
				this.tipoAgrupamento.toString() === 'MUNICIPIO_POSTO'
					? corrigeDuplicacaoNome(`${nomeMunicipio}/${nomeAgrupamento}`)
					: nomeAgrupamento;

			this.chart?.setTitle({
				text: `Climatologia de ${nomeFinal} - ${periodoExibido}`,
				align: 'center',
			});

			this.resultado.forEach(value => {
				this.series[0].data.push({
					y: Number.parseFloat(value?.climatologia.toFixed(1)),
					name: capitalizeFirstLetter(this.verificaMes(value.mes)),
				});
			});

			this.chart!.xAxis[0].setCategories(meses);
			this.series.forEach(el => {
				this.chart?.addSeries(el as any);
			});
		} else {
			this.configurarChart(this.resultado);
		}
	}

	obterMes(data: string): string {
		const date = new Date(data);
		const mes = date.toLocaleString('default', { month: 'long' });
		return mes.toLowerCase();
	}

	configurarChart(preciptacoesAgrupadas: ClimatologiaResponseDTO[]) {
		const categorias = this.extrairNomes(preciptacoesAgrupadas);

		const meses: string[] = this.extrairMeses();

		this.chart?.xAxis[0].setCategories(meses);
		this.chart?.setTitle({
			text: `Gráfico de Climatologia`,
			align: 'center',
		});
		this.series = categorias.map(res => {
			return {
				name: res,
				data: meses.map(mes => {
					const entry = this.resultado.find(
						item =>
							item.nome === res &&
							capitalizeFirstLetter(this.verificaMes(item.mes)) === mes
					);

					return entry
						? {
								name: this.verificaMes(entry.mes),
								y: Number.parseFloat(entry?.climatologia.toFixed(1)),
						  }
						: null;
				}),
			};
		});

		this.series.forEach(el => {
			this.chart?.addSeries(el as any);
		});
	}
	verificaMes(mes: string) {
		return mes === 'marco' ? 'Março' : capitalizeFirstLetter(mes);
	}
	extrairNomes(preciptacoesAgrupadas: ClimatologiaResponseDTO[]) {
		return [...new Set(preciptacoesAgrupadas.map(el => el.nome))];
	}

	extrairMeses() {
		return [
			...new Set(
				this.resultado.map((item: { mes: string }) => {
					if (item.mes === 'marco') {
						return capitalizeFirstLetter('março');
					} else {
						return capitalizeFirstLetter(item.mes);
					}
				})
			),
		];
	}

	ordenarPorMes(
		resultados: ClimatologiaResponseDTO[]
	): ClimatologiaResponseDTO[] {
		return resultados.sort((a, b) => {
			const indiceA = mesesDoAno[a.mes.toLowerCase()];
			const indiceB = mesesDoAno[b.mes.toLowerCase()];
			return indiceA - indiceB;
		});
	}
}
const mesesDoAno: Record<string, number> = {
	janeiro: 1,
	fevereiro: 2,
	marco: 3,
	abril: 4,
	maio: 5,
	junho: 6,
	julho: 7,
	agosto: 8,
	setembro: 9,
	outubro: 10,
	novembro: 11,
	dezembro: 12,
};
