<div>
	<label class="text-center text-tertiary fw-semibold w-100">
		Pluviometria observada (mm) - {{ periodoTitulo }}
	</label>
	<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />
	<div id="mapa-legenda">
		<div class="d-flex">
			<div class="col-12 vh-100 pe-lg-0 pe-1" style="max-height: 500px">
				<seira-mapa-paraiba-leaflet
					(mapaEmitter)="setupMap($event)"
					[zoomOnlyWithCtrl]="true"
					[loading]="loadingFiltrando"
					[monitoramentoMapa]="map" />
			</div>
		</div>

		<seira-legenda-pluviometria-observada [legendaInfo]="legenda" />
	</div>
</div>
