/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input, OnInit } from '@angular/core';
import { AgrupamentoResponse } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import {
	capitalizeFirstLetter,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';
import { corrigeDuplicacaoNome } from '../../utils';

@Component({
	selector: 'seira-popup-agrupamento',
	templateUrl: './popup-agrupamento.component.html',
	styleUrls: ['./popup-agrupamento.component.scss'],
})
export class PopupAgrupamentoComponent implements OnInit {
	@Input() agrupamento?: AgrupamentoResponse;
	@Input() headerColor = 'bg-primary';
	@Input() titleColor = 'white';
	nome: string;
	ngOnInit() {
		this.nome = corrigeDuplicacaoNome(this.agrupamento?.nome || '');
	}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}
