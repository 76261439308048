import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	Renderer2,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewChildren,
	ViewEncapsulation,
} from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import {
	microrregioesComRanges,
	MunicipiosPaths,
	municipioSvgPaths,
} from '@componentes/mapa-paraiba-svg/svg-paths';
import { ValorPorGeocodigo } from '@componentes/mapa-paraiba-svg/mapa.interfaces';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import {
	EventoMesorregiaoPorPeriodo,
	InformacaoMesorregiao,
} from '@home/submodulos/previsao-tempo/interfaces/mapa-previsao';
import { v4 as uuidv4 } from 'uuid';
import { CORES, coresQuantis, legendaInfoBoletimDiario } from './legenda';
import { LegendaItem } from '@home/submodulos/dados-meteorologicos/interfaces/legenda-de-cores';
import { isNotNuloOuUndefined } from '@utils';

type LocalizacaoMesorregioes = Record<string, { x: number; y: number }>;
type TipoCor = 'normal' | 'permanente' | 'localizacao' | 'selecionado';
type PopoverContext = {
	color: string | null;
	geocodigo: string | undefined;
	nome: string | null;
	dadosComMesmoGeocodigo?: ValorPorGeocodigo[];
	valor: number | null;
	data?: any;
};

@Component({
	selector: 'seira-mapa-paraiba-svg',
	templateUrl: './mapa-paraiba-svg.component.svg',
	styleUrls: ['./mapa-paraiba-svg.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MapaParaibaSvgComponent implements OnChanges, OnInit {
	@ViewChildren(PopoverDirective) popovers!: QueryList<PopoverDirective>;
	@ViewChildren(TooltipDirective) tooltips!: QueryList<TooltipDirective>;
	@ViewChildren('path') paths!: QueryList<ElementRef<SVGPathElement>>;
	@ViewChild('mapa') mapa!: ElementRef<SVGGeometryElement>;
	@Input() loading = false;
	@Input() popoverTemplate?: TemplateRef<any>;
	@Input() color = 'none';
	@Input() onClickColor = 'none';
	@Input() atributosMunicipio?: ValorPorGeocodigo[];
	@Input() id: string | number = `mapa-svg${uuidv4()}`;
	@Input() tipoMapa: 'municipios' | 'precipitacao' | 'estatico' = 'municipios';
	@Input() informacoesMesorregioes: InformacaoMesorregiao[] = [];
	@Input() eventosMesorregiao?: EventoMesorregiaoPorPeriodo[] = [];
	@Input() loadingButton = false;
	@Input() geocodigoMunicipioSelecionado?: number;
	@Input() customSetColorByValue?: (value: number) => string;
	@Output() loadingButtonChange = new EventEmitter<boolean>();
	@Output() limparSelectMunicipio = new EventEmitter();
	@Output() legendaItems = new EventEmitter<LegendaItem[]>();
	@Output() geocodigoMunicipioSelecionadoChange = new EventEmitter<number>();
	@Input() microrregiao?: string;
	@Input() asImage = false;
	imagemSrc?: string;
	hoverColor = '#BFD9E4';
	isHovered = false;
	popoverContext?: PopoverContext;
	municipioPaths: MunicipiosPaths[] = [];

	legenda: LegendaItem[] = [
		{ color: CORES.BRANCO_NEVE, label: 'Muito seco', index: 0 },
		{ color: CORES.AMARELO, label: 'Seco', index: 1 },
		{ color: CORES.AZUL_CLARO, label: 'Normal', index: 2 },
		{ color: CORES.AZUL, label: 'Chuvoso', index: 3 },
		{ color: CORES.AZUL_ESCURO, label: 'Muito chuvoso', index: 4 },
		{ color: CORES.CINZA, label: 'Sem informação', index: 5 },
	];

	private readonly ESTADO_COMPLETO = 'Estado completo';

	ngOnInit() {
		this.setMunicipio();
	}

	localizacaoMesorregioes: LocalizacaoMesorregioes = {
		'SERTÃO PARAIBANO': {
			x: 130000,
			y: 115000,
		},
		BORBOREMA: {
			x: 230000,
			y: 160000,
		},
		'AGRESTE PARAIBANO': {
			x: 285000,
			y: 120000,
		},
		'MATA PARAIBANA': {
			x: 345000,
			y: 100000,
		},
	};

	constructor(private renderer: Renderer2) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['atributosMunicipio']) {
			this.setMunicipio();
		}
	}
	setMunicipio() {
		if (this.microrregiao && this.microrregiao !== this.ESTADO_COMPLETO) {
			this.municipioPaths = municipioSvgPaths.filter(
				el => el.microrregiao == this.microrregiao
			);
		} else {
			this.municipioPaths = municipioSvgPaths;
		}
		setTimeout(() => {
			this.setColors();
		}, 0);
	}

	onMouseEnter(event: MouseEvent) {
		const element = event.target as SVGPathElement;
		this.popoverContext = this.getPopoverContext(element);
		if (this.popoverContext.color === this.onClickColor) return;

		this.changeColor(element, this.hoverColor);
	}

	onMouseLeave(event: MouseEvent) {
		this.clearColor(event.target as SVGPathElement);
	}

	onClick(event: MouseEvent, popover: PopoverDirective) {
		const element = event.target as SVGPathElement;
		this.popoverContext = this.getPopoverContext(element);
		this.selecionarMunicipio(event.target as SVGPathElement, popover);
	}

	private selecionarMunicipio(path: SVGPathElement, popover: PopoverDirective) {
		if (this.popoverTemplate) {
			this.togglePopover(path, popover);

			const clickedPathTooltip = this.getTooltipByGeocodigo(
				+popover.popoverContext.geocodigo
			);
			const openTooltip = this.getOpenTooltip();
			openTooltip?.hide();
			if (clickedPathTooltip?.tooltipId === openTooltip?.tooltipId) {
				this.removerLocalizacaoMunicipio(+popover.popoverContext.geocodigo);
				this.limparSelectMunicipio.emit();
			}

			this.geocodigoMunicipioSelecionado = +popover.popoverContext.geocodigo;
			this.geocodigoMunicipioSelecionadoChange.emit(
				this.geocodigoMunicipioSelecionado
			);
		}
	}

	selecionarMunicipioPorGeocodigo(geocodigo: number) {
		const path = this.getPathByGeocodigo(geocodigo);
		const popover = this.getPopoverByGeocodigo(geocodigo);

		if (popover) {
			popover.popoverContext = this.getPopoverContext(path);
			this.selecionarMunicipio(path, popover);
		}
	}

	onPopoverHidden() {
		this.clearColors();

		setTimeout(() => {
			const openPopover = this.getOpenPopover();
			this.isHovered = !!openPopover;
		}, 100);
	}

	localizarMunicipio(geocodigo: number, geocodigoAnterior?: number) {
		if (geocodigoAnterior) {
			const path = this.getPathByGeocodigo(geocodigoAnterior);
			this.clearColor(path, true);
		}

		const path = this.getPathByGeocodigo(geocodigo);
		const popover = this.getPopoverByGeocodigo(geocodigo);

		if (popover) {
			popover.popoverContext = this.getPopoverContext(path);
			popover.show();
		}

		this.changeColor(path, this.onClickColor, 'localizacao');
	}

	removerLocalizacaoMunicipio(geocodigo: number) {
		const path = this.getPathByGeocodigo(geocodigo);
		const popover = this.getPopoverByGeocodigo(geocodigo);

		popover?.hide();
		path.removeAttribute('data-localizacao');

		if (path.hasAttribute('data-permanente')) {
			this.changeColor(path, this.color, 'permanente');
		} else {
			this.clearColor(path);
		}
	}

	colorirMunicipios(geocodigos: number[], tipo: TipoCor = 'normal') {
		this.clearColors();

		for (const geocodigo of geocodigos) {
			const element = this.getPathByGeocodigo(geocodigo);
			this.changeColor(element, this.color, tipo);
		}
		this.loadingButton = false;
		this.loadingButtonChange.emit(this.loadingButton);
	}

	obterHrefTipoEventoPorMesorregiao(mesorregiao: string) {
		const eventoSelecionado = this.eventosMesorregiao?.find(
			e => e.nomeMesorregiao === mesorregiao
		);

		const tipoEvento = eventoSelecionado?.tipoEvento;
		const deslocamento = eventoSelecionado?.deslocamento;
		const horario = eventoSelecionado?.horario;
		const hora = new Date(horario!).getHours();
		let periodo = '';
		if ((hora === 0 && deslocamento === 6) || hora >= 18) {
			periodo = '_NOITE';
		}

		return `assets/images/previsao/${tipoEvento}${periodo}.svg`;
	}

	resetParamsDataPermanente() {
		document.querySelectorAll(`[selecionado="true"]`).forEach(e => {
			if (e.getAttribute('data-permanente')) {
				e.setAttribute('fill', this.color);
			}
			e.setAttribute('selecionado', 'false');
		});
	}

	private changeColor(
		target: SVGPathElement,
		color: string,
		tipo: TipoCor = 'normal'
	) {
		const corPermanente = target.getAttribute('data-permanente');
		const corLocalizacao = target.getAttribute('data-localizacao');
		if (tipo === 'selecionado' && color !== 'none') {
			this.resetParamsDataPermanente();

			target.setAttribute(tipo, 'true');
			target.setAttribute('fill', color);
		}

		if (tipo === 'normal' && (corPermanente || corLocalizacao)) return;

		if (color !== 'none') target.setAttribute('fill', color);
		if (tipo === 'permanente') target.setAttribute('data-permanente', 'true');
		if (tipo === 'localizacao') target.setAttribute('data-localizacao', 'true');
	}

	private clearColor(target: SVGPathElement, clearAnyway = false) {
		const openPopover = this.getOpenPopover();
		const isTargetTheOpenPopover =
			target.attributes.getNamedItem('aria-describedby')?.value ===
			'ngx-popover-' + openPopover?.popoverId;
		const corPermanente = target.getAttribute('data-permanente');
		const corLocalizacao = target.getAttribute('data-localizacao');

		if (corPermanente && corLocalizacao && clearAnyway)
			target.setAttribute('fill', this.color);

		if (
			isTargetTheOpenPopover ||
			!this.color ||
			(corPermanente && !clearAnyway) ||
			(corLocalizacao && !clearAnyway) ||
			(corPermanente && corLocalizacao)
		)
			return;

		target.setAttribute('fill', 'none');
	}

	private getPopoverContext(path: SVGPathElement): PopoverContext {
		const color = path.getAttribute('fill');
		const geocodigo = path.getAttribute('id')?.replace(this.id + '_', '');
		const nome = path.getAttribute('data-nome');
		const dadosComMesmoGeocodigo: ValorPorGeocodigo[] = [];
		const dadosDoGeocodigo = this.atributosMunicipio?.find(
			el => el.geocodigo.toString() == geocodigo
		);
		if (geocodigo) {
			this.atributosMunicipio?.forEach((el: ValorPorGeocodigo) => {
				if (el.geocodigo.toString() === geocodigo) {
					dadosComMesmoGeocodigo.push(el as any);
				}
			});
		}
		return {
			color,
			geocodigo,
			nome,
			dadosComMesmoGeocodigo,
			data: dadosDoGeocodigo?.data,
			valor: isNotNuloOuUndefined(dadosDoGeocodigo)
				? dadosDoGeocodigo.valor
				: null,
		};
	}

	private getTooltipByGeocodigo(geocodigo: number) {
		const element = this.getTooltipElementByGeocodigo(geocodigo);
		const tooltipId = element.getAttribute('data-tooltipId');

		return this.tooltips.find(tooltip => tooltip.tooltipId === +tooltipId);
	}

	private getPopoverByGeocodigo(geocodigo: number) {
		const path = this.getPathByGeocodigo(geocodigo);
		const popoverId = path.getAttribute('data-popoverId');

		return this.popovers.find(popover => popover.popoverId === +popoverId);
	}

	private togglePopover(target: SVGPathElement, popover: PopoverDirective) {
		const openPopover = this.getOpenPopover();

		if (openPopover) {
			openPopover.hide();
		}

		popover.toggle();
		this.clearColors();
		this.changeColor(target, this.onClickColor, 'selecionado');
	}

	private setColors() {
		if (!this.paths?.length) return;
		this.legenda.forEach(item => (item.index = 0));
		this.clearColors(true);
		this.atributosMunicipio?.forEach(attr => {
			const element = this.getPathByGeocodigo(attr.geocodigo);
			this.changeColor(element, this.handleColor(attr.valor), 'permanente');
		});

		if (this.microrregiao) {
			let aux = [...this.municipioPaths];

			this.atributosMunicipio?.forEach(atributo => {
				aux = aux.filter(
					municipio => Number(municipio.geocodigo) !== atributo.geocodigo
				);
			});

			aux.forEach(attr => {
				const color = coresQuantis[5];
				this.changeColor(
					this.getPathByGeocodigo(Number(attr.geocodigo)),
					color,
					'permanente'
				);

				const legendaItem = this.legenda.find(item => item.color === color);
				if (legendaItem) {
					legendaItem.index++;
				}
			});
		}

		if (this.asImage && !this.imagemSrc) {
			const svg = document.getElementById(this.id.toString());
			if (svg) {
				const svgString = new XMLSerializer().serializeToString(svg);
				const blob = new Blob([svgString], {
					type: 'image/svg+xml;charset=utf-8',
				});
				this.imagemSrc = URL.createObjectURL(blob);
			}
		}
		this.legendaItems.emit(this.legenda);
	}

	private handleColor(valor: number): string {
		let color = '';

		if (valor <= 0) {
			color = legendaInfoBoletimDiario[0] || '';
		} else if (this.microrregiao) {
			const rangeEspecifico = microrregioesComRanges.find(
				microrregiao => microrregiao.microrregiao === this.microrregiao
			);

			const microrregioes = rangeEspecifico
				? rangeEspecifico
				: microrregioesComRanges.find(micro => micro.microrregiao);

			if (microrregioes) {
				const matchingRange = microrregioes.ranges
					.sort((a, b) => a - b)
					.find(range => valor < range);

				if (matchingRange !== undefined) {
					const index = microrregioes.ranges.indexOf(matchingRange);
					color = coresQuantis[index];

					const legendaItem = this.legenda.find(item => item.color === color);
					if (legendaItem) {
						legendaItem.index++;
						this.legendaItems.emit(this.legenda);
					}
				}
			}
		} else {
			if (this.customSetColorByValue) {
				return this.customSetColorByValue(valor);
			}
			const minorPrecipitacaoColorKey = Object.keys(legendaInfoBoletimDiario)
				.map(Number)
				.filter(key => valor < key)[0];

			color = legendaInfoBoletimDiario[minorPrecipitacaoColorKey] || '';
		}

		return color;
	}

	clearColors(clearAnyway = false) {
		this.resetParamsDataPermanente();
		this.paths.forEach(path => {
			const corPermanente = path.nativeElement.getAttribute('data-permanente');
			const corLocalizacao =
				path.nativeElement.getAttribute('data-localizacao');

			if (
				(corPermanente && !clearAnyway) ||
				(corLocalizacao && !clearAnyway) ||
				(corPermanente && corLocalizacao)
			)
				return;

			this.renderer.setAttribute(path.nativeElement, 'fill', 'none');
		});
	}

	private getOpenPopover() {
		return this.popovers.find(popover => popover.isOpen);
	}

	private getOpenTooltip() {
		return this.tooltips.find(tooltip => tooltip.isOpen);
	}

	private getPathByGeocodigo(geocodigo: number) {
		return this.renderer.selectRootElement(
			`path[id="${this.id}_${geocodigo}"]`,
			true
		);
	}

	private getTooltipElementByGeocodigo(geocodigo: number) {
		return this.renderer.selectRootElement(
			`g[id="${this.id}_${geocodigo}"]`,
			true
		);
	}

	onMouseEnterMap() {
		if (this.getOpenPopover()) return;

		this.isHovered = true;
	}

	onMouseLeaveMap() {
		if (this.getOpenPopover()) return;

		this.isHovered = false;
	}

	@HostListener('document:mousedown', ['$event'])
	onGlobalClick(event: any): void {
		const opcoesMapa = document.querySelector('#opcoes-mapa');

		const isOutsideOfMap = !this.mapa?.nativeElement.contains(event.target);
		const isOutsideOfOpcoesMapa = !opcoesMapa?.contains(event.target);

		if (isOutsideOfMap && isOutsideOfOpcoesMapa) {
			this.clearMunicipioSelecionado();
		}
	}

	private clearMunicipioSelecionado() {
		this.geocodigoMunicipioSelecionado = undefined;
		this.geocodigoMunicipioSelecionadoChange.emit(
			this.geocodigoMunicipioSelecionado
		);
	}

	calculateMicrorregiaoViewBox(distancia = 15000) {
		if (this.microrregiao === this.ESTADO_COMPLETO) {
			return '62538 55734 344530 194658';
		}
		let minX = Infinity;
		let minY = Infinity;
		let maxX = -Infinity;
		let maxY = -Infinity;

		this.municipioPaths.forEach(path => {
			const [x1, y1, x2, y2] = this.getMinMaxCoordinates(path.d.split(' ')[1]);
			minX = Math.min(minX, x1);
			minY = Math.min(minY, y1);
			maxX = Math.max(maxX, x2);
			maxY = Math.max(maxY, y2);
		});

		// Adiciona a distância a cada lado
		minX -= distancia;
		minY -= distancia;
		maxX += distancia;
		maxY += distancia / 2;

		const width = (maxX - minX) * 1.1;
		const height = (maxY - minY) * 1.1;

		return `${minX} ${minY} ${width} ${height}`;
	}

	getMinMaxCoordinates(coordinates: string): number[] {
		const coords = coordinates.split(',').map(Number);
		const xValues = coords.filter((_, index) => index % 2 === 0);
		const yValues = coords.filter((_, index) => index % 2 !== 0);
		const minX = Math.min(...xValues);
		const minY = Math.min(...yValues);
		const maxX = Math.max(...xValues);
		const maxY = Math.max(...yValues);

		return [minX, minY, maxX, maxY];
	}
}
