import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { getBase64ImageFromURL } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import * as pdfseira from '@utils/pdf-seira';
import { startOfMonth, subYears } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subscription } from 'rxjs';

export const PERIODO_MINIMO_SPI_EM_ANOS = 10;
export const PERIODO_MAXIMO_SPI_EM_ANOS = 30;

@Component({
	selector: 'seira-pluviometro-grafico-spi',
	templateUrl: './pluviometro-grafico-spi.component.html',
	styleUrls: ['./pluviometro-grafico-spi.component.scss'],
})
export class PluviometroGraficoSpiComponent
	implements InstanciaRelatorio, OnInit, OnDestroy
{
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];

	private subscription = new Subscription();
	private inputs = inject(INPUTS_RELATORIOS);

	carregandoRelatorio = true;
	graficoSPIUrl?: string;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportarPDF(this.graficoSPIUrl);
			},
		},
	];
	descricaoRelatorio =
		'Define-se como índice de precipitação padronizado (SPI), a anomalia, em número de desvios padrão, que a pluviometria observada se afasta da climatologia, auxiliando na classificação de períodos secos ou úmidos em um determinado local ou região.';

	periodoTitulo = '';
	constructor(
		private readonly relatoriosService: RelatoriosService,
		private readonly toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		const inicioDefault = startOfMonth(
			subYears(new Date(), PERIODO_MINIMO_SPI_EM_ANOS)
		);
		const fimDefault = new Date();

		this.inputs.form.patchValue({
			dataInicio: inicioDefault,
			dataFim: fimDefault,
		});
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		this.carregandoRelatorio = true;
		this.inputs.setLoading(true);
		this.periodoTitulo = this.getPeriodo();
		this.relatoriosService.buscarGraficoSPI(dataInicio, dataFim).subscribe({
			next: grafico => {
				this.graficoSPIUrl = URL.createObjectURL(grafico);
			},
			error: () => {
				this.carregandoRelatorio = false;
				this.toastr.error('Ocorreu um erro ao gerar o relatório');
			},
			complete: () => {
				this.carregandoRelatorio = false;
				this.inputs.setLoading(false);
			},
		});
	}

	getPeriodo() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');
		return DateTimeUtils.formatarDataPeriodo(dataInicio, dataFim);
	}

	async exportarPDF(graficoSPIUrl?: string) {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Relatório - Índice padronizado de precipitação (SPI) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!graficoSPIUrl) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		} else {
			documentDefinition.content.push({
				image: await getBase64ImageFromURL(graficoSPIUrl),
				alignment: 'center',
				width: 500,
				height: 250,
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.subscription.unsubscribe();
	}

	private getFormItemValue(name: string) {
		return this.inputs.form.get(name)?.value;
	}
}
