<div class="d-flex flex-column mt-2 align-items-center">
	<label class="text-center text-tertiary fw-semibold w-100">
		Índice de anomalia de precipitação modificado (IAPM) - {{ periodoTitulo }}
	</label>
	<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />
	<div
		*ngIf="carregando; else mapas"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>
	<ng-template #mapas>
		<div
			id="mapa-legenda"
			style="position: relative"
			class="w-100 mt-4"
			*ngIf="interpolacaoIAPM">
			<div class="col-12 mb-3 mb-lg-0 p-0">
				<div class="p-0 mx-1">
					<seira-mapa-interpolacao
						[mapaId]="'iapm'"
						[mapaNome]="'Índice de anomalia de precipitação modificado'"
						[imageOpacity]="0.8"
						[interpolacao]="interpolacaoIAPM"
						[carregando]="carregando"
						[valores]="valoresIAPM"
						[zoomOnlyWithCtrl]="true">
					</seira-mapa-interpolacao>
				</div>
			</div>
		</div>
	</ng-template>

	<ng-container
		*ngIf="!carregando && (!interpolacaoPrecipitacao || !interpolacaoIAPM)">
		<seira-sem-dados></seira-sem-dados>
	</ng-container>
</div>
