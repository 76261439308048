import { Component, OnInit } from '@angular/core';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Chart } from 'highcharts';
import { handleGenerateQuantisDataHighchart } from '@home/submodulos/dados-meteorologicos/utils/graficoUtils';
import { obter_erro_request } from '@utils';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { QuantisMicrorregiaoService } from '@home/submodulos/dados-meteorologicos/services/quantis-microrregiao.service';
import * as moment from 'moment';
import { Regiao } from '@administracao/submodulos/ranges-quantis/interfaces/regiao';
import { RegiaoPluviometricaService } from '@home/submodulos/dados-meteorologicos/services/regiao-pluviometrica.service';
import { Meses } from '../../enum';
import {
	DadoGraficoQuantis,
	DadosGraficoQuantisMensal,
} from '../../interfaces';
import { Mensagens } from '@core/enums/mensagens';

@Component({
	selector: 'seira-quantis',
	templateUrl: './quantis.component.html',
	styleUrls: ['./quantis.component.scss'],
})
export class QuantisComponent implements OnInit {
	informacoesGerais: TextoInformativo = {
		label: Mensagens.INFORMACOES_GERAIS,
		texts: [
			{
				title: 'O que você vai encontrar nesta página',
				text:
					'Informações a respeito da classificação das chuvas' +
					' de acordo com a região pluviométrica, verificando se o período foi muito chuvoso, chuvoso, muito seco, seco ou normal. ',
			},
		],
	};

	formQuantis!: FormGroup;
	chart!: Chart;
	resultado?: DadosGraficoQuantisMensal;
	regioes: Regiao[] = [];
	carregandoAddUpdate = false;
	options: any[] = [];
	loading = false;

	constructor(
		private formBuilder: FormBuilder,
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService,
		private quantisMicrorregiaoService: QuantisMicrorregiaoService,
		private regiaoPluviometricaService: RegiaoPluviometricaService
	) {}

	ngOnInit(): void {
		const dataAtual = moment();

		this.buscarRegioesPluviometricas();

		this.formQuantis = this.formBuilder.group({
			periodo: [dataAtual, [Validators.required]],
			regiao: [1, [Validators.required]],
		});
		this.gerarQuantis();
	}

	buscarRegioesPluviometricas() {
		this.carregandoAddUpdate = true;
		this.regiaoPluviometricaService.listarRegioesPluviometricas().subscribe({
			next: resp => {
				this.regioes = resp;
				this.carregandoAddUpdate = false;
			},
			error: err => {
				const msg_erro = obter_erro_request(err);
				this.toastr.error(
					msg_erro,
					'Erro ao buscar as informações das regiões pluviométricas'
				);
				this.carregandoAddUpdate = false;
			},
		});
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}

	gerarQuantis() {
		this.loading = true;
		const ano = this.formQuantis.get('periodo')?.value!;
		const regiao = this.formQuantis.get('regiao')?.value;

		this.quantisMicrorregiaoService
			.handleFetchQuantisMensaisGraphByRegiaoId(regiao, ano.year())
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);

							return {
								titulo: `${Meses[q.mes! - 1]}`,
								categorias: newCategorias,
							};
						}
					);
					const tituloGeral = `Região pluviométrica: ${resp.regiao}  - ${resp.ano}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';
					this.resultado = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
					};
				},
				error: () => {
					this.resultado = undefined;
					this.loading = false;
					this.toastr.error(
						'Erro ao obter informações da região pluviométrica selecionada'
					);
				},
				complete: () => {
					this.loading = false;
				},
			});
	}
}
