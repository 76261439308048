import * as Highcharts from 'highcharts';

export const optionsChartObservadoDesvioClimatologia = (
	isPercentage: boolean
): Highcharts.Options => ({
	navigator: {
		enabled: false,
	},
	exporting: {
		enabled: true,
		buttons: {
			contextButton: {
				menuItems: [
					'viewFullscreen',
					'separator',
					'downloadPNG',
					'downloadJPEG',
				],
			},
		},
	},
	rangeSelector: {
		enabled: false,
	},
	chart: {
		type: 'column',
	},
	credits: {
		enabled: false,
	},
	xAxis: {
		categories: [],
	},
	yAxis: [
		{
			title: {
				text: 'Precipitação (mm)',
				style: {
					color: 'rgb(44, 175, 254)',
				},
			},
			labels: {
				format: '{value}',
				style: {
					color: 'rgb(44, 175, 254)',
				},
			},
			opposite: false,
		},
		{
			title: {
				text: 'Desvio',
				style: {
					color: 'rgb(84, 79, 197)',
				},
			},
			labels: {
				format: isPercentage ? '{value} %' : '{value} mm',
				style: {
					color: 'rgb(84, 79, 197)',
				},
			},
			opposite: true,
		},
	],
	tooltip: {
		shared: true,
		split: false,
		followPointer: true,
		useHTML: true,
	},
	plotOptions: {
		column: {
			borderWidth: 0,
		},
	},
	legend: {
		align: 'left',
		verticalAlign: 'top',
		enabled: true,
	},
	series: [],
});
