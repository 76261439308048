import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { Subscription } from 'rxjs';
import { Validators } from '@angular/forms';
import {
	DadosRelatorioPluviometricoDiario,
	PostosRelatorios,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { formatISO } from 'date-fns';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { capitalizeFirstLetter } from '@utils';
import { ToastrService } from 'ngx-toastr';
import { TipoTabelas } from '@home/submodulos/dados-meteorologicos/interfaces/relatorioEnum';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';
import { DateTimeUtils } from '@utils/datetime-util';

@Component({
	selector: 'seira-pluviometro-tabela-precipitacao-diaria',
	templateUrl: './pluviometro-tabela-precipitacao-diaria.component.html',
	styleUrls: ['./pluviometro-tabela-precipitacao-diaria.component.scss'],
})
export class PluviometroTabelaPrecipitacaoDiariaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como pluviometria diária, a quantidade de chuva acumulada em um determinado local para um período de 24 horas.';

	inputs = inject(INPUTS_RELATORIOS);
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	carregandoRelatorio = true;
	periodo = '';
	dadosTabelaRelatorio: Relatorios<DadosRelatorioPluviometricoDiario>[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT(this.dadosTabelaRelatorio);
			},
		},
	];
	private subscription = new Subscription();
	constructor(
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}
	capturaTipoTabelas(field: keyof typeof TipoTabelas) {
		return TipoTabelas[field]?.toUpperCase();
	}
	formataData(data: Date) {
		const formatoData = new Date(data);
		return formatoData.toLocaleDateString('pt-BR', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		});
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}
		const dataInicio = this.inputs.form.get('dataInicio')?.value;
		const dataFim = this.inputs.form.get('dataFim')?.value;
		if (!dataInicio || !dataFim || !this.postos.length) {
			return;
		}
		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		this.periodo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			'periodo'
		);
		const postosIds = this.postos.map(posto => posto.id);
		this.relatoriosService
			.buscarRelatorioPluviometricos(
				postosIds,
				formatISO(new Date(dataInicio)),
				formatISO(new Date(dataFim))
			)
			.subscribe({
				next: pluviometricos => {
					this.dadosTabelaRelatorio =
						this.converteFormatoRelatorioPluviometrico(pluviometricos);
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
			});
	}
	converteFormatoRelatorioPluviometrico(dadosOriginais: any) {
		const dadosTransformados: Relatorios<DadosRelatorioPluviometricoDiario>[] =
			[];

		dadosOriginais?.relatoriosDiarios.forEach((relatorio: any) => {
			const detalhesPosto = relatorio.detalhesPosto;

			for (let i = 0; i < relatorio.dadosMensais.length; i++) {
				const dadosMensais = relatorio.dadosMensais[i];

				if (dadosMensais) {
					const municipio = detalhesPosto.municipio;
					const posto = detalhesPosto.posto;
					const tipo = detalhesPosto.tipo;
					const periodo = new Date(dadosMensais.periodo);
					const mesAno = `${capitalizeFirstLetter(
						periodo.toLocaleString('pt-BR', {
							month: 'long',
						})
					)}/${periodo.getFullYear()}`;
					const mesAnoCompacto = this.transformarFormatoMesAno(mesAno);

					const municipioExistente = dadosTransformados.find(
						dados => dados.municipio === municipio
					);

					let data: Relatorios<DadosRelatorioPluviometricoDiario> = {
						municipio: municipio,
						data: [],
					};

					if (municipioExistente) {
						data = municipioExistente;
					}

					const dadosPosto = {
						posto: posto,
						mesAno: mesAnoCompacto,
						tipo: tipo,
						d1: null,
						d2: null,
						d3: null,
						d4: null,
						d5: null,
						d6: null,
						d7: null,
						d8: null,
						d9: null,
						d10: null,
						d11: null,
						d12: null,
						d13: null,
						d14: null,
						d15: null,
						d16: null,
						d17: null,
						d18: null,
						d19: null,
						d20: null,
						d21: null,
						d22: null,
						d23: null,
						d24: null,
						d25: null,
						d26: null,
						d27: null,
						d28: null,
						d29: null,
						d30: null,
						d31: null,
						total: dadosMensais.precipitacaoAcumulada,
					};

					data.data.push(dadosPosto);

					data.data.forEach(() => {
						dadosMensais.medicaoDiaria.forEach((md: any, index: any) => {
							if (md) {
								const seq = 'd' + (index + 1);

								data.data[data.data.length - 1][seq] = md.precipitacao;
							}
						});
					});

					if (!municipioExistente) {
						dadosTransformados.push(data);
					}
				}
			}
		});
		dadosTransformados.sort((a, b) => {
			if (a.municipio < b.municipio) return -1;
			if (a.municipio > b.municipio) return 1;
			return 0;
		});

		return dadosTransformados;
	}
	transformarFormatoMesAno(stringOriginal: string) {
		const mesesAbreviados: any = {
			janeiro: 'Jan',
			fevereiro: 'Fev',
			março: 'Mar',
			abril: 'Abr',
			maio: 'Mai',
			junho: 'Jun',
			julho: 'Jul',
			agosto: 'Ago',
			setembro: 'Set',
			outubro: 'Out',
			novembro: 'Nov',
			dezembro: 'Dez',
		};

		const partes = stringOriginal.split('/');

		const mesCompleto = partes[0].toLowerCase();
		const ano = partes[1];

		const mesAbreviado = mesesAbreviados[mesCompleto];

		if (mesAbreviado) {
			return `${mesAbreviado}/${ano}`;
		} else {
			return stringOriginal;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	getPeriodo() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');
		return DateTimeUtils.formatarDataPeriodo(dataInicio, dataFim, 'periodo');
	}

	async exportarPDF(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioPluviometricoDiario>[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		documentDefinition.content.push({
			text: `Relatório - Precipitação diária (mm) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaPluviometricoDiario.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			let i = 0;
			while (i < dadoRelatorio.data.length) {
				const tableData: (string | number)[][] = [];

				tableData.push([
					'Data',
					'01',
					'02',
					'03',
					'04',
					'05',
					'06',
					'07',
					'08',
					'09',
					'10',
					'11',
					'12',
					'13',
					'14',
					'15',
					'16',
					'17',
					'18',
					'19',
					'21',
					'22',
					'23',
					'24',
					'25',
					'26',
					'27',
					'28',
					'29',
					'30',
					'31',
					'Total',
				]);

				let j = i;
				while (dadoRelatorio.data[i]?.posto === dadoRelatorio.data[j]?.posto) {
					tableData.push([
						dadoRelatorio.data[j].mesAno,
						dadoRelatorio.data[j]['d1'] || '-',
						dadoRelatorio.data[j]['d2'] || '-',
						dadoRelatorio.data[j]['d3'] || '-',
						dadoRelatorio.data[j]['d4'] || '-',
						dadoRelatorio.data[j]['d5'] || '-',
						dadoRelatorio.data[j]['d6'] || '-',
						dadoRelatorio.data[j]['d7'] || '-',
						dadoRelatorio.data[j]['d8'] || '-',
						dadoRelatorio.data[j]['d9'] || '-',
						dadoRelatorio.data[j]['d10'] || '-',
						dadoRelatorio.data[j]['d11'] || '-',
						dadoRelatorio.data[j]['d12'] || '-',
						dadoRelatorio.data[j]['d13'] || '-',
						dadoRelatorio.data[j]['d14'] || '-',
						dadoRelatorio.data[j]['d15'] || '-',
						dadoRelatorio.data[j]['d16'] || '-',
						dadoRelatorio.data[j]['d17'] || '-',
						dadoRelatorio.data[j]['d18'] || '-',
						dadoRelatorio.data[j]['d19'] || '-',
						dadoRelatorio.data[j]['d21'] || '-',
						dadoRelatorio.data[j]['d22'] || '-',
						dadoRelatorio.data[j]['d23'] || '-',
						dadoRelatorio.data[j]['d24'] || '-',
						dadoRelatorio.data[j]['d25'] || '-',
						dadoRelatorio.data[j]['d26'] || '-',
						dadoRelatorio.data[j]['d27'] || '-',
						dadoRelatorio.data[j]['d28'] || '-',
						dadoRelatorio.data[j]['d29'] || '-',
						dadoRelatorio.data[j]['d30'] || '-',
						dadoRelatorio.data[j]['d31'] || '-',
						dadoRelatorio.data[j].total || '-',
					]);
					j++;
				}

				documentDefinition.content.push(
					{
						text: `${dadoRelatorio.municipio} - ${dadoRelatorio.data[i].posto}`,
						fontSize: 10,
						margin: [0, 10],
					},
					{
						table: {
							body: tableData.map(row =>
								row.map(cell => ({ text: cell, fontSize: 10 }))
							),
						},
					}
				);

				if (i < j) {
					i = j;
				} else {
					i++;
				}
			}
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioPluviometricoDiario>[]
	) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Mês/ano',
			'posto',
			'Tipo posto',
			'1',
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			'10',
			'11',
			'12',
			'13',
			'14',
			'15',
			'16',
			'17',
			'18',
			'19',
			'21',
			'22',
			'23',
			'24',
			'25',
			'26',
			'27',
			'28',
			'29',
			'30',
			'31',
			'Total',
		]);

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dadoRelatorio.municipio,
					dado.mesAno,
					dado.posto,
					dado.tipo,
					dado['d1'] || '-',
					dado['d2'] || '-',
					dado['d3'] || '-',
					dado['d4'] || '-',
					dado['d5'] || '-',
					dado['d6'] || '-',
					dado['d7'] || '-',
					dado['d8'] || '-',
					dado['d9'] || '-',
					dado['d10'] || '-',
					dado['d11'] || '-',
					dado['d12'] || '-',
					dado['d13'] || '-',
					dado['d14'] || '-',
					dado['d15'] || '-',
					dado['d16'] || '-',
					dado['d17'] || '-',
					dado['d18'] || '-',
					dado['d19'] || '-',
					dado['d21'] || '-',
					dado['d22'] || '-',
					dado['d23'] || '-',
					dado['d24'] || '-',
					dado['d25'] || '-',
					dado['d26'] || '-',
					dado['d27'] || '-',
					dado['d28'] || '-',
					dado['d29'] || '-',
					dado['d30'] || '-',
					dado['d31'] || '-',
					dado.total || '-',
				]);
			});
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-precipitacao-diaria-${this.getPeriodo()}`
		);
	}

	exportarTXT(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioPluviometricoDiario>[]
	) {
		let txtData = '';

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				txtData +=
					`Município/Posto: ${dadoRelatorio.municipio}/${dado.posto}\n` +
					`Mês/ano: ${dado.mesAno}\n` +
					`Tipo posto: ${dado.tipo}\n` +
					`1: ${dado['d1'] || '-'} \n` +
					`2: ${dado['d2'] || '-'} \n` +
					`3: ${dado['d3'] || '-'} \n` +
					`4: ${dado['d4'] || '-'} \n` +
					`5: ${dado['d5'] || '-'} \n` +
					`6: ${dado['d6'] || '-'} \n` +
					`7: ${dado['d7'] || '-'} \n` +
					`8: ${dado['d8'] || '-'} \n` +
					`9: ${dado['d9'] || '-'} \n` +
					`10: ${dado['d10'] || '-'} \n` +
					`11: ${dado['d11'] || '-'} \n` +
					`12: ${dado['d12'] || '-'} \n` +
					`13: ${dado['d13'] || '-'} \n` +
					`14: ${dado['d14'] || '-'} \n` +
					`15: ${dado['d15'] || '-'} \n` +
					`16: ${dado['d16'] || '-'} \n` +
					`17: ${dado['d17'] || '-'} \n` +
					`18: ${dado['d18'] || '-'} \n` +
					`19: ${dado['d19'] || '-'} \n` +
					`20: ${dado['d20'] || '-'} \n` +
					`21: ${dado['d21'] || '-'} \n` +
					`22: ${dado['d22'] || '-'} \n` +
					`23: ${dado['d23'] || '-'} \n` +
					`24: ${dado['d24'] || '-'} \n` +
					`25: ${dado['d25'] || '-'} \n` +
					`26: ${dado['d26'] || '-'} \n` +
					`27: ${dado['d27'] || '-'} \n` +
					`28: ${dado['d28'] || '-'} \n` +
					`29: ${dado['d29'] || '-'} \n` +
					`30: ${dado['d30'] || '-'} \n` +
					`31: ${dado['d31'] || '-'} \n` +
					`Total: ${dado.total || '-'} \n`;
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-precipitacao-diaria-${this.getPeriodo()}`
		);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();

		this.subscription.unsubscribe();
	}
}
