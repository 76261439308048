import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { CalendarEvent } from 'angular-calendar';
import { PeriodoBoletim } from '../../enum/PeriodoBoletim';
import { MedicaoHistorico } from '@modulos/meteorologia/submodulos/cartas-campo/types/carta-campo';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { Subscription } from 'rxjs';
import { DateTimeUtils } from '@utils/datetime-util';
import { FormularioRelatorio } from '@home/submodulos/dados-meteorologicos/interfaces';

@Component({
	selector: 'seira-modal-pdf-options',
	templateUrl: './modal-pdf-options.component.html',
	styleUrls: ['./modal-pdf-options.component.scss'],
})
export class ModalPdfOptionsComponent implements OnInit, OnDestroy {
	listaFiltro: Set<string> = new Set();
	protected readonly PeriodoBoletim = PeriodoBoletim;
	@Input() isModal = false;
	@Input() close!: (modal: boolean) => void;
	@Input() events!: CalendarEvent<MedicaoHistorico>[];
	@Input() date!: Date;
	loadingSubmit = false;
	form!: FormGroup;
	minDate: Date | null;
	maxDate: Date = new Date();
	subscriptions = new Subscription();
	tiposPosto: { label: TipoEstacao; value: keyof typeof TipoEstacao }[] = [];
	limiteSupAno = new Date();
	limiteInfAno: Date | null;
	dataBoletim: Date;
	@Output() afterSubmitOnModalFunc:
		| ((
				dataInicio: Date | null,
				ano: Date | null,
				limiteInferiorMes: number | null,
				limiteInferiorAno: number | null,
				periodo: PeriodoBoletim | null,
				tipoPosto: TipoEstacao | null
		  ) => void)
		| undefined;
	OpcoesTipoTabela: OptionRadio<PeriodoBoletim>[] = [
		{ label: 'Mensal', value: PeriodoBoletim.MENSAL },
		{ label: 'Anual', value: PeriodoBoletim.ANUAL },
		{ label: 'Ambos', value: PeriodoBoletim.AMBOS },
	];

	constructor(private fb: FormBuilder) {
		for (const tipoPostoKey in TipoEstacao) {
			const key = tipoPostoKey as keyof typeof TipoEstacao;
			if (TipoEstacao[key] !== TipoEstacao.FLUVIOMETRO) {
				let label: any = TipoEstacao[key];
				if (label === 'Pluviômetro convencional') {
					label = 'PCO';
				}
				this.tiposPosto.push({ value: label, label: label });
			}
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit() {
		this.form = this.fb.group({
			periodo: null,
			dataInicio: [null, [Validators.required]],
			ano: [null, [Validators.required]],
			limiteInferiorMes: null,
			limiteInferiorAno: null,
			tipoPosto: new FormControl<TipoEstacao | null>(null),
		});

		if (this.dataBoletim) {
			const anoBoletim = this.dataBoletim.getFullYear();
			const mesBoletim = this.dataBoletim.getMonth();

			this.form.get('ano')?.setValue(new Date(anoBoletim, 0, 1));
			this.form
				.get('dataInicio')
				?.setValue(new Date(anoBoletim, mesBoletim, 1));
		}

		this.mudancasAno();
		this.mudancasPeriodo();
		this.subscriptions.add(
			this.form.get(FormularioRelatorio.DATA_INICIO)?.valueChanges.subscribe({
				next: value => {
					if (!value) {
						this.limiteInfAno = null;
						this.limiteSupAno = new Date();
						return;
					}
					const ano = this.dataBoletim.getFullYear();
					this.limiteInfAno = DateTimeUtils.getDataNoInicioAno(ano);
					this.limiteSupAno = DateTimeUtils.getDataNoFimAno(ano);
				},
			})
		);
	}

	mudancasAno() {
		this.subscriptions.add(
			this.form.controls['ano'].valueChanges.subscribe({
				next: ano => {
					if (ano) {
						const anoSelecionado = new Date(ano).getUTCFullYear();
						const anoAtual = new Date().getUTCFullYear();
						this.minDate = new Date(anoSelecionado, 0, 1);
						this.maxDate =
							anoSelecionado < anoAtual
								? new Date(anoSelecionado, 11, 31)
								: new Date();
					}
				},
			})
		);
	}

	mudancasPeriodo() {
		this.subscriptions.add(
			this.form.controls['periodo'].valueChanges.subscribe({
				next: () => {
					this.minDate = null;
					this.maxDate = new Date();
				},
			})
		);
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	onSubmit() {
		if (this.afterSubmitOnModalFunc) {
			const dataInicio: Date | null = this.form.get('dataInicio')!.value;
			const ano: Date | null = this.form.get('ano')!.value;
			const limiteInferiorMes: number | null =
				this.form.get('limiteInferiorMes')!.value;
			const limiteInferiorAno: number | null =
				this.form.get('limiteInferiorAno')!.value;
			const periodo: PeriodoBoletim | null = this.form.get('periodo')!.value;
			const tipoPosto: TipoEstacao | null = this.form.get('tipoPosto')!.value;

			this.afterSubmitOnModalFunc(
				dataInicio,
				ano,
				limiteInferiorMes,
				limiteInferiorAno,
				periodo,
				tipoPosto
			);

			this.loadingSubmit = true;
		}
	}

	limparFiltros() {
		this.listaFiltro.clear();
		this.form.setValue({
			periodo: null,
			dataInicio: null,
			ano: null,
			limiteInferiorMes: null,
			limiteInferiorAno: null,
			tipoPosto: null,
		});
		this.ngOnInit();
	}

	isConfirmDisabled(): boolean {
		const periodo: PeriodoBoletim | null = this.form.get('periodo')!.value;
		const dataInicio: Date | null = this.form.get('dataInicio')!.value;
		const ano: Date | null = this.form.get('ano')!.value;

		if (
			periodo === PeriodoBoletim.MENSAL ||
			periodo === PeriodoBoletim.ANUAL ||
			periodo === PeriodoBoletim.AMBOS
		) {
			if (
				(periodo === PeriodoBoletim.MENSAL && !dataInicio) ||
				(periodo === PeriodoBoletim.ANUAL && !ano) ||
				(periodo === PeriodoBoletim.AMBOS && (!dataInicio || !ano))
			) {
				return true;
			}
		}

		return false;
	}
}
