import { endOfYear } from 'date-fns';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	inject,
	Injector,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { enumAsSelectOptions, obter_erro_request } from '@utils';
import {
	Formato,
	FormatoVariaveisMeteorologicas,
	TipoRelatorio,
	TipoVariaveisMeteorologicas,
} from '@home/submodulos/dados-meteorologicos/interfaces/relatorioEnum';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import moment, { Moment } from 'moment';
import { ComponentesRelatoriosService } from '@home/submodulos/dados-meteorologicos/services/componentes-relatorios.service';
import {
	ComponenteRelatorio,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';

import {
	Agrupamento,
	LabelVariavelComUnidade,
	ValuesVariaveis,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import {
	TiposMapasVariaveisMeteorologicas,
	TiposPeriodosVariaveisMeteorologicas,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { JanelaTempo } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/variaveis';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { OpcaoPagina } from '@componentes/public-page-options/public-page-options.component';
import { MapaInterpolacaoEstacoesPCDSComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-interpolacao-estacoes-pcds/mapa-interpolacao-estacoes-pcds.component';
import { TiposPeriodosValoresExtremos } from '@modulos/home/submodulos/dados-meteorologicos/interfaces/variaveis-meteologicas';
import { TipoPrecipitacao } from '@shared/enum';
import { Mensagens } from '@core/enums/mensagens';

@Component({
	selector: 'seira-estacoes-automaticas',
	templateUrl: './estacoes-automaticas.component.html',
	styleUrls: ['./estacoes-automaticas.component.scss'],
})
export class EstacoesAutomaticasComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
	dynamicComponentContainer!: ViewContainerRef;
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	form!: FormGroup;
	private listaRelatorios = inject(
		ComponentesRelatoriosService
	).getRelatorios();
	relatorioAtual?: ComponenteRelatorio;
	relatorioAnterior?: ComponenteRelatorio;
	instanceRelatorio?: InstanciaRelatorio;
	tipos: Select[] = [];
	tipoPeriodo: Select[] = [];
	tipoPeriodoValoresExtremos: Select[] = [];
	formatos: Select[] = [];
	variaveis: Select[] = [];
	estacoes: Estacao[] = [];
	loading = false;
	postos: PostosRelatorios[] = [];
	options: any[] = [];
	informacoesGerais: TextoInformativo = {
		label: Mensagens.INFORMACOES_GERAIS,
		texts: [
			{
				title: 'O que são variáveis meteorológicas?',
				text: 'São elementos da atmosfera passíveis de serem mensurados com equipamentos específicos. A medição sistemática (ao longo do tempo), das variáveis meteorológicas como temperatura, vento, chuva, pressão, radiação solar, umidade, etc. definem o comportamento do clima de uma determinada região.',
			},
			{
				title: 'O que você vai encontrar nesta página?',
				text: 'Aqui você pode acompanhar, a partir de dados coletados automaticamente, através de PCD, informações meteorológicas registradas em uma janela de tempo no mínimo horária, nas diversas estações do estado da Paraíba. As informações estão em forma de mapas, usando algoritmos de interpolação de dados, gráficos e tabelas.',
			},
		],
	};
	@ViewChild('mapa')
	mapaInterpolacao?: MapaInterpolacaoEstacoesPCDSComponent;
	OpcoesTipoVisualizacao: OptionRadio<'MAPA' | 'GRAFICO' | 'TABELA'>[] = [
		{ label: 'Mapa', value: 'MAPA' },
		{ label: 'Gráfico', value: 'GRAFICO' },
		{ label: 'Tabela', value: 'TABELA' },
	];

	botoesDeExportacao: GroupButton[] = [];
	tiposPeriodos = enumAsSelectOptions(TiposPeriodosVariaveisMeteorologicas);
	tiposDePeriodosValoresExtremos = enumAsSelectOptions(
		TiposPeriodosValoresExtremos
	);
	tiposMapas = enumAsSelectOptions(TiposMapasVariaveisMeteorologicas);

	variaveisMapa = [
		{
			label: LabelVariavelComUnidade.PRECIPITACAO_ACUMULADA,
			value: ValuesVariaveis.PRECIPITACAO,
		},
		{
			label: LabelVariavelComUnidade.TEMPERATURA_MEDIA_DO_AR,
			value: ValuesVariaveis.TEMPERATURA_DO_AR,
		},
	];

	precipitacoes: Select[] = Object.values(TipoPrecipitacao).map(value => ({
		value,
		name: value.charAt(0).toUpperCase() + value.slice(1),
	}));

	inputsRelatorioInjector!: Injector;
	janelasDeTempo: Select[] = [];
	buscaPersonalizada = false;
	disabled = false;
	opcoesDaPagina: OpcaoPagina<any>[] = [];
	optionsAgrupamento: Select[] = [];
	constructor(
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService,
		private cdr: ChangeDetectorRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private readonly modalService: BsModalService
	) {
		const hoje = new Date();
		const mesPassado = new Date();
		mesPassado.setDate(hoje.getDate() - 30);
		this.janelasDeTempo = enumAsSelectOptions(JanelaTempo);
		this.form = this.formBuilder.group({
			[FormularioRelatorio.TIPO]: new FormControl<
				keyof typeof TipoRelatorio | null
			>('EVAPOTRANSPIRACAO', Validators.required),
			[FormularioRelatorio.VARIAVEL]: new FormControl<string | null>(
				'precipitacao'
			),
			[FormularioRelatorio.MICRORREGIAO]: new FormControl<string | null>(null),
			[FormularioRelatorio.PRECIPITACAO]: new FormControl<string | null>(
				TipoPrecipitacao.ACUMULADA
			),
			[FormularioRelatorio.DATA_INICIO]: new FormControl<Moment>(
				moment(mesPassado)
			),
			[FormularioRelatorio.DATA_FIM]: new FormControl<Moment>(moment(hoje)),
			[FormularioRelatorio.POSTO]: new FormControl('todos_estados'),
			[FormularioRelatorio.ESTACAO]: new FormControl(),
			[FormularioRelatorio.PERIODO]: new FormControl<moment.Moment | null>(
				moment(hoje)
			),
			[FormularioRelatorio.LOADING_GERAR]: new FormControl<boolean>(false),
			[FormularioRelatorio.VARIAVEL_MAPA]: new FormControl<null | string>(
				ValuesVariaveis.PRECIPITACAO
			),
			[FormularioRelatorio.TIPO_PERIODO]: new FormControl<null | string>(
				'ANUAL'
			),
			[FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS]: new FormControl<
				null | string
			>('MENSAL_COMPLETO'),
			[FormularioRelatorio.TIPO_MAPA]: new FormControl<null | string>(
				'ISOLINHA'
			),
			[FormularioRelatorio.AGRUPAMENTO]: new FormControl('MUNICIPIO_POSTO'),
			tipoVisualizacao: new FormControl('MAPA'),
			janelaDeTempo: new FormControl(
				JanelaTempo['Últimas 6 horas'],
				Validators.required
			),
		});
		this.setInjector();
	}

	janelaTempoIsPersonalizada() {
		const campoControl = this.form.get('dataFim')!;
		campoControl.setValidators([Validators.required]);

		this.form.valueChanges.subscribe({
			next: value => {
				this.rangeDataValid();
				return value.janelaDeTempo === 'Personalizada'
					? (this.buscaPersonalizada = true)
					: (this.buscaPersonalizada = false);
			},
		});
	}
	rangeDataValid() {
		const dataFinal = this.form.get('dataFim')?.value;
		if (dataFinal === null) {
			this.disabled = true;
			return false;
		}
		this.disabled = false;
		return true;
	}
	setInjector() {
		this.inputsRelatorioInjector = Injector.create({
			providers: [
				{
					provide: INPUTS_RELATORIOS,
					useValue: {
						form: this.form,
						postos: this.postos,
						estacoes: this.estacoes,
						agrupamento: this.optionsAgrupamento,
						setLoading: this.setLoading,
					},
				},
			],
		});
	}

	ngOnInit(): void {
		this.tipos = enumAsSelectOptions(TipoRelatorio);
		this.formatos = enumAsSelectOptions(Formato);
		this.variaveis = enumAsSelectOptions(TipoVariaveisMeteorologicas);
		this.optionsAgrupamento = enumAsSelectOptions(Agrupamento);
		this.tiposDePeriodosValoresExtremos = enumAsSelectOptions(
			TiposPeriodosValoresExtremos
		);
		this.buscarEstacoes();
		this.observarFormulario();
		this.observarFormatos();
	}

	ngAfterViewInit() {
		this.relatorioAtual = this.listaRelatorios.EVAPOTRANSPIRACAO.TABELA;
		this.loadDynamicComponent(this.relatorioAtual.componente);
		this.cdr.detectChanges();
		this.janelaTempoIsPersonalizada();
	}

	get loadingForm() {
		return !!this.form.get('loadingGerar')!.value;
	}
	setLoading(state: boolean) {
		this.form.get('loadingGerar')?.setValue(state);
	}
	formato(formato: string[]) {
		return formato.includes(this.getFormItemValue(FormularioRelatorio.FORMATO));
	}
	tipoFormato(
		tipos: Array<keyof typeof TipoRelatorio> | null = null,
		formatos: Array<keyof typeof Formato> | null = null
	) {
		const formato = this.getFormItemValue('tipoVisualizacao');
		const tipo = this.getFormItemValue('tipo');
		return (
			this.condicaoTipoFormato(formatos, formato) &&
			this.condicaoTipoFormato(tipos, tipo)
		);
	}
	condicaoTipoFormato(array: string[] | null, valorNoForm: string) {
		if (array === null) {
			return true;
		}
		return array.includes(valorNoForm);
	}
	get mostrarSelectVariaveisGrafico() {
		return this.tipoFormato(null, ['GRAFICO']);
	}
	get mostrarSelectTipoMapa() {
		return this.tipoFormato(null, ['MAPA']);
	}
	get mostrarSelectTipo() {
		return this.tipoFormato(null, ['TABELA']);
	}
	get mostrarSelectTipoPeriodoValoresExtremos() {
		return this.tipoFormato(['VALORES_EXTREMOS'], ['TABELA']);
	}
	get mostrarDatePickerAnoCompleto() {
		return (
			this.mostrarSelectTipoPeriodoValoresExtremos &&
			this.getFormItemValue(
				FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS
			) === 'ANUAL_COMPLETO'
		);
	}
	get mostrarDatePickerAnoParcial() {
		return (
			this.mostrarSelectTipoPeriodoValoresExtremos &&
			this.getFormItemValue(
				FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS
			) === 'ANUAL_PARCIAL'
		);
	}
	get mostrarDatePickerMesCompleto() {
		return (
			this.mostrarSelectTipoPeriodoValoresExtremos &&
			this.getFormItemValue(
				FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS
			) === 'MENSAL_COMPLETO'
		);
	}
	get mostrarDatePickerMesParcial() {
		return (
			this.mostrarSelectTipoPeriodoValoresExtremos &&
			this.getFormItemValue(
				FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS
			) === 'MENSAL_PARCIAL'
		);
	}
	get mostrarDatePickerAno() {
		return (
			this.mostrarSelectsMapaHistorico &&
			this.getFormItemValue(FormularioRelatorio.TIPO_PERIODO) === 'ANUAL'
		);
	}
	get mostrarDateRangePicker() {
		return this.tipoFormato(
			['EVAPOTRANSPIRACAO', 'VARIAVEL_METEOROLOGICA'],
			['TABELA', 'GRAFICO']
		);
	}

	get mostrarSelectsMapa() {
		return (
			this.tipoFormato(null, ['MAPA']) &&
			this.getFormItemValue(FormularioRelatorio.TIPO_MAPA) === 'ISOLINHA'
		);
	}
	get mostrarSelectsMapaHistorico() {
		return (
			this.tipoFormato(null, ['MAPA']) &&
			this.getFormItemValue(FormularioRelatorio.TIPO_MAPA) === 'HISTORICO'
		);
	}
	get mostrarDateRangePickerMes() {
		return (
			this.mostrarSelectsMapaHistorico &&
			this.getFormItemValue(FormularioRelatorio.TIPO_PERIODO) === 'MENSAL'
		);
	}
	get mostrarSelectDePostos() {
		return (
			this.tipoFormato(['VARIAVEL_METEOROLOGICA'], ['TABELA']) ||
			this.tipoFormato(null, ['GRAFICO'])
		);
	}
	get mostrarAgrupamento() {
		return this.tipoFormato(['VALORES_EXTREMOS'], ['TABELA']);
	}
	get mostrarSelectDePostosValoresExtremos() {
		return (
			this.tipoFormato(['VALORES_EXTREMOS'], ['TABELA']) &&
			this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO) ===
				'MUNICIPIO_POSTO'
		);
	}

	observarFormatos() {
		this.form.get('tipoVisualizacao')?.valueChanges.subscribe(formato => {
			this.analiseFormato(formato);
		});
	}
	analiseFormato(formato: string) {
		switch (formato) {
			case 'GRAFICO' || 'MAPA' || 'TABELA':
				if (this.getFormItemValue('tipo') === 'VARIAVEL_METEOROLOGICA') {
					this.ajustarEstacoesSomentePCD();
					break;
				}
		}
	}

	ajustarEstacoesSomentePCD() {
		this.options = this.options.filter(o => o.tipo === 'PCD');
		this.options.unshift({ label: 'Estado completo', value: 0 });
	}

	gerarRelatorio() {
		this.instanceRelatorio?.gerarRelatorio();
	}

	loadDynamicComponent(component: any): void {
		if (this.relatorioAtual !== this.relatorioAnterior) {
			if (this.dynamicComponentContainer.get(0)) {
				this.dynamicComponentContainer.remove(0);
			}
			if (component) {
				const componentFactory =
					this.componentFactoryResolver.resolveComponentFactory(component);
				const componentRef = this.dynamicComponentContainer.createComponent(
					componentFactory,
					undefined,
					this.inputsRelatorioInjector
				);
				this.instanceRelatorio = componentRef.instance as InstanciaRelatorio;
				this.botoesDeExportacao =
					this.instanceRelatorio.botoesDeExportacao || [];
				if (
					!this.instanceRelatorio.postos?.length ||
					!this.instanceRelatorio.estacoes?.length
				) {
					this.instanceRelatorio.postos = this.postos;
					this.instanceRelatorio.estacoes = this.estacoes;

					this.instanceRelatorio.precipitacao = this.precipitacoes;
				}
			} else {
				this.instanceRelatorio = undefined;
			}
		}
		this.relatorioAnterior = this.relatorioAtual;
	}

	observarFormulario() {
		this.form.valueChanges.subscribe({
			next: ({
				tipo,
				tipoVisualizacao,
				tipoMapa,
			}: {
				tipo?: keyof typeof TipoRelatorio;
				tipoVisualizacao?:
					| keyof typeof Formato
					| keyof typeof FormatoVariaveisMeteorologicas;
				tipoMapa?: keyof typeof TiposMapasVariaveisMeteorologicas;
			}) => {
				if (tipoVisualizacao && tipoVisualizacao !== 'TABELA') {
					tipo = 'VARIAVEL_METEOROLOGICA';
				}
				if (tipo && this.listaRelatorios.hasOwnProperty(tipo)) {
					const tipos = this.listaRelatorios[tipo] as any;
					if (tipoVisualizacao && tipos.hasOwnProperty(tipoVisualizacao)) {
						const formatos = tipos[tipoVisualizacao];
						if (tipoMapa && formatos.hasOwnProperty(tipoMapa)) {
							this.relatorioAtual = formatos[tipoMapa];
						} else {
							this.relatorioAtual = formatos;
						}
					} else {
						this.relatorioAtual = tipos;
					}
				}
				this.loadDynamicComponent(this.relatorioAtual?.componente);
			},
		});
	}

	buscarEstacoes() {
		this.relatoriosService.consultarOpcoesFiltros().subscribe({
			next: resp => {
				resp.estacoes.push({
					id: 0,
					microrregiaoCodigo: '',
					nomeMunicipio: 'Todos os postos',
					statusEstacao: 'ATIVA',
					tiposMedicao: [],
					responsavel: '',
					mesorregiao: 0,
					longitude: 0,
					latitude: 0,
					nomePosto: 'Todos os postos',
					municipio: 0,
					tipoEstacao: 'PCD',
					microrregiao: 0,
					telefone: '',
				});
				this.estacoes = resp.estacoes.filter(
					e => e.tipoEstacao === TipoEstacao.PCD
				);
				this.form.patchValue({
					estacao: this.estacoes?.[0].id,
				});
				this.postos = resp.estacoes
					.filter(e => e.tipoEstacao == TipoEstacao.PCD)
					.map(estacao => ({
						id: estacao.id,
						municipio: estacao.nomeMunicipio,
						nomePosto: estacao.nomePosto,
						tipo: estacao.tipoEstacao,
						responsavel: estacao.responsavel ?? '-',
					}));

				if (this.instanceRelatorio) {
					this.instanceRelatorio.estacoes = this.estacoes;
					this.instanceRelatorio.postos = this.postos;
				}
				if (this.options.length < 1) {
					resp.estacoes
						.filter(e => e.tipoEstacao === TipoEstacao.PCD)
						.map(value => {
							if (value.statusEstacao === 'ATIVA') {
								this.options.push({
									label: `${value.nomePosto} - ${value.tipoEstacao}`,
									value: value.id,
									tipo: value.tipoEstacao,
								});
							}
						});
				}
				this.setInjector();
			},
			error: err => {
				const msg_erro = obter_erro_request(err);
				this.toastr.error(
					msg_erro,
					'Erro ao buscar as informações das estações'
				);
			},
		});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}
	get calcularDataInicio() {
		const dataInicio = new Date(this.getFormItemValue('dataInicio'));
		return dataInicio;
	}
	get calcularDataFim() {
		if (this.getFormItemValue(FormularioRelatorio.TIPO_PERIODO) !== 'MENSAL') {
			const dataInicio = new Date(this.getFormItemValue('dataInicio'));
			const dataAtual = new Date();

			const diferencaRangeInicioAtual = Math.abs(
				(dataAtual.getFullYear() - dataInicio.getFullYear()) * 12 +
					dataAtual.getMonth() -
					dataInicio.getMonth()
			);

			if (diferencaRangeInicioAtual <= 6) {
				const newDataInicio = new Date(dataInicio);
				newDataInicio.setMonth(
					newDataInicio.getMonth() + diferencaRangeInicioAtual
				);
				return newDataInicio;
			}

			const dataFim = new Date(this.getFormItemValue('dataFim'));

			if (dataFim) {
				const diferencaRangeInicioFim = Math.abs(
					(dataFim.getFullYear() - dataInicio.getFullYear()) * 12 +
						dataFim.getMonth() -
						dataInicio.getMonth()
				);

				if (diferencaRangeInicioFim > 6) {
					this.form.get('dataFim')?.setValue(null);
					const newDataInicio = new Date(dataInicio);
					newDataInicio.setMonth(newDataInicio.getMonth() + 6);
					return newDataInicio;
				}

				if (
					diferencaRangeInicioFim === 0 &&
					dataInicio.getMonth() > dataFim.getMonth()
				) {
					this.form.get('dataFim')?.setValue(null);
				}
			}

			const newDataInicio = new Date(dataInicio);
			newDataInicio.setMonth(newDataInicio.getMonth() + 6);
			return newDataInicio;
		}
		return new Date();
	}

	get calcularDataInicioValoresExtremos() {
		const dataInicio = new Date(this.getFormItemValue('dataInicio'));
		return dataInicio;
	}

	get calcularDataFimValoresExtremos() {
		const dataInicio = new Date(this.getFormItemValue('dataInicio'));
		if (
			this.getFormItemValue(
				FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS
			) === 'ANUAL_PARCIAL'
		) {
			const newDataFim = endOfYear(dataInicio);
			return newDataFim;
		}
		return dataInicio;
	}
	protected readonly FormularioRelatorio = FormularioRelatorio;

	ngOnDestroy(): void {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.VARIAVEL)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}
}
