import { Component, Input } from '@angular/core';
import { isNotNuloOuUndefined } from '@utils';
import { Posto } from '@home/submodulos/dados-meteorologicos/interfaces/boletim';

@Component({
	selector: 'seira-popup-boletim-diario-mapa',
	templateUrl: './popup-boletim-diario-mapa.component.html',
	styleUrls: ['./popup-boletim-diario-mapa.component.scss'],
})
export class PopupBoletimDiarioMapaComponent {
	@Input() headerColor!: string | null;
	@Input() nome!: string;
	@Input() precipitacaoAcumulada?: string | null = null;
	@Input() postos: Posto[] = [];
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
}
