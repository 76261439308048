<label class="text-center text-tertiary fw-semibold w-100" id="mapa-legenda">
	Mosaico de chuvas - {{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />

<div
	*ngIf="carregando; else mapa"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>

<ng-template #mapa>
	<div class="mt-2" *ngIf="resultados.length; else semDados">
		<div class="row mt-2" *ngIf="funcaoCoresMapa">
			<div
				*ngFor="let resultado of resultados; let i = index"
				class="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
				<seira-imagem-historico-relatorio
					[idMapa]="'mapa-' + i"
					[funcaoCoresMapa]="funcaoCoresMapa"
					[legenda]="legenda"
					[imagem]="resultado"></seira-imagem-historico-relatorio>
			</div>
		</div>
		<seira-legenda-relatorio-historico-variaveis
			id="legenda"
			[options]="legenda"
			style="width: 100%" />
	</div>
</ng-template>

<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
