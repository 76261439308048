<div>
	<div class="leaft-control-container" style="background-color: lightgray">
		<div
			class="mapa-interpolacao"
			[ngStyle]="{ opacity: carregando ? 0.5 : 1 }"
			[id]="mapaId"
			(wheel)="ativarMomentaneamenteMensagem()"
			(mouseleave)="mouseEmCimaDoMapa = false"
			#mapaDiv>
			<div *seiraIfViewportSize="'desktop'">
				<div *ngIf="mapaId === 'mapa-interpolacao'" class="legenda-pcd">
					<seira-monitoramento-legenda-pcd> </seira-monitoramento-legenda-pcd>
				</div>
				<div *ngIf="mapaId === 'desvio'"></div>
				<div class="buttons" *ngIf="exportButtons.length > 0">
					<seira-public-button-group
						[buttons]="exportButtons"
						mode="dropdown"
						dropdownContainer="#map-container"></seira-public-button-group>
				</div>
			</div>
			<div *ngIf="carregando" class="loading-overlay">
				<seira-loading></seira-loading>
			</div>
			<div
				*ngIf="!ctrlAtivo && zoomOnlyWithCtrl && mouseEmCimaDoMapa"
				class="fullscreen-message">
				Pressione ctrl + scroll para dar zoom
			</div>
			<ng-content />
		</div>
	</div>
	<seira-monitoramento-legenda-horizontal
		[mapaNome]="mapaNome"
		[valores]="interpolacao ? interpolacao.legenda : valores"
		[carregando]="carregando">
	</seira-monitoramento-legenda-horizontal>
</div>
