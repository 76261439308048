import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import {
	capitalizeFirstLetter,
	criarImagemBase64FromChart,
	filterSelectOptions,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO } from 'date-fns';
import { Chart } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Subject, takeUntil } from 'rxjs';
import {
	FormularioRelatorio,
	IapmDTO,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '../../../interfaces';
import {
	GraficosPrecipitacaoService,
	RelatoriosService,
} from '../../../services';
import { Agrupamento } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';
import { gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import moment from 'moment';

export const PERIODO_MINIMO_IAPM_EM_ANOS = 2;
export const PERIODO_MAXIMO_IAPM_EM_ANOS = 10;

export const PERIODO_MINIMO_IAPM_EM_MESES = 2;
export const PERIODO_MAXIMO_IAPM_EM_MESES = 6;
@Component({
	selector: 'seira-pluviometria-grafico-iapm',
	templateUrl: './pluviometria-grafico-iapm.component.html',
	styleUrls: ['./pluviometria-grafico-iapm.component.scss'],
})
export class PluviometriaGraficoIapmComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	isLoading = true;
	chart!: Chart;
	dados?: IapmDTO[];

	descricaoRelatorio =
		'Define-se como IAPM, Índice de Anomalia de Precipitação Modificado, a anomalia da pluviometria mensal em comparação com a média histórica, utilizado para identificar padrões fora do comum em determinado local ou região.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
	];
	fileName = '';
	chartTitle = '';
	periodoTitulo = '';
	agrupamento: Select<string>[];
	private _destroyed = new Subject();
	relatorioService = inject(RelatoriosService);

	constructor(
		private readonly service: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.MUNICIPIO_POSTO,
			Agrupamento.MUNICIPIO,
			Agrupamento.SUBBACIA
		);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setDatasByPeriodoBusca(
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}

	gerarRelatorio() {
		if (this.inputs.form?.invalid) {
			return;
		}

		const agrupamento = this.inputs.form?.get('agrupamento')?.value;
		const periodoBusca = this.inputs.form?.get('periodoBusca')?.value;
		const dataInicio = this.inputs.form?.get('dataInicio')?.value;
		const dataFim = this.inputs.form?.get('dataFim')?.value;

		let id = 0;

		switch (agrupamento) {
			case 'MICRORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MICRORREGIAO)?.value;
				break;
			case 'MESORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MESORREGIAO)?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.getDataFromForm(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.value;
				break;
			case 'BACIA':
				id = this.getDataFromForm(FormularioRelatorio.BACIA)?.value;
				break;
		}

		if (id == 0 || !agrupamento || !periodoBusca || !dataInicio || !dataFim) {
			return;
		}

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		);
		this.handleFetchIAPM(
			id,
			agrupamento,
			this.handleFormatPeriodoBusca(periodoBusca),
			formatISO(new Date(dataInicio)),
			formatISO(new Date(dataFim))
		);
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (!this.chart || !this.dados) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: `Relatório - Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	setDatasByPeriodoBusca(periodoBuscaValue: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();

			switch (periodoBuscaValue) {
				case 'anual':
					dataFim.setValue(moment(hoje).subtract(1, 'year').endOf('year'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_ANOS, 'year')
							.startOf('year')
					);
					break;
				case 'mensal':
					dataFim.setValue(moment(hoje).subtract(1, 'month').startOf('month'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_MESES, 'month')
							.endOf('month')
					);
					break;
			}
		}
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MESORREGIAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		);

		switch (agrupamentoValue) {
			case 'BACIA':
				this.inputs.form
					?.get(FormularioRelatorio.BACIA)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MESORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MESORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					?.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: (periodo: PeriodosLabel | null) => {
					this.setDatasByPeriodoBusca(periodo);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	private handleFetchIAPM(
		id: number,
		agrupamento: string,
		periodo: string,
		dataInicial: string,
		dataFinal: string
	) {
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.service
			.gerarGraficoIAPM(id, agrupamento, periodo, dataInicial, dataFinal)
			.subscribe({
				next: values => {
					this.dados =
						periodo === 'MENSAL'
							? values.map(value => ({
									...value,
									periodo: value.periodo.padStart(7, '0'),
							  }))
							: values;
					this.fileName = gerarFilenameGrafico(
						`grafico_iapm_${this.inputs.form
							.get(FormularioRelatorio.AGRUPAMENTO)
							?.value.toLocaleLowerCase()}_${periodo.toLocaleLowerCase()}`
					);
					this.chartTitle = `IAPM - ${capitalizeFirstLetter(
						values[0].nome
					)} - ${DateTimeUtils.formatarDataPeriodo(
						dataInicial,
						dataFinal,
						periodo
					)}`;
				},
				error: err => {
					this.toastr.error('Erro ao buscar as informações');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	private getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form?.get(data);
	}

	private handleFormatPeriodoBusca(periodo: string) {
		if (periodo === 'anual') return 'ANUAL';
		if (periodo === 'mensal') return 'MENSAL';
		return 'PERIODO';
	}

	private handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}
}
