import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	DadoEstatisticaAnual,
	DadoPrecipitacaoAnual,
} from '@home/submodulos/dados-meteorologicos/componentes/tabela-relatorio-precipitacao-anual/tabela-relatorio-precipitacao-anual.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	DadoPrecipitacao,
	EstatisticasPrecipitacao,
	PostosRelatorios,
	RelatorioAnual,
	Relatorios,
	TipoEstatistica,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import {
	isNotNuloOuUndefined,
	numberToBrNumber,
	obter_erro_request,
} from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';

@Component({
	selector: 'seira-pluviometro-tabela-precipitacao-anual',
	templateUrl: './pluviometro-tabela-precipitacao-anual.component.html',
	styleUrls: ['./pluviometro-tabela-precipitacao-anual.component.scss'],
})
export class PluviometroTabelaPrecipitacaoAnualComponent
	implements OnInit, InstanciaRelatorio, OnDestroy
{
	descricaoRelatorio =
		'Define-se como precipitação anual, o total pluviométrico acumulado no período de um ano.';

	postos!: PostosRelatorios[];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];

	precipitacaoAnual: Relatorios<DadoPrecipitacaoAnual>[] = [];
	estatisticas: Relatorios<DadoEstatisticaAnual>[] = [];

	inputs = inject(INPUTS_RELATORIOS);
	carregando = true;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];
	periodoTitulo = '';

	constructor(
		private readonly relatoriosService: RelatoriosService,
		private readonly toastr: ToastrService
	) {
		this.postos = this.inputs.postos;
		this.setValidators();
	}
	ngOnInit(): void {
		throw new Error('Method not implemented.');
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	private getPrecipitacaoAcumulada(
		dadoPrecipitacao: DadoPrecipitacao,
		index: number
	) {
		return dadoPrecipitacao.precipitacoesMensais?.[index]
			?.precipitacaoAcumulada;
	}

	private processarDadoEstatistica(
		estatisticas: EstatisticasPrecipitacao,
		tipoEstatistica: TipoEstatistica
	) {
		const labelEstatisticaMap: Record<string, string> = {
			anosContabilizados: 'Quantidade de anos',
			mediasMensais: 'Média (mm)',
			maximaMensal: 'Máxima (mm)',
			minimaMensal: 'Mínima (mm)',
		};

		return {
			estatistica: labelEstatisticaMap[tipoEstatistica],
			jan: estatisticas[tipoEstatistica][0],
			fev: estatisticas[tipoEstatistica][1],
			mar: estatisticas[tipoEstatistica][2],
			abr: estatisticas[tipoEstatistica][3],
			mai: estatisticas[tipoEstatistica][4],
			jun: estatisticas[tipoEstatistica][5],
			jul: estatisticas[tipoEstatistica][6],
			ago: estatisticas[tipoEstatistica][7],
			set: estatisticas[tipoEstatistica][8],
			out: estatisticas[tipoEstatistica][9],
			nov: estatisticas[tipoEstatistica][10],
			dez: estatisticas[tipoEstatistica][11],
		};
	}

	private processarDadoPrecipitacao(dadoPrecipitacao: DadoPrecipitacao) {
		return {
			ano: new Date(dadoPrecipitacao.ano).getFullYear(),
			jan: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 0),
			fev: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 1),
			mar: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 2),
			abr: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 3),
			mai: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 4),
			jun: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 5),
			jul: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 6),
			ago: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 7),
			set: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 8),
			out: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 9),
			nov: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 10),
			dez: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 11),
			total: dadoPrecipitacao.precipitacaoAcumuladaAno,
		};
	}

	private processarDadosEstatistica(
		estatisticasPrecipitacao: EstatisticasPrecipitacao
	) {
		return Object.keys(estatisticasPrecipitacao).map(tipoEstatistica =>
			this.processarDadoEstatistica(
				estatisticasPrecipitacao,
				tipoEstatistica as TipoEstatistica
			)
		);
	}

	private popularDadosTabela(relatorio: RelatorioAnual) {
		const municipio = relatorio.detalhesPosto.municipio;
		const nomePosto = relatorio.detalhesPosto.posto;
		const tipoPosto = TipoEstacao[relatorio.detalhesPosto.tipo];
		const labelMunicipio = `${municipio} - ${nomePosto} (${tipoPosto})`;

		relatorio.dadosPrecipitacao.forEach(dadoPrecipitacao => {
			const dadoPrecipitacaoAnual = this.precipitacaoAnual.find(
				dado => dado.municipio === labelMunicipio
			);
			const dadoEstatistica = this.estatisticas.find(
				dado => dado.municipio === labelMunicipio
			);
			const dadoPrecipitacaoAnualProcessado =
				this.processarDadoPrecipitacao(dadoPrecipitacao);
			const dadoEstatisticasProcessado = this.processarDadosEstatistica(
				relatorio.estatisticasPrecipitacao
			);

			if (dadoPrecipitacaoAnual && dadoEstatistica) {
				dadoPrecipitacaoAnual.data.push(dadoPrecipitacaoAnualProcessado);
				dadoEstatistica.data = dadoEstatisticasProcessado;
			} else {
				this.precipitacaoAnual.push({
					municipio: labelMunicipio,
					data: [dadoPrecipitacaoAnualProcessado],
				});
				this.estatisticas.push({
					municipio: labelMunicipio,
					data: [...dadoEstatisticasProcessado],
				});
			}
		});
	}

	gerarRelatorio() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			this.inputs.form.get('dataInicio')?.value,
			this.inputs.form.get('dataFim')?.value
		);
		this.carregando = true;
		this.inputs.setLoading(true);
		const postosIds = this.postos.map(posto => posto.id);

		this.relatoriosService
			.buscarRelatorioPrecipitacaoAnual(postosIds, dataInicio, dataFim)
			.subscribe({
				next: response => {
					this.precipitacaoAnual = [];
					this.estatisticas = [];
					response.relatoriosAnuais.forEach(relatorio =>
						this.popularDadosTabela(relatorio)
					);
				},
				error: error => {
					this.carregando = false;
					const mensagem = obter_erro_request(error);
					this.toastr.error(mensagem, 'Ocorreu um erro ao gerar o relatório');
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Relatório - Precipitação anual (mm) - ${DateTimeUtils.formatarDataPeriodo(
				this.getFormItemValue('dataInicio'),
				this.getFormItemValue('dataFim')
			)}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.precipitacaoAnual.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		const colunasMeses = [
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
		];

		this.precipitacaoAnual.forEach((dadoRelatorio, index) => {
			const precipitacoesAnuais = dadoRelatorio.data;
			const estatisticas = this.estatisticas[index].data;

			const dadosTabelaPrecipitacaoAnual: (string | number)[][] = [];

			dadosTabelaPrecipitacaoAnual.push(['Ano', ...colunasMeses, 'Total']);

			precipitacoesAnuais.forEach(dado => {
				const { ano, ...rest } = dado;
				dadosTabelaPrecipitacaoAnual.push([
					ano,
					...Object.values(rest).map(d =>
						isNotNuloOuUndefined(d) ? String(numberToBrNumber(d)) : '-'
					),
				]);
			});

			const dadosTabelaEstatistica: (string | number)[][] = [];

			dadosTabelaEstatistica.push(['Estatísticas', ...colunasMeses]);

			estatisticas.forEach(dado => {
				const { estatistica, ...rest } = dado;

				dadosTabelaEstatistica.push([
					estatistica,
					...Object.values(rest).map(d =>
						isNotNuloOuUndefined(d) ? String(numberToBrNumber(d)) : '-'
					),
				]);
			});

			documentDefinition.content.push(
				{
					text: dadoRelatorio.municipio,
					fontSize: 12,
					margin: [0, 10],
				},
				{
					style: 'table',
					table: {
						widths: [75, ...Array(13).fill(24)],
						body: dadosTabelaPrecipitacaoAnual,
					},
				},
				{
					style: 'table',
					table: {
						widths: [75, ...Array(12).fill(24)],
						body: dadosTabelaEstatistica,
					},
				}
			);
		});

		documentDefinition.styles = {
			table: {
				fontSize: 8,
				margin: [0, 0, 0, 10],
			},
		};

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	async exportarCSV() {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Posto',
			'Ano',
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
			'Total',
		]);

		this.precipitacaoAnual.forEach(dadoRelatorio => {
			const [municipio, posto] = dadoRelatorio.municipio.split(' - ');

			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					municipio,
					posto,
					...Object.values(dado).map(valor =>
						isNotNuloOuUndefined(valor) ? valor : '-'
					),
				]);
			});
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-precipitacao-anual-${DateTimeUtils.formatarDataPeriodo(
				this.getFormItemValue('dataInicio'),
				this.getFormItemValue('dataFim')
			)}`
		);
	}

	async exportarTXT() {
		let txtData = '';

		this.precipitacaoAnual.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				txtData += `Município/Posto: ${dadoRelatorio.municipio}\n`;
				Object.entries(dado).forEach(([chave, valor]) => {
					txtData += `${chave}: ${valor ?? '-'}\n`;
				});
				txtData += '\n\n';
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-precipitacao-anual-${DateTimeUtils.formatarDataPeriodo(
				this.getFormItemValue('dataInicio'),
				this.getFormItemValue('dataFim')
			)}`
		);
	}

	getFormItemValue(controlName: string): any {
		return this.inputs.form.get(controlName)!.value;
	}
}
