import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
	exports: [RouterModule],
	imports: [
		RouterModule.forChild([
			{
				path: '',
				redirectTo: 'seguranca',
				pathMatch: 'full',
			},
			{
				path: 'orgao',
				loadChildren: () =>
					import('./submodulos/orgao/orgao.module').then(m => m.OrgaoModule),
			},
			{
				path: 'grupo',
				loadChildren: () =>
					import('./submodulos/grupo/grupo.module').then(m => m.GrupoModule),
			},
			{
				path: 'usuario',
				loadChildren: () =>
					import('./submodulos/usuario/usuario.module').then(
						m => m.UsuarioModule
					),
			},
			// {
			// 	path: 'cultura',
			// 	loadChildren: () =>
			// 		import('./submodulos/cultura/cultura.module').then(
			// 			m => m.CulturaModule
			// 		),
			// },
			// {
			// 	path: 'solo',
			// 	loadChildren: () =>
			// 		import('./submodulos/solo/solo.module').then(m => m.SoloModule),
			// },
			// {
			// 	path: 'zoneamento',
			// 	loadChildren: () =>
			// 		import(
			// 			'./submodulos/zoneamento-edafoclimatico/zoneamento-edafoclimatico.module'
			// 		).then(m => m.ZoneamentoEdafoclimaticoModule),
			// },
			// {
			// 	path: 'risco_climatico',
			// 	loadChildren: () =>
			// 		import('./submodulos/risco-climatico/risco-climatico.module').then(
			// 			m => m.RiscoClimaticoModule
			// 		),
			// },
			{
				path: 'comparar_modelo',
				loadChildren: () =>
					import('./submodulos/comparar-modelos/comparar-modelos.module').then(
						m => m.CompararModelosModule
					),
			},
			{
				path: 'modelo_numerico',
				loadChildren: () =>
					import('./submodulos/modelo-numerico/modelo-numerico.module').then(
						m => m.ModeloNumericoModule
					),
			},
			{
				path: 'configuracao-aviso-meteorologico',
				loadChildren: () =>
					import(
						'./submodulos/configuracao-aviso-meteorologico/configuracao-aviso-meteorologico.module'
					).then(m => m.ConfiguracaoAvisoMeteorologicoModule),
			},
			{
				path: 'configuracao-veranico',
				loadChildren: () =>
					import(
						'./submodulos/configuracao-veranico/configuracao-veranico.module'
					).then(m => m.ConfiguracaoVeranicoModule),
			},
			{
				path: 'ranges-quantis',
				loadChildren: () =>
					import('./submodulos/ranges-quantis/ranges-quantis.module').then(
						m => m.RangesQuantisModule
					),
			},
			{
				path: 'climatologia',
				loadChildren: () =>
					import('./submodulos/climatologia/climatologia.module').then(
						m => m.ClimatologiaModule
					),
			},
			{
				path: 'imagens-sistema',
				loadChildren: () =>
					import('./submodulos/imagens-sistema/imagens-sistema.module').then(
						m => m.ImagensSistemaModule
					),
			},
		]),
	],
})
export class AdministracaoRoutingModule {}
