<div>
	<label for="legenda" class="text-muted fw-semibold my-1">
		Pluviometria observada (mm)
	</label>
	<div class="bg-white rounded shadow-lg d-flex flex-column card" id="legenda">
		<div class="card-body">
			<div class="legenda-horizontal">
				<div
					*ngFor="let key of getLegendas(); index as i"
					class="legenda-item shadow"
					[style]="borderRadius(i)"
					[style.backgroundColor]="key.cor"
					[style.width]="width"></div>
			</div>
			<div class="legenda-horizontal">
				<div
					*ngFor="let key of getLegendas()"
					class="legenda-item"
					[style.width]="width">
					{{ key.valor }}
				</div>
			</div>
		</div>
	</div>
</div>
