import {
	ChangeDetectorRef,
	Component,
	DoCheck,
	inject,
	KeyValueDiffer,
	KeyValueDiffers,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { criarImagemBase64FromChart, obter_erro_request } from '@utils';
import { Chart, SeriesOptionsType } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { DadosDesvioRelatorio, RelatorioDesvio } from '../../../interfaces';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '../../../interfaces/tipos-relatorios';
import { RelatoriosService } from '../../../services/relatorios.service';
import { optionsChartDesvio } from './chart-options';
import { gerarFilenameGrafico } from '../../../utils';
import { format } from 'date-fns';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DocumentExporter } from '@utils/document-exporter';
import { DateTimeUtils } from '@utils/datetime-util';
import * as ExcelTable from 'mr-excel';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'seira-grafico-desvio-milimetro',
	templateUrl: './grafico-desvio.component.html',
	styleUrls: ['./grafico-desvio.component.scss'],
})
export class GraficoDesvioComponent implements DoCheck, OnDestroy, OnInit {
	inputs = inject(INPUTS_RELATORIOS);
	opcoesGrafico: Highcharts.Options = optionsChartDesvio;
	grafico?: Highcharts.Chart;
	relatorios: RelatorioDesvio[] = [];
	private differ: KeyValueDiffer<any, any>;
	fileName: string;
	descricaoRelatorio = '';
	descricaoRelatorioMilimetro =
		'Define-se como desvio (mm), a diferença entre a quantidade de chuva registrada e a sua climatologia para um mesmo período, em um determinado local ou região.';
	descricaoRelatorioPorcentagem =
		'Define-se como desvio (%) a variação da precipitação em relação à climatologia, expressa em porcentagem, que indica se o acumulado de chuvas foi superior ou inferior à climatologia.';

	periodoTitulo = '';
	_destroyed = new Subject();
	loading = true;
	resultado = false;
	tipoDesvio = '';
	tipoPeriodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA);
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLS(),
		},
	];
	agrupamentoPluviometriaObsHeader: {
		label: string;
		text: string;
		size: number;
	} = {
		label: '',
		text: '',
		size: 0,
	};
	tituloArquivoExportacao = '';

	constructor(
		private readonly relatorioService: RelatoriosService,
		private readonly differs: KeyValueDiffers,
		private readonly cdr: ChangeDetectorRef,
		private toast: ToastrService
	) {
		this.differ = this.differs.find({}).create();
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.descricaoRelatorio = this.isDesvioPorcentagem
			? this.descricaoRelatorioPorcentagem
			: this.descricaoRelatorioMilimetro;

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.clearValidators();
		this._destroyed.next(undefined);
	}

	setGrafico(grafico: Chart): void {
		this.grafico = grafico;
		this.configurarGrafico();
	}

	getAno(dataString: string | Date): number {
		return new Date(dataString).getUTCFullYear();
	}

	get isSeparadoPorEstacoes(): boolean {
		return ['MUNICIPIO', 'MUNICIPIO_POSTO'].includes(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
	}

	get _agrupamento(): string {
		return this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
	}

	public calcularDesvioMilimetro(chuva: number, climatologia: number): number {
		const desvio = chuva - climatologia;
		return parseFloat(desvio.toFixed(2));
	}

	public calcularDesvioPorcentagem(
		chuva: number,
		climatologia: number
	): number {
		if (climatologia === 0) {
			return 0;
		}
		const desvioPercentual = ((chuva - climatologia) / climatologia) * 100;
		return parseFloat(desvioPercentual.toFixed(2));
	}

	getSeriesSeparadasPorAno(relatorios: RelatorioDesvio[]): SeriesOptionsType[] {
		return relatorios.map(r => {
			const anos = new Set(r.desvios.map(d => this.getAno(d.data)));
			const desvios: DadosDesvioRelatorio[] = [];
			anos.forEach(ano => {
				const desviosDoAno = r.desvios.filter(d => this.getAno(d.data) === ano);
				const objChuvaEClimaSomados = desviosDoAno.reduce<DadosDesvioRelatorio>(
					(a, b) => ({
						desvioMilimetro: 0,
						chuva: a.chuva + b.chuva,
						desvioPorcentagem: 0,
						climatologia: a.climatologia + b.climatologia,
						data: new Date(this.getAno(b.data), 0, 1),
					}),
					{
						desvioMilimetro: 0,
						chuva: 0,
						desvioPorcentagem: 0,
						climatologia: 0,
						data: new Date(),
					}
				);
				const { length } = desviosDoAno;
				const { chuva, climatologia, data } = objChuvaEClimaSomados;
				const chuvaMedia = chuva / length;
				const climatologiaMedia = climatologia / length;
				const desvioMedioAno = {
					chuva: chuva / length,
					climatologia: chuva / length,
					data: data,
					desvioMilimetro: this.calcularDesvioMilimetro(
						chuvaMedia,
						climatologiaMedia
					),
					desvioPorcentagem: this.calcularDesvioPorcentagem(
						chuvaMedia,
						climatologiaMedia
					),
				};
				desvios.push(desvioMedioAno);
			});
			return {
				type: 'column',
				name: this.getNomeSerie(r),
				groupPadding: 0.1,
				pointPadding: 0.1,
				data: desvios.map(d =>
					this.isDesvioPorcentagem ? d.desvioPorcentagem : d.desvioMilimetro
				),
			};
		});
	}

	getNomeSerie(r: RelatorioDesvio): string {
		const mapeamento: Record<string, string> = {
			REGIAO_PLUVIOMETRICA: r.regiaoPluviometrica,
			MICRORREGIAO: r.microrregiao,
			BACIA: r.bacia,
			SUBBACIA: r.subBacia,
			MESORREGIAO: r.mesorregiao,
		};
		let name = '';
		if (this.isSeparadoPorEstacoes) {
			name = r.estacao;
		} else {
			name = mapeamento[this._agrupamento];
		}
		return name;
	}

	configurarGrafico(): void {
		const tipoPeriodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		const categories: string[] = [];
		let series: SeriesOptionsType[];
		if (tipoPeriodo === 'anual') {
			this.fileName = gerarFilenameGrafico(
				`desvio_${this.isDesvioPorcentagem ? '%' : 'mm'}_anual`
			);
			series = this.getSeriesSeparadasPorAno(this.relatorios);
			const years = new Set(
				this.relatorios.flatMap(r =>
					r.desvios.map(d => this.getAno(d.data).toString())
				)
			);

			categories.push(...years);
		} else {
			if (tipoPeriodo === 'mensal') {
				this.fileName = gerarFilenameGrafico(
					`desvio_${this.isDesvioPorcentagem ? '%' : 'mm'}_mensal`
				);
			}
			series = this.relatorios.map(r => {
				return {
					type: 'column',
					name: this.getNomeSerie(r),
					groupPadding: 0.1,
					pointPadding: 0.1,
					data: r.desvios.map(d => {
						categories.push(
							format(
								new Date(d.data),
								`${tipoPeriodo !== 'anual' ? 'MM/' : ''}yyyy`
							)
						);
						return this.isDesvioPorcentagem
							? d.desvioPorcentagem
							: d.desvioMilimetro;
					}),
				};
			});
		}
		if (this.grafico) {
			this.grafico.update(
				{
					series,
					xAxis: {
						categories,
						title: {
							text: tipoPeriodo === 'anual' ? 'Anos' : 'Meses',
						},
					},
					yAxis: {
						title: {
							text: this.isDesvioPorcentagem ? 'Desvio (%)' : 'Desvio (mm)',
						},
					},
					tooltip: {
						valueSuffix: this.isDesvioPorcentagem ? ' %' : ' mm',
					},
				},
				true,
				true
			);
			this.grafico.setTitle({
				text: `Desvio ${this.isDesvioPorcentagem ? '(%)' : '(mm)'} - ${
					this.periodoTitulo
				}`,
				align: 'center',
			});

			if (this._agrupamento == 'REGIAO_PLUVIOMETRICA') {
				this.grafico.update({
					colors: [
						'#FFFF00',
						'#00008B',
						'#90EE90',
						'#FFA500',
						'#FF0000',
						'#006400',
						'#5DADE2',
					],
				});
			} else {
				this.grafico.update({
					colors: undefined,
				});
			}

			this.opcoesGrafico = {
				...this.opcoesGrafico,
				exporting: {
					enabled: true,
					buttons: {
						contextButton: {
							menuItems: [
								'viewFullscreen',
								'separator',
								'downloadPNG',
								'downloadJPEG',
							],
						},
					},
					filename: this.fileName,
				},
			};
		}
		this.cdr.detectChanges();
		this.differ.diff(this);
	}

	ngDoCheck(): void {
		const changes = this.differ.diff(this);
		if (changes && this.grafico) {
			this.grafico.reflow();
		}
	}

	clearValidators(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	get dataFim(): Date {
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		return dataFim;
	}

	get dataInicio(): Date {
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		return dataInicio;
	}

	get isDesvioPorcentagem(): boolean {
		const tipo = this.inputs.form.get(FormularioRelatorio.TIPO)?.value;
		return tipo === 'DESVIO_PORCENTAGEM';
	}

	setAgrupamentoHeader(agrupamento: any) {
		agrupamento === 'BACIA'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'bacia',
					text: 'Bacia',
					size: 48,
			  })
			: agrupamento === 'MESORREGIAO'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'mesorregiao',
					text: 'Mesorregião',
					size: 48,
			  })
			: agrupamento === 'MICRORREGIAO'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'microrregiao',
					text: 'Microrregião',
					size: 48,
			  })
			: agrupamento === 'MUNICIPIO'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'municipio',
					text: 'Município',
					size: 48,
			  })
			: agrupamento === 'REGIAO_PLUVIOMETRICA'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'regiao',
					text: 'Região Pluviométrica',
					size: 48,
			  })
			: agrupamento === 'SUBBACIA'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'subbacia',
					text: 'Subbacia',
					size: 48,
			  })
			: '';
	}

	getDadosTabelaParaExportacao(dados: RelatorioDesvio[]) {
		const tableData: any[][] = [];
		this.tipoDesvio = this.inputs.form.get(FormularioRelatorio.TIPO)?.value;
		const agrupamento = this.inputs.form.get('agrupamento');
		const colunas: string[] = [];

		if (agrupamento?.value != 'MUNICIPIO_POSTO') {
			colunas.push(`${this.agrupamentoPluviometriaObsHeader.text}`);
		} else {
			colunas.push('Município/Posto');
		}

		colunas.push('Climatologia', 'Chuva', 'Data');

		this.tipoDesvio === 'DESVIO_MILIMETRO'
			? colunas.push('Desvio (mm)')
			: colunas.push('Desvio (%)');

		if (
			agrupamento?.value === 'MUNICIPIO_POSTO' ||
			agrupamento?.value === 'MICRORREGIAO' ||
			agrupamento?.value === 'MUNICIPIO'
		) {
			if (agrupamento?.value === 'MICRORREGIAO') {
				colunas.push('Município');
			} else {
				colunas.push('Estação', 'Latitude', 'Longitude');
			}
		}

		tableData.push(colunas);

		dados.forEach((item: RelatorioDesvio) => {
			item.desvios.forEach(desvio => {
				const rowData = [
					item.microrregiao
						? item.microrregiao
						: item.mesorregiao
						? item.mesorregiao
						: item.bacia
						? item.bacia
						: item.subBacia
						? item.subBacia
						: item.regiaoPluviometrica
						? item.regiaoPluviometrica
						: item.municipio,

					desvio.climatologia,
					desvio.chuva,
					DateTimeUtils.formatarData(desvio.data.toString(), 'dd/MM/yyyy'),

					this.tipoDesvio === 'DESVIO_MILIMETRO'
						? desvio.desvioMilimetro
						: desvio.desvioPorcentagem,
				];

				if (
					agrupamento?.value === 'MUNICIPIO_POSTO' ||
					agrupamento?.value === 'MICRORREGIAO' ||
					agrupamento?.value === 'MUNICIPIO'
				) {
					if (agrupamento?.value === 'MICRORREGIAO') {
						rowData.push(item.municipio);
					} else {
						rowData.push(item.estacao, item.latitude, item.longitude);
					}
				}

				tableData.push(rowData);
			});
		});

		return tableData;
	}

	gerarRelatorio(): void {
		const agrupamento = this.inputs.form.get('agrupamento');
		this.tituloArquivoExportacao = `Relatório de Desvios - ${
			this.tipoPeriodo?.value === 'anual'
				? this.getAno(this.dataInicio) + ' a ' + this.getAno(this.dataFim)
				: this.tipoPeriodo?.value === 'mensal'
				? DateTimeUtils.getMesNumericoEAno(this.dataInicio) +
				  ' a ' +
				  DateTimeUtils.getMesNumericoEAno(this.dataFim)
				: ''
		}`;

		if (
			![
				'MUNICIPIO_POSTO',
				'MUNICIPIO',
				'MICRORREGIAO',
				'MESORREGIAO',
				'REGIAO_PLUVIOMETRICA',
				'BACIA',
				'SUBBACIA',
			].includes(this._agrupamento)
		) {
			this.toast.error(
				`A geração de gráfico de desvio não é permitida para este agrupamento`,
				'Opção não permitida'
			);
			return;
		}
		let chave: keyof typeof FormularioRelatorio | undefined;
		if (
			!['MESORREGIAO', 'REGIAO_PLUVIOMETRICA', 'BACIA'].includes(
				this._agrupamento
			)
		) {
			chave =
				this._agrupamento === 'MUNICIPIO_POSTO'
					? 'ESTACAO'
					: (this._agrupamento as keyof typeof FormularioRelatorio);
		}
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			this.dataInicio,
			this.dataFim,
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value
		);
		this.loading = true;
		this.inputs.setLoading(true);
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento: this._agrupamento,
				idEntidade: chave
					? this.inputs.form.get(FormularioRelatorio[chave])?.value
					: null,
				dataFim: this.dataFim,
				dataInicio: this.dataInicio,
				periodo: periodo.toUpperCase(),
				incluirDetalhes: false,
			})
			.subscribe({
				next: value => {
					this.relatorioService.verificaExistenciaDados(value);

					if (value.length === 0) {
						this.toast.info(
							'Não há desvios para os parametros selecionados',
							'Sem dados'
						);
					}
					this.setAgrupamentoHeader(agrupamento?.value);
					this.relatorios = value;
				},
				error: err => {
					const msgErro = obter_erro_request(err);
					this.toast.error(
						msgErro,
						'Erro ao obter informações de desvio no servidor'
					);
					this.loading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (!this.grafico) {
			this.toast.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.grafico),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: `Relatório - Climatologia/desvio (${
					this.tipoDesvio === 'porcentagem' ? '%' : 'mm'
				})/observado - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.relatorios);
		DocumentExporter.gerarCSV(tableData, this.tituloArquivoExportacao);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.relatorios);
		const txtContent = tableData.map(row => row.join('\t')).join('\n');
		DocumentExporter.gerarTXT(txtContent, this.tituloArquivoExportacao);
	}

	async exportarXLS() {
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'municipio',
				text: 'Municipio',
				size: 48,
			},
			{ label: 'latitude', text: 'Latitude', size: 24 },
			{ label: 'longitude', text: 'Longitude', size: 24 },
		];

		this.tipoDesvio === 'desvioMilimetro'
			? headers.push({
					label: 'desvioMilimetro',
					text: 'Desvio (mm)',
					size: 24,
			  })
			: headers.push({
					label: 'desvioPorcentagem',
					text: 'Desvio (%)',
					size: 24,
			  });

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.relatorios.map(desvio => {
						if (this.tipoDesvio === 'desvioMilimetro') {
							return {
								desvioMilimetro: desvio.desvioMilimetro,
								latitude: desvio.latitude,
								longitude: desvio.longitude,
								municipio: desvio.municipio,
							};
						} else {
							return {
								desvioPorcentagem: desvio.desvioPorcentagem,
								latitude: desvio.latitude,
								longitude: desvio.longitude,
								municipio: desvio.municipio,
							};
						}
					}),
					columns: [
						{ key: 'municipio' },
						{
							key: 'latitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'longitude',
							style: { numFmt: '0.0' },
						},
						{
							key:
								this.tipoDesvio === 'desvioMilimetro'
									? 'desvioMilimetro'
									: 'desvioPorcentagem',
							style: { numFmt: '0.0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${this.tituloArquivoExportacao}`,
						styleId: 'title',
					},
				},
			],
			fileName: this.tituloArquivoExportacao,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
