import { Component, inject, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { handleGenerateQuantisDataHighchart } from '@home/submodulos/dados-meteorologicos/utils/graficoUtils';
import { gerarTabelaParaRelatorioDeQuantisPdf } from '@home/submodulos/dados-meteorologicos/utils/relatorio-quantis';
import { Select } from '@layout/interfaces/select';
import { criarImagemBase64FromChart } from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { Chart } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { ContentText, TDocumentDefinitions } from 'pdfmake/interfaces';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Meses, PeriodoChuvosoMap } from '../../../enum';
import {
	DadoGraficoQuantis,
	DadosGraficoQuantisMensal,
	TipoGraficos,
} from '../../../interfaces';
import { QuantisMicrorregiaoService } from '../../../services';
import { gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';

@Component({
	selector: 'seira-pluviometro-grafico-quantis',
	templateUrl: './pluviometro-grafico-quantis.component.html',
	styleUrls: ['./pluviometro-grafico-quantis.component.scss'],
})
export class PluviometroGraficoQuantisComponent
	implements OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	microrregioes!: Select<string>[];
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	chart!: Chart;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
	];

	loading = true;
	gerou = false;
	useMaximumLimit = 500;
	isAnual = false;
	isPeriodosPluviometricos = false;
	resultado?: DadosGraficoQuantisMensal;
	private subscription = new Subscription();
	private _destroyed = new Subject();

	descricaoRelatorio = '';
	descricaoRelatorioMensal =
		'Define-se como quantis mensais, os valores que dividem uma amostra de dados mensais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	descricaoRelatorioAnual =
		'Define-se como quantis anuais, os valores que dividem uma amostra de dados anuais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	descricaoRelatorioPluviometrico =
		'Define-se como quantis por período chuvoso os valores que segmentam a precipitação acumulada ao longo de diferentes períodos, sejam eles anuais, quadrimestrais, quinquemestrais ou semestrais, dividindo-a em percentis para analisar a distribuição da chuva em uma microrregião.';

	periodoTitulo = '';
	quantisTitulo = '';

	constructor(
		private toastr: ToastrService,
		private readonly quantisService: QuantisMicrorregiaoService
	) {}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_CHUVOSO)
			?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.clearValidators();
		this.subscription.unsubscribe();
		this._destroyed.next(undefined);
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}
		this.loading = true;
		this.inputs.setLoading(true);
		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);
		this.handleFetchQuantisByType(tipoInput?.value);
		this.handleSetValidatorsByType(tipoInput?.value);
		tipoInput?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: values => {
				this.handleFetchQuantisByType(values);
				this.handleSetValidatorsByType(values);
			},
		});
		this.descricaoRelatorio =
			tipoInput?.value === 'QUANTIS_MENSAL'
				? this.descricaoRelatorioMensal
				: tipoInput?.value === 'QUANTIS_ANUAL'
				? this.descricaoRelatorioAnual
				: this.descricaoRelatorioPluviometrico;
	}

	handleFetchQuantisByType(type: string) {
		if (type === 'QUANTIS_PLUVIOMETRICOS') {
			this.quantisTitulo = 'pluviométricos';
			this.handleFetchQuantisByRegiaoAndPeriodoChuvoso();
		}

		if (type === 'QUANTIS_MENSAL') {
			this.quantisTitulo = 'mensal';
			this.handleFetchQuantisMensal();
		}

		if (type === 'QUANTIS_ANUAL') {
			this.quantisTitulo = 'anual';
			this.handleFetchQuantisAnual();
		}
	}

	handleFetchQuantisByRegiaoAndPeriodoChuvoso() {
		const periodoChuvoso = this.inputs.form.get('periodoChuvoso')
			?.value as string;
		const regiao = this.inputs.form.get('regiao')?.value;
		this.isPeriodosPluviometricos = true;
		const mapeamentoQtd: Record<string, number> = {
			QUADRA_CHUVOSA: 4,
			QUINTETO_CHUVOSO: 5,
			SEMESTRE_CHUVOSO: 6,
			ANO_CIVIL: 10,
		};
		const hoje = new Date();
		this.quantisService
			.handleFetchUltimosQuantisByRegiaoAndPeriodoChuvoso(
				regiao,
				periodoChuvoso,
				mapeamentoQtd[periodoChuvoso]
			)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] =
						resp.quantis.map<DadoGraficoQuantis>(q => ({
							titulo: q.ano?.toString(),
							categorias: handleGenerateQuantisDataHighchart(q),
						}));
					this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
						new Date(
							hoje.getFullYear() - mapeamentoQtd[periodoChuvoso] + 1,
							0,
							1
						),
						new Date(hoje.getFullYear(), 0, 1),
						periodoChuvoso
					);
					const periodo = PeriodoChuvosoMap.get(
						this.inputs.form.get(FormularioRelatorio.PERIODO_CHUVOSO)?.value
					);
					const tituloGeral = `Região: ${resp.regiao} - Período: ${periodo} - ${this.periodoTitulo}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';
					const fileName = gerarFilenameGrafico(`quantis_regiao`);
					this.resultado = {
						tituloGeral,
						fileName,
						legenda,
						dadosGraficosQuantis,
					};
					const todosValores = resp.quantis.flatMap(
						({ ano, ...valores }) => Object.values(valores) as number[]
					);
					this.useMaximumLimit = Math.max(...todosValores) + 100;
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	handleFetchQuantisMensal() {
		const ano = this.inputs.form.get('periodo')?.value;
		const estacao = Number.parseInt(this.inputs.form.get('estacao')?.value);

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(ano, '', '', true);
		this.quantisService
			.handleFetchQuantisMensaisGraphByEstacaoId(
				estacao,
				new Date(ano).getFullYear()
			)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);

							return {
								titulo: `${Meses[q.mes! - 1]}`,
								categorias: newCategorias,
							};
						}
					);

					const tituloGeral = `Posto: ${resp.posto} (${resp.municipio}) - ${resp.ano}`;
					const fileName = gerarFilenameGrafico(
						`quantis_mensal_${resp.posto?.toLocaleLowerCase()}`
					);
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultado = {
						tituloGeral,
						fileName,
						legenda,
						dadosGraficosQuantis,
					};
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	handleFetchQuantisAnual() {
		const ano = this.inputs.form.get('periodo')?.value;
		const estacao = Number.parseInt(this.inputs.form.get('estacao')?.value);

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(ano, '', '', true);
		this.quantisService
			.handleFetchQuantilAnualGraphByEstacaoId(
				estacao,
				new Date(ano).getFullYear()
			)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = [
						{
							titulo: new Date(ano).getFullYear().toString(),
							categorias: handleGenerateQuantisDataHighchart(
								resp.quantis.valores
							),
						},
					];

					const tituloGeral = `Posto: ${resp.posto} - ${resp.ano}`;
					const fileName = gerarFilenameGrafico(
						`quantis_anual_${resp.posto?.toLocaleLowerCase()}`
					);
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultado = {
						tituloGeral,
						fileName,
						legenda,
						dadosGraficosQuantis,
					};
					this.useMaximumLimit =
						Math.max(
							...Object.values(resp.quantis.valores).map(
								values => values as number
							)
						) + 100;
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.isAnual = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}

	getPeriodo() {
		return this.inputs.form.get('periodo')?.value.year() || '';
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (
			!this.resultado ||
			!this.chart ||
			!this.resultado.dadosGraficosQuantis.length
		) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}
		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 450,
			width: 420,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions();
		const quantisTypeTitle = Object.entries(TipoGraficos).find(
			att => att[0].toString() === this.inputs.form.get('tipo')!.value
		)?.[1];
		const content = [
			{
				text: `Relatório - ${quantisTypeTitle} - ${this.getPeriodo()}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		if (this.resultado?.dadosGraficosQuantis) {
			const table = {
				table: gerarTabelaParaRelatorioDeQuantisPdf(
					this.resultado.dadosGraficosQuantis,
					true
				),
			};
			const labelTabela: ContentText = {
				text: `Todos os dados de medidas na tabela estão expressos em milímetros(mm).`,
				margin: [0, 15, 0, 5],
				fontSize: 10,
			};
			content.push(labelTabela);
			content.push(table);
		}
		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	handleSetValidatorsByType(type: string) {
		if (type === 'QUANTIS_PLUVIOMETRICOS') {
			this.setValidatorsToQuantisPluviometricos();
		}
		if (type === 'QUANTIS_MENSAL' || type === 'QUANTIS_ANUAL') {
			this.setValidatorsToQuantisMensalOrAnual();
		}
	}

	setValidatorsToQuantisMensalOrAnual() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	setValidatorsToQuantisPluviometricos() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_CHUVOSO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.setValidators(Validators.required);
	}
}
