export const optionsChartPluviometriaObservada: Highcharts.Options = {
	chart: {
		type: 'column',
	},
	title: {
		text: 'Pluviometria observada',
		align: 'center',
	},
	tooltip: {
		valueSuffix: ' mm',
		valueDecimals: 1,
	},
	yAxis: [
		{
			title: {
				text: 'Precipitação (mm)',
			},
		},
	],
	navigator: {
		enabled: false,
	},
	exporting: {
		enabled: true,
		buttons: {
			contextButton: {
				menuItems: [
					'viewFullscreen',
					'separator',
					'downloadPNG',
					'downloadJPEG',
				],
			},
		},
	},
	rangeSelector: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
	xAxis: [{ categories: [] }],
	series: [],
};
