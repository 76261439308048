<seira-public-modal [close]="close">
	<div title class="text-tertiary d-flex gap-3 align-items-center">
		<h2 class="fw-medium m-0 w-auto">
			Relatório de {{ decapitalizeFirstLetter(labelTipoVariavel) }} do período
			de
			{{ imagem.data | date: getDateFormat(imagem.historico[0].periodo) }}
		</h2>
	</div>
	<div content [formGroup]="form">
		<div class="row justify-content-between align-items-end">
			<div class="col-lg-6">
				<seira-public-radio-group
					[options]="OpcoesTipoVisualizacao"
					label="Modo de visualização"
					controlName="visualizacao"></seira-public-radio-group>
			</div>
			<div class="col-lg-6 text-end">
				<seira-public-button-group [buttons]="botoesExportacao" />
			</div>
		</div>
		<div
			[ngStyle]="{ display: tipoVisualizacao === 'tabela' ? 'none' : 'block' }">
			<div
				class="d-flex flex-column align-items-center w-100"
				*ngIf="imagem.historico.length">
				<div style="min-width: 75%" class="mb-2" id="mapa-svg">
					<seira-mapa-paraiba-svg
						[atributosMunicipio]="imagem.historico"
						[customSetColorByValue]="funcaoCoresMapa"
						[popoverTemplate]="popup" />
				</div>
				<seira-legenda-relatorio-historico-variaveis
					[options]="legenda"
					style="width: 100%" />
			</div>
		</div>
		<div
			[ngStyle]="{ display: tipoVisualizacao === 'mapa' ? 'none' : 'block' }"
			class="mt-2">
			<seira-public-table
				#tabelaMosaico
				id="tabela-mosaico"
				[searching]="false"
				[info]="true"
				[paging]="true"
				[hasFooter]="true"
				[hasHeader]="false"
				[data]="imagem.historico"
				[columns]="colunasTabela"></seira-public-table>
		</div>
	</div>
</seira-public-modal>
<ng-template
	#popup
	let-color="color"
	let-nome="nome"
	let-dadosComMesmoGeocodigo="dadosComMesmoGeocodigo">
	<seira-popup-padrao-mapa-paraiba
		[headerColor]="color"
		[headerTextColor]="handleTextColorBasedOnBackgroundColor(color)">
		<ng-container header>{{ nome }}</ng-container>
		<ng-container data>
			<div
				class="d-flex flex-column"
				*ngIf="dadosComMesmoGeocodigo.length; else semMedicao">
				<div class="d-inline-flex align-items-center text-center">
					<i
						*ngIf="isNotNuloOuUndefined(dadosComMesmoGeocodigo[0].valor)"
						class="fs-1"
						[ngClass]="
							imagem.tipoVariavel === 'precipitacao'
								? 'ph-drop'
								: 'ph-thermometer'
						"></i>
					<span class="fs-3">{{
						labelValorMedido(dadosComMesmoGeocodigo[0].valor)
					}}</span>
				</div>
			</div>
		</ng-container>
		<ng-container description *ngIf="dadosComMesmoGeocodigo.length">
			{{ labelTipoVariavel }}
		</ng-container>
	</seira-popup-padrao-mapa-paraiba>
	<ng-template #semMedicao>
		Sem medições para o muncípio nesse período.
	</ng-template>
</ng-template>
