<div>
	<label for="legenda" class="text-muted fw-semibold my-1"> NDC (dias) </label>
	<div class="bg-white rounded shadow-lg d-flex flex-column card" id="legenda">
		<div class="card-body">
			<div class="legenda-horizontal">
				<div
					*ngFor="let key of getKeys()"
					class="legenda-item"
					[style]="borderRadius(key)"
					[style.backgroundColor]="legendaInfo[key]"></div>
			</div>
			<div class="legenda-horizontal">
				<div *ngFor="let key of getLegendas()" class="legenda-item">
					{{ key.valor }}
				</div>
			</div>
		</div>
	</div>
</div>
