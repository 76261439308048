import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';

import { Validators } from '@angular/forms';
import { optionsChartPluviometriaObservada } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada/chartOptions';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
} from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import * as Highcharts from 'highcharts/highstock';
import { Subject, takeUntil } from 'rxjs';
import { gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';

@Component({
	selector: 'seira-grafico-pluviometria-observada',
	templateUrl: './grafico-pluviometria-observada.component.html',
	styleUrls: ['./grafico-pluviometria-observada.component.scss'],
})
export class GraficoPluviometriaObservadaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	chart: Highcharts.Chart;
	chartOptions: Highcharts.Options = optionsChartPluviometriaObservada;
	_destroyed = new Subject();
	descricaoRelatorio =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período.';
	periodoTitulo = '';
	loading = true;
	response: AgrupamentoResponse[];

	constructor(private relatoriosService: RelatoriosService) {}

	ngOnInit(): void {
		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
		this.configurarNewGrafico(this.response);
	}

	checaAgrupamento(dados: DadosAgrupamento) {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = this.inputs.form.get(FormularioRelatorio.ESTACAO)?.value;
				break;

			case 'MICRORREGIAO':
				dados.microrregiao = this.inputs.form.get(
					FormularioRelatorio.MICRORREGIAO
				)?.value;
				break;
			case 'MUNICIPIO':
				dados.municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO)
					?.value;
				break;
		}
	}

	gerarRelatorio() {
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};
		this.checaAgrupamento(dados);
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		);
		this.loading = true;
		this.inputs.setLoading(true);
		this.relatoriosService.buscarRelatorioPorAgrupamento(dados).subscribe({
			next: preciptacoesAgrupadas => {
				this.response = preciptacoesAgrupadas;
				this.chartOptions = {
					...this.chartOptions,
					exporting: {
						...this.chartOptions.exporting,
						filename: gerarFilenameGrafico(
							`grafico_pluviometria_observada_${this.inputs.form
								.get(FormularioRelatorio.AGRUPAMENTO)
								?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
						),
					},
				};
			},
			complete: () => {
				this.loading = false;
				this.inputs.setLoading(false);
			},
		});
	}

	extrairDatasPrecipitacao(
		preciptacoesAgrupadas: AgrupamentoResponse[],
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacoesAgrupadas
					.map(precipitacoes => {
						/**
						 * Esse regex é necessário por questões de algum bug que acontece no backend
						 * onde a data é retornada como "2024.0" por exemplo.
						 * Esse regex é uma solução provisória para resolver esse bug.
						 */
						const data = new Date(precipitacoes.data!.replace(/\.0/g, ''));
						return DateTimeUtils.formatarDataPeriodo(
							data.setHours(data.getHours() + 3),
							'',
							periodoAgrupamento,
							true
						);
					})
					.sort((a, b) => +a - +b)
			),
		];
	}

	extrairNomes(preciptacoesAgrupadas: AgrupamentoResponse[]) {
		return [...new Set(preciptacoesAgrupadas.map(el => el.nome))];
	}

	configurarNewGrafico(preciptacoesAgrupadas: AgrupamentoResponse[]) {
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		const extractedSeries = this.extrairNomes(preciptacoesAgrupadas);
		const categories = this.extrairDatasPrecipitacao(
			preciptacoesAgrupadas,
			periodo
		);

		this.chart.xAxis[0].setCategories(categories);
		const series = extractedSeries.map(serie => ({
			name: serie,
			data: categories.map(categoria => {
				const pluviometriaFindedByName = preciptacoesAgrupadas.find(
					agrupamento => {
						/**
						 * Esse regex é necessário por questões de algum bug que acontece no backend
						 * onde a data é retornada como "2024.0" por exemplo.
						 * Esse regex é uma solução provisória para resolver esse bug.
						 */
						const data = new Date(agrupamento.data!.replace(/\.0/g, ''));
						const dataFormatted = DateTimeUtils.formatarDataPeriodo(
							data.setHours(data.getHours() + 3),
							'',
							periodo,
							true
						);
						return (
							dataFormatted.replace(/\.0/g, '') === categoria &&
							agrupamento.nome === serie
						);
					}
				);
				return pluviometriaFindedByName
					? {
							name: pluviometriaFindedByName.nome,
							y: +pluviometriaFindedByName.precipitacaoAcumulada.toFixed(2),
					  }
					: null;
			}),
		}));
		series.forEach(serie => this.chart.addSeries(serie as any));
	}
}
