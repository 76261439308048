import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DadosAgrupamento } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import * as Highcharts from 'highcharts/highstock';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { RelatorioNDCResponse } from './../../../interfaces/relatorio-ndc';
import { options } from './chartOptions';
import { gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';

@Component({
	selector: 'seira-grafico-ndc',
	templateUrl: './grafico-ndc.component.html',
	styleUrls: ['./grafico-ndc.component.scss'],
})
export class GraficoNdcComponent implements OnInit, OnDestroy {
	chart!: Highcharts.Chart;
	chartOptions: Highcharts.Options = options;

	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloEixoX = '';
	resultados: RelatorioNDCResponse[] = [];
	descricaoRelatorio =
		'Define-se número de dias com chuva (NDC) como o número de dias em que a pluviometria diária, acumulada em 24 horas, foi superior a 0,1mm.';

	periodoTitulo = '';
	loading = true;

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {}

	ngOnInit(): void {
		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
		this.atualizarTitulosChart();
		this.configurarChart(this.resultados);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	gerarRelatorio() {
		this.limparChart();

		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		);

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
			isRetornoAgrupado: true,
		};

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = this.getDataFromForm(FormularioRelatorio.ESTACAO)?.value;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = this.getDataFromForm(
					FormularioRelatorio.MICRORREGIAO
				)?.value;
				break;
			case 'MUNICIPIO':
				dados.municipio = this.getDataFromForm(FormularioRelatorio.MUNICIPIO)
					?.value;
				break;
		}

		this.relatoriosService.buscarRelatorioNDCPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				this.relatoriosService.verificaExistenciaDados(ndcAgrupados);
				this.resultados = ndcAgrupados;

				this.chartOptions = {
					...this.chartOptions,
					exporting: {
						enabled: true,
						buttons: {
							contextButton: {
								menuItems: [
									'viewFullscreen',
									'separator',
									'downloadPNG',
									'downloadJPEG',
								],
							},
						},
						filename: gerarFilenameGrafico(
							`grafico_numero_dias_com_chuva_${this.inputs.form
								.get(FormularioRelatorio.AGRUPAMENTO)
								?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
						),
					},
				};
			},
			error: err => {
				this.toastrService.error(
					'Erro ao gerar gráfico de NDC, tente novamente'
				);
				this.loading = false;
				this.inputs.setLoading(false);
				this.configurarChart([]);
			},
			complete: () => {
				this.loading = false;
				this.inputs.setLoading(false);
			},
		});
	}

	limparChart() {
		if (this.chart && this.chart.series.length > 0) {
			while (this.chart.series.length > 0) {
				this.chart.series[0].remove(true);
			}
		}
	}

	extrairNomes(preciptacoesAgrupadas: RelatorioNDCResponse[]) {
		return [...new Set(preciptacoesAgrupadas.map(el => el.nome))];
	}

	configurarChart(resultados: RelatorioNDCResponse[]) {
		let series = [];
		let categorias: string[] = [];
		const tipoPeriodo = this.getDataFromForm(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (tipoPeriodo === 'mensal' || tipoPeriodo === 'anual') {
			categorias = [
				...new Set(
					resultados.map(resultado =>
						tipoPeriodo === 'anual'
							? resultado.ano?.toString() || ''
							: `${String(resultado.mes?.toString().padStart(2, '0'))}/${
									resultado.ano || ''
							  }`
					)
				),
			];

			const nomes = this.extrairNomes(resultados);
			series = nomes.map(nome => ({
				type: 'column' as const,
				name: nome,
				data: categorias.map(categoria => {
					const ponto = resultados.find(r => {
						const categoriaAtual =
							tipoPeriodo === 'anual'
								? r.ano?.toString() || ''
								: `${String(r.mes?.toString().padStart(2, '0'))}/${
										r.ano || ''
								  }`;
						return r.nome === nome && categoriaAtual === categoria;
					});
					return ponto
						? {
								name: ponto.nome,
								y: ponto.diasComChuva,
								precipitacao:
									ponto.somaPrecipitacao?.toFixed(1).replace('.', ',') || '0',
						  }
						: { y: null, precipitacao: '0' };
				}),
			}));

			this.chart?.xAxis[0].setCategories(categorias);
		} else {
			series = [
				{
					type: 'column' as const,
					name: 'Dias com chuva',
					data: resultados.map(ndc => ({
						name: ndc.nome,
						y: ndc.diasComChuva,
						z: 1,
						precipitacao:
							ndc.somaPrecipitacao?.toFixed(1).replace('.', ',') || '0',
					})),
				},
			];
		}

		this.chart?.update({
			tooltip: {
				pointFormat:
					'Dias com chuva: <b>{point.y} dias</b><br>' +
					'Precipitação total: <b>{point.precipitacao} mm</b><br>',
			},
		});

		while (this.chart?.series.length) {
			this.chart.series[0].remove(false);
		}

		series.forEach(serie => {
			this.chart?.addSeries(serie as Highcharts.SeriesOptionsType, false);
		});

		this.chart?.redraw();
	}

	atualizarTitulosChart() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const tipoPeriodo = this.getDataFromForm(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (tipoPeriodo === 'mensal') {
			this.tituloEixoX = 'Meses';
		} else if (tipoPeriodo === 'anual') {
			this.tituloEixoX = 'Anos';
		} else {
			switch (agrupamento) {
				case 'MUNICIPIO_POSTO':
					this.tituloEixoX = 'Município/Posto';
					break;
				case 'MICRORREGIAO':
					this.tituloEixoX = 'Microrregião';
					break;
				case 'MUNICIPIO':
					this.tituloEixoX = 'Município';
					break;
				case 'MESORREGIAO':
					this.tituloEixoX = 'Mesorregiões';
					break;
				case 'REGIAO_PLUVIOMETRICA':
					this.tituloEixoX = 'Regiões Pluviométricas';
					break;
				case 'BACIA':
					this.tituloEixoX = 'Bacias';
					break;
				case 'SUB_BACIA':
					this.tituloEixoX = 'Sub bacias';
					break;
			}
		}

		this.chart.xAxis[0].setTitle({ text: this.tituloEixoX });
	}
}
