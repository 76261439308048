<label class="text-center text-tertiary fw-semibold w-100 mt-3">
	Índice de precipitação padronizada (SPI) - {{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

<div class="d-flex justify-content-center align-items-center w-100">
	<div
		*ngIf="carregandoRelatorio; else graficoSPI"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<ng-template #graficoSPI>
		<div *ngIf="!!graficoSPIUrl; else semDados">
			<div class="d-flex justify-content-center">
				<img
					class="grafico-responsivo"
					[src]="graficoSPIUrl"
					alt="Gráfico SPI" />
			</div>
		</div>
	</ng-template>

	<ng-template #semDados>
		<seira-sem-dados />
	</ng-template>
</div>
