<div>
	<div class="d-flex align-items-center">
		<seira-page-title class="w-100 align-items-center">
			<div class="d-flex justify-content-between align-items-center">
				<div
					style="color: #728187; font-weight: 400; font-size: 24px !important">
					Dados meteorológicos
					<i class="dot bg-primary dot-space"></i>
					Estações Automáticas
				</div>
				<div class="d-flex justify-content-end">
					<seira-public-page-about
						class="size-about"
						[textosInformativos]="informacoesGerais"></seira-public-page-about>
				</div>
			</div>
		</seira-page-title>
	</div>

	<form
		[formGroup]="form"
		class="form-group"
		[ngSwitch]="getFormItemValue('tipoVisualizacao')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="col-lg-3">
				<div class="col-lg-9 col-md-4 col-sm-6 mt-3">
					<seira-public-radio-group
						idRadio="modoVisualizacaoEstacoes"
						[options]="OpcoesTipoVisualizacao"
						label="Modo de visualização"
						controlName="tipoVisualizacao"></seira-public-radio-group>
				</div>

				<div *ngIf="mostrarSelectTipo" class="mt-1">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo">
					</seira-public-select>
				</div>

				<div *ngIf="mostrarSelectVariaveisGrafico" class="mt-1">
					<seira-public-select
						placeholder="Selecione uma variável"
						label="Variável"
						[options]="variaveis"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma variável encontrado"
						formControlName="variavel">
					</seira-public-select>
				</div>
				<div *ngIf="mostrarDateRangePicker" class="mt-1">
					<seira-public-date-range-picker
						style="width: 100%"
						label="Período"
						controlStartName="dataInicio"
						controlEndName="dataFim"
						startPlaceholder="Inicio"
						endPlaceholder="Fim" />
				</div>

				<div *ngIf="mostrarAgrupamento" class="mt-1">
					<seira-public-select
						placeholder="selecione um agrupamento"
						label="Agrupamento"
						[options]="optionsAgrupamento"
						optionValue="value"
						optionLabel="name"
						formControlName="agrupamento">
					</seira-public-select>
				</div>
				<div *ngIf="mostrarSelectDePostos" class="mt-1">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Posto"
						[searchable]="true"
						[options]="options"
						optionValue="value"
						optionLabel="label"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao">
					</seira-public-select>
				</div>
				<div *ngIf="mostrarSelectDePostosValoresExtremos" class="mt-1">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Posto"
						[searchable]="true"
						[options]="options"
						optionValue="value"
						optionLabel="label"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao">
					</seira-public-select>
				</div>
				<ng-container>
					<div *ngIf="mostrarSelectTipoPeriodoValoresExtremos" class="mt-1">
						<seira-public-select
							[options]="tiposDePeriodosValoresExtremos"
							style="width: 100%"
							optionValue="value"
							optionLabel="name"
							label="Tipo de período"
							placeholder="Selecione um tipo de período"
							formControlName="tipoPeriodoValoresExtremos" />
					</div>
					<div *ngIf="mostrarDatePickerAnoCompleto" class="mt-1">
						<seira-public-date-picker
							style="width: 100%"
							label="Período"
							placeholder="Selecione um período"
							controlName="dataInicio"
							datePickerType="year" />
					</div>
					<div *ngIf="mostrarDatePickerAnoParcial" class="mt-1">
						<seira-public-date-picker
							[id]="'dataInicio'"
							style="width: 100%"
							label="Data de início"
							placeholder="Início"
							controlName="dataInicio"
							datePickerType="partial_year" />

						<seira-public-date-picker
							[id]="'dataFim'"
							style="width: 100%"
							label="Data de fim"
							placeholder="Selecione uma data"
							controlName="dataFim"
							[minDate]="calcularDataInicioValoresExtremos"
							[maxDate]="calcularDataFimValoresExtremos"
							datePickerType="partial_month" />
					</div>
					<div *ngIf="mostrarDatePickerMesCompleto" class="mt-1">
						<seira-public-date-picker
							style="width: 100%"
							label="Período"
							placeholder="Selecione um período"
							controlName="dataInicio"
							datePickerType="month" />
					</div>
					<div *ngIf="mostrarDatePickerMesParcial" class="mt-1">
						<seira-public-date-range-picker
							style="width: 100%"
							label="Período"
							controlStartName="dataInicio"
							controlEndName="dataFim"
							startPlaceholder="Inicio"
							endPlaceholder="Fim" />
					</div>
				</ng-container>
				<div *ngIf="mostrarSelectTipoMapa">
					<seira-public-select
						[options]="tiposMapas"
						style="width: 100%"
						optionValue="value"
						optionLabel="name"
						label="Tipo mapa"
						placeholder="Selecione um tipo de mapa"
						formControlName="tipoMapa" />
				</div>

				<div *ngIf="mostrarSelectsMapa" class="mt-1">
					<seira-public-select
						class="col-12 col-md-3 col-lg-2"
						placeholder="Informe a janela de tempo"
						[options]="janelasDeTempo"
						label="Janela de tempo"
						optionValue="name"
						optionLabel="value"
						notFoundText="Nenhuma janela de tempo encontrada"
						formControlName="janelaDeTempo"
						style="margin-right: 10px">
					</seira-public-select>
					<seira-public-date-range-picker
						*ngIf="buscaPersonalizada"
						class="col-12 col-md-3 col-lg-2"
						style="margin-right: 10px"
						label="Período"
						controlEndName="dataFim"
						controlStartName="dataInicio"></seira-public-date-range-picker>

					<seira-public-select-variavel-estacoes-automaticas
						class="col-12 col-md-3 col-lg-2"
						style="margin-right: 10px"
						[clearable]="false"
						formControlName="variavel" />
				</div>

				<ng-container *ngIf="mostrarSelectsMapaHistorico">
					<seira-public-select
						[options]="variaveisMapa"
						optionLabel="label"
						optionValue="value"
						style="width: 100%"
						label="Variável"
						placeholder="Selecione uma variável"
						[formControlName]="FormularioRelatorio.VARIAVEL_MAPA" />

					<seira-public-select
						[options]="tiposPeriodos"
						style="width: 100%"
						optionValue="value"
						optionLabel="name"
						label="Tipo de período"
						placeholder="Selecione um tipo de período"
						formControlName="tipoPeriodo" />

					<div *ngIf="mostrarDateRangePickerMes" class="mt-1">
						<seira-public-date-picker
							[id]="'dataInicio'"
							style="width: 100%"
							label="Data de início"
							placeholder="Início"
							controlName="dataInicio"
							datePickerType="month" />

						<seira-public-date-picker
							[id]="'dataFim'"
							style="width: 100%"
							label="Data de fim"
							placeholder="Selecione uma data"
							controlName="dataFim"
							[minDate]="calcularDataInicio"
							[maxDate]="calcularDataFim"
							datePickerType="month" />
					</div>
					<div *ngIf="mostrarDatePickerAno" class="mt-1">
						<seira-public-date-picker
							style="width: 100%"
							label="Período"
							placeholder="Selecione um período"
							controlName="periodo"
							datePickerType="year" />
					</div>
				</ng-container>

				<div class="mt-3">
					<ng-container *ngTemplateOutlet="botaoGerarRelatorio"></ng-container>
				</div>
			</div>
			<div class="col-lg-6">
				<ng-container #dynamicComponentContainer></ng-container>
			</div>
		</div>
		<ng-template #botaoGerarRelatorio>
			<seira-public-button
				[disabledButton]="form.invalid || !instanceRelatorio"
				[loading]="loadingForm"
				(clicked)="gerarRelatorio()"
				>Gerar relatório</seira-public-button
			>
		</ng-template>
	</form>
	<div class="row">
		<div class="col-lg-6">
			<seira-info-gerais
				id="informacoes-gerais"
				[textosInformativos]="informacoesGerais"></seira-info-gerais>
		</div>
	</div>
</div>
