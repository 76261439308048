import kriging from './kriging';
import L from 'leaflet';
import {
	VariavelMeteorologica,
	variavelParametros,
} from './intepolacao-parametros';
import { DesvioDTO } from '@modulos/home/submodulos/dados-meteorologicos/interfaces/desvioDTO';

export interface LegendaCor {
	cor: string;
	valorMin: number;
	valorMax: number;
	unidade: string;
	variavel?: string;
	municipio?: string;
	infoAdicional: string;
}

export interface LatLngValue extends ValorPorLocalizacao {
	tipoPCD: string | null;
	municipio: string;
	nomePosto: string;
	valorMin: number;
	valorMax: number;
}

export interface ValorPorNdc {
	nomePosto: string;
	lat: number;
	lng: number;
	precipitacao: number;
	value: number;
}

export interface ValoresBusca {
	municipio: string;
	lat: number;
	lng: number;
	value: number;
	tipoValor: TipoValor;
	unidade: string;
}
export enum TipoValor {
	CLIMATOLOGIA = 'Climatologia',
	Desvio = 'Desvio',
}
export interface ValorPorLocalizacao {
	lat: number;
	lng: number;
	value: number;
}

export interface ValorDesvioInterpolacao extends ValorPorLocalizacao {
	desvio: keyof DesvioDTO;
	nomeMunicipio: string;
}

export interface ValorPorMesoregiao extends ValorPorLocalizacao {
	mesoregiao: string;
	nomePosto: string;
	isPrecipitacao: boolean;
}

export interface ValorPorPosto extends ValorPorLocalizacao {
	nomePosto: string;
	isPrecipitacao: boolean;
}
export type ValoresInterpolacao =
	| LatLngValue
	| ValorPorMesoregiao
	| ValorPorLocalizacao
	| ValorDesvioInterpolacao
	| ValorPorNdc;

export interface LatLngDto {
	lat: number;
	lng: number;
	value: number;
}
export interface InterpolacaoCanvasBounds {
	canvas: HTMLCanvasElement;
	latLngBounds: L.LatLngBounds;
	valores: ValoresInterpolacao[];
	legenda: LegendaCor[];
	variograma: any;
}

export function gerarLegenda(
	variavel: VariavelMeteorologica,
	intervalos: number[],
	regiao: any
): any[] {
	const parametros = variavelParametros[variavel];

	if (!parametros) {
		console.error(`Parâmetros não encontrados para a variável: ${variavel}`);
		return [];
	}

	const {
		unidade,
		valorMax: paramValorMax,
		valorMin: paramValorMin,
		colormap,
	} = parametros;

	const infoAdicional =
		'infoAdicional' in parametros ? parametros.infoAdicional : [];

	const valorMax = paramValorMax ?? 1200;
	const valorMin = paramValorMin ?? 0;

	let colorMap = colormap;

	if (intervalos) {
		if (variavel === 'precipitacao') {
			if (intervalos[0] === 0) {
				colorMap = colorMap.slice(4, intervalos.length + 3);
				colorMap = ['#ffffff'].concat(colorMap);
			} else {
				colorMap = colorMap.slice(4, intervalos.length + 4);
			}
		}

		return colorMap.map((value, i) => ({
			cor: value,
			valorMin: intervalos[i],
			valorMax: (intervalos[i + 1] - 0.1).toFixed(1).replace('.', ','),
			infoAdicional: infoAdicional[i] ?? '',
			unidade,
			regiao,
		}));
	}

	const range = valorMax - valorMin;
	const step = range / (colorMap.length - 1);

	return colorMap.slice(0, -1).map((value, i) => ({
		cor: value,
		valorMin: (valorMin + step * i).toFixed(0),
		valorMax: (valorMin + step * (i + 1)).toFixed(0),
		infoAdicional: infoAdicional[i] ?? '',
		unidade,
	}));
}

export function criarCanvasInterpolacao(
	valores: ValoresInterpolacao[],
	variavel: VariavelMeteorologica,
	regiao: number[][][],
	width: number,
	isValoresResumido = false
): InterpolacaoCanvasBounds {
	const params = variavelParametros[variavel];
	if (!params) {
		console.error(`Parâmetros não encontrados para a variável: ${variavel}`);
		return {
			canvas: document.createElement('canvas'),
			latLngBounds: L.latLngBounds([]),
			legenda: [],
			valores: [],
			variograma: {},
		};
	}

	const { sigma2, alpha, model, colormap, valorMin, valorMax, intervalos } =
		params;

	let variograma: any;

	if (isValoresResumido) {
		const listaLatLngDto: LatLngDto[] = valores.map(item => ({
			lng: item.lng,
			lat: item.lat,
			value: item.value,
		}));
		variograma = kriging.train(
			listaLatLngDto.map(value => value.value),
			listaLatLngDto.map(value => value.lng),
			listaLatLngDto.map(value => value.lat),
			model,
			sigma2,
			alpha
		);
	} else {
		variograma = kriging.train(
			valores.map(value => value.value),
			valores.map(value => value.lng),
			valores.map(value => value.lat),
			model,
			sigma2,
			alpha
		);
	}

	if (!variograma || !variograma.M || !variograma.K) {
		console.error('Variograma não gerado corretamente:', variograma);
		return {
			canvas: document.createElement('canvas'),
			latLngBounds: L.latLngBounds([]),
			legenda: [],
			valores: [],
			variograma: {},
		};
	}

	const grid = kriging.grid(regiao, variograma, width);

	const valorMaxAtual = valorMax ?? grid?.zlim?.[1] ?? 1200;
	const valorMinAtual = valorMin ?? grid?.zlim?.[0] ?? 0;

	if (!grid || !grid.data || !Array.isArray(grid.data)) {
		console.error('Grid não gerado corretamente:', grid);
		return {
			canvas: document.createElement('canvas'),
			latLngBounds: L.latLngBounds([]),
			legenda: [],
			valores: [],
			variograma: {},
		};
	}

	const canvas = document.createElement('canvas');
	canvas.width = grid.data.length;
	canvas.height = grid.data[0].length;

	const intervaloExpandido = kriging.expandirIntervalo(
		valorMaxAtual,
		intervalos,
		1.3,
		2
	);

	let colorMapAtual = colormap;

	if (variavel === 'precipitacao') {
		if (intervaloExpandido[0] === 0) {
			colorMapAtual = colorMapAtual.slice(4, intervaloExpandido.length + 3);
			colorMapAtual = ['#ffffff'].concat(colorMapAtual);
		} else {
			colorMapAtual = colorMapAtual.slice(4, intervaloExpandido.length + 4);
		}
	}

	const plot = kriging.plot(
		canvas,
		grid,
		grid.xlim,
		grid.ylim,
		colorMapAtual,
		valorMinAtual,
		valorMaxAtual,
		intervaloExpandido
	);

	return {
		canvas,
		latLngBounds: L.latLngBounds([
			[grid.ylim[0], grid.xlim[0]],
			[grid.ylim[1], grid.xlim[1]],
		]),
		legenda: gerarLegenda(variavel, intervaloExpandido, regiao),
		valores: valores,
		variograma,
	};
}
