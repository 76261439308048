import { HttpClient } from '@angular/common/http';
import {
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	ChuvaEstado,
	DadosTabela,
} from '@home/submodulos/dados-meteorologicos/interfaces/chuva-estado';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { MapaPluviometriaInterpoladaService } from '@home/submodulos/dados-meteorologicos/services/mapa-pluviometria-interpolada.service';
import { Select } from '@layout/interfaces/select';
import { TipoPrecipitacao } from '@shared/enum';
import { GeoJSON } from '@shared/interfaces/geometry';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { capitalizeFirstLetter, numberToBrNumber, verificaSePDF } from '@utils';
import { DocumentExporter } from '@utils/document-exporter';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	ValorPorMesoregiao,
} from '@utils/interpolacao/interpolacao';
import * as pdfseira from '@utils/pdf-seira';
import { format, formatISO } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { FeatureCollection, Polygon } from 'geojson';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { RelatoriosService } from '../../../services';
import * as ExcelTable from 'mr-excel';
import { DateTimeUtils } from '@utils/datetime-util';
import Position = GeoJSON.Position;

@Component({
	selector: 'seira-mapa-pluviometria-interpolada',
	templateUrl: './mapa-pluviometria-interpolada.component.html',
	styleUrls: ['./mapa-pluviometria-interpolada.component.scss'],
})
export class MapaPluviometriaInterpoladaComponent implements OnInit, OnDestroy {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	interpolacao?: InterpolacaoCanvasBounds;
	carregando = true;
	valores: ChuvaEstado[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	medicoes: ChuvaEstado[] = [];
	dados: DadosTabela[] = [];
	loading = false;
	regiao?: Position[][];
	descricaoRelatorio =
		'Define-se como pluviometria interpolada o volume total de precipitação registrado em diferentes regiões de um estado específico durante um determinado período.';

	periodoTitulo = '';
	precipitacaoTitle = '';
	OpcoesTipoVisualizacao: OptionRadio<'mapa' | 'tabela'>[] = [
		{ label: 'Mapa', value: 'mapa' },
		{ label: 'Tabela', value: 'tabela' },
	];
	relatorioService = inject(RelatoriosService);

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF(this.medicoes);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV(this.medicoes);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT(this.medicoes);
			},
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	constructor(
		private MapaPluviometriaInterpoladaService: MapaPluviometriaInterpoladaService,
		private httpClient: HttpClient,
		private fb: FormBuilder
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.initialValues();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PRECIPITACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}

	calcularMedia(valores: number[]): number {
		const soma = valores.reduce((total, valor) => total + valor, 0);
		return soma / valores.length;
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PRECIPITACAO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		const dataInicio: Date | null = this.inputs.form.get(
			FormularioRelatorio.DATA_INICIO
		)?.value;
		const dataFim: Date | null = this.inputs.form.get(
			FormularioRelatorio.DATA_FIM
		)?.value;

		if (!dataInicio || !dataFim || !this.postos.length) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregando = true;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			'mensal'
		);
		this.precipitacaoTitle = this.inputs.form
			.get('precipitacao')
			?.value.toLowerCase();

		this.MapaPluviometriaInterpoladaService.buscarMapaChuvaEstado(
			formatISO(new Date(dataInicio)),
			formatISO(new Date(dataFim))
		).subscribe({
			next: (resp: ChuvaEstado[]) => {
				this.relatorioService.verificaExistenciaDados(resp);

				if (this.dados.length === 0) {
					const valoresAgrupados: { [mesorregiao: string]: ChuvaEstado[] } = {};
					resp.forEach(valor => {
						if (!valoresAgrupados[valor.mesorregiao]) {
							valoresAgrupados[valor.mesorregiao] = [];
						}
						valoresAgrupados[valor.mesorregiao].push(valor);
					});

					for (const mesorregiao in valoresAgrupados) {
						if (mesorregiao in valoresAgrupados) {
							const valores = valoresAgrupados[mesorregiao];
							const mediaAcumulado = this.calcularMedia(
								valores.map(v => v.acumulado)
							);
							const mediaMedia = this.calcularMedia(valores.map(v => v.media));
							const mediaDesvio =
								((mediaAcumulado - mediaMedia) / mediaMedia) * 100;

							this.dados.push({
								mesorregiao: mesorregiao,
								acumulado: mediaAcumulado,
								media: mediaMedia,
								desvio: mediaDesvio,
							});
						}
					}
				}
				this.medicoes = resp;

				this.interpolarValores();
				this.inputs.setLoading(false);
			},
			error: erro => {
				this.inputs.setLoading(false);
				this.carregando = false;
				console.error('erro', erro);
			},
		});
	}

	interpolarValores() {
		this.carregando = true;
		if (this.medicoes.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;
				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
		}
	}

	criarImagemInterpolacao() {
		const tipoPrecipitacao = this.inputs.form.get('precipitacao')?.value;
		let variavel: VariavelMeteorologica = 'precipitacao';

		const valores: ValorPorMesoregiao[] = this.medicoes.map(value => {
			let valor: number;
			if (tipoPrecipitacao === TipoPrecipitacao.ACUMULADA) {
				valor = value.acumulado;
			} else if (tipoPrecipitacao === TipoPrecipitacao.MEDIA) {
				valor = value.media;
			} else {
				valor = value.desvio;
				variavel = 'desvioAcumulado';
			}

			return {
				lat: value.latitude,
				lng: value.longitude,
				value: valor,
				mesoregiao: value.mesorregiao,
				nomePosto: value.nomePosto,
				isPrecipitacao: variavel === 'precipitacao',
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valores,
				variavel,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	getDadosTabelaChuvaEstado(dados: ChuvaEstado[], isPdf: boolean) {
		const tableData: any[][] = [];
		const colunas = [
			{ text: 'Nome do posto', fillColor: '#DCDCDC' },
			{ text: 'Mesorregião', fillColor: '#DCDCDC' },
			{ text: 'Chuva observada (mm)', fillColor: '#DCDCDC' },
		];

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: ChuvaEstado) => {
			const rowData = [
				capitalizeFirstLetter(item.nomePosto.toLowerCase()),
				capitalizeFirstLetter(item.mesorregiao.toLowerCase()),
				numberToBrNumber(item.acumulado.toFixed(2)),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	retornarPluviometriaInterpoladaParaXLSX(dados: ChuvaEstado[]) {
		const tableData: any[] = [];

		dados.forEach(dado => {
			tableData.push({
				nomePosto: dado.nomePosto,
				mesorregiao: dado.mesorregiao,
				acumulado: numberToBrNumber(dado.acumulado.toFixed(2)),
			});
		});

		return tableData;
	}

	async exportarPDF(medicoes?: ChuvaEstado[]) {
		const elementos = ['chuvaEstado', 'iapm', 'precipitacao'];
		const imagens = [];

		for (const elemento of elementos) {
			const elementoHtml = document.getElementById(elemento);
			if (elementoHtml) {
				const img = await html2canvas(elementoHtml).then(canvas =>
					canvas.toDataURL('image/png')
				);
				imagens.push({ imagem: img, titulo: '' });
			}
		}
		format(new Date(), "dd 'de' MMMM 'de' yyyy HH'h'mm", {
			locale: ptBrLocale,
		});
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'portrait'
		);

		documentDefinition.content.push([
			{
				text: `Pluviometria interpolada (${this.precipitacaoTitle}) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
		]);

		for (const imagem of imagens) {
			if (imagem.imagem) {
				documentDefinition.content.push(
					{
						text: imagem.titulo,
						alignment: 'center',
						margin: [0, 15, 0, 5],
						fontSize: 12,
					},
					{
						image: imagem.imagem,
						width: 515,
						heigth: 250,
						alignment: 'center',
					}
				);
			}
		}

		if (medicoes) {
			documentDefinition.content.push({
				table: {
					widths: ['35%', '35%', '30%'],
					body: this.getDadosTabelaChuvaEstado(medicoes, true),
					layout: {
						noWrap: false,
						fontSize: 5,
					},
				},

				marginTop: 20,
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarTXT(medicoes: ChuvaEstado[]) {
		const tableData = this.getDadosTabelaChuvaEstado(medicoes, false);
		const txtContent = tableData.map(row => row.join('\t')).join('\n');
		DocumentExporter.gerarTXT(
			txtContent,
			`Pluviometria interpolada (${this.precipitacaoTitle}) - ${this.periodoTitulo}`
		);
	}

	exportarCSV(medicoes: ChuvaEstado[]) {
		const tableData = this.getDadosTabelaChuvaEstado(medicoes, false);
		DocumentExporter.gerarCSV(
			tableData,
			`Pluviometria interpolada (${this.precipitacaoTitle}) - ${this.periodoTitulo}`
		);
	}

	async exportarXLSX() {
		const nomeArquivo = `Pluviometria interpolada (${this.precipitacaoTitle}) - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{ label: 'nomePosto', text: 'Nome do posto', size: 24 },
			{ label: 'mesorregiao', text: 'Mesoregião', size: 24 },
			{ label: 'acumulado', text: 'Chuva observada (mm)', size: 24 },
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPluviometriaInterpoladaParaXLSX(this.medicoes),
					columns: [
						{ key: 'nomePosto' },
						{
							key: 'latitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'longitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'acumulado',
							style: { numFmt: '0.0' },
						},
						{
							key: 'desvio',
							style: { numFmt: '0' },
						},
						{
							key: 'estacaoId',
							style: { numFmt: '0' },
						},
						{
							key: 'media',
							style: { numFmt: '0' },
						},
						{
							key: 'mesoregião',
							style: { numFmt: '0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 8,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
