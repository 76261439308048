import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';

import { Validators } from '@angular/forms';
import { HighStockComponent } from '@componentes/high-stock/high-stock.component';
import { optionsChartObservadoDesvioClimatologia } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada-desvio-climatologia/chartOptions';
import { format } from 'date-fns-tz';
import * as Highcharts from 'highcharts/highstock';
import { Subject, takeUntil } from 'rxjs';

import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { criarImagemBase64FromChart } from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { RelatorioDesvio } from '@home/submodulos/dados-meteorologicos/interfaces';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services';

@Component({
	selector: 'seira-grafico-pluviometria-observado-desvio-climatologia',
	templateUrl:
		'./grafico-pluviometria-observado-desvio-climatologia.component.html',
	styleUrls: [
		'./grafico-pluviometria-observado-desvio-climatologia.component.scss',
	],
})
export class GraficoPluviometriaObservadoDesvioClimatologiaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild(HighStockComponent, { static: false })
	highStockComponent: HighStockComponent;
	inputs = inject(INPUTS_RELATORIOS);
	chart: Highcharts.Chart;
	loading = false;
	gerou = false;
	_destroyed = new Subject();
	tipoDesvio = '';
	resultado = false;
	chartOptions: Highcharts.Options = optionsChartObservadoDesvioClimatologia(
		this.inputs.form.get(FormularioRelatorio.TIPO)?.value ===
			'PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA'
	);

	descricaoRelatorio = '';
	descricaoRelatorioMilimetro =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. ' +
		'O desvio em milímetros refere-se à diferença entre a quantidade de chuva registrada e a sua climatologia para o mesmo período, expressa em milímetros em uma determinada localidade. ' +
		'A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';
	descricaoRelatorioPorcentagem =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. ' +
		'O desvio em porcentagem refere-se à variação da precipitação em relação à sua climatologia, expressa como uma porcentagem para indicar se o volume de chuva foi superior ou inferior ao esperado. ' +
		'A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
	];
	periodoTitulo = '';

	constructor(
		private toastr: ToastrService,
		private readonly relatorioService: RelatoriosService
	) {}

	ngOnInit() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });
		this.tipoDesvio =
			this.inputs.form.get(FormularioRelatorio.TIPO)?.value ===
			'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA'
				? 'milimetro'
				: 'porcentagem';

		this.descricaoRelatorio =
			this.tipoDesvio === 'milimetro'
				? this.descricaoRelatorioMilimetro
				: this.descricaoRelatorioPorcentagem;

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	gerarRelatorio() {
		this.loading = true;
		this.inputs.setLoading(true);
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;

		const diaInicio =
			periodoAgrupamento !== 'anual'
				? dataInicio
				: new Date(`${new Date(dataInicio).getFullYear()}-01-01T00:00:00`);
		const diaFim =
			periodoAgrupamento !== 'anual'
				? dataFim
				: new Date(`${new Date(dataFim).getFullYear()}-12-31T00:00:00`);

		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		let id = null;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoAgrupamento
		);

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				id = this.inputs.form.get('estacao')?.value;
				break;
			case 'MICRORREGIAO':
				id = this.inputs.form.get('microrregiao')?.value;
				break;
			case 'MESORREGIAO':
				id = this.inputs.form.get('mesorregiao')?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.inputs.form.get('regiao')?.value;
				break;
			case 'BACIA':
				id = this.inputs.form.get('bacia')?.value;
				break;
			case 'MUNICIPIO':
				id = this.inputs.form.get('municipio')?.value;
				break;
		}

		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento,
				idEntidade: id,
				dataFim: new Date(diaFim),
				dataInicio: new Date(diaInicio),
				periodo: periodoAgrupamento.toUpperCase(),
				incluirDetalhes: false,
			})
			.subscribe({
				next: preciptacoesAgrupadas => {
					this.configurarChart(preciptacoesAgrupadas, periodoAgrupamento);
					this.chartOptions = {
						...this.chartOptions,
						exporting: {
							enabled: true,
							buttons: {
								contextButton: {
									menuItems: [
										'viewFullscreen',
										'separator',
										'downloadPNG',
										'downloadJPEG',
									],
								},
							},
							filename: gerarFilenameGrafico(
								`grafico_pluviometria_observada_desvio_${
									this.tipoDesvio === 'porcentagem' ? '%' : 'mm'
								}_${this.inputs.form
									.get(FormularioRelatorio.AGRUPAMENTO)
									?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
							),
						},
					};
					this.resultado = !!preciptacoesAgrupadas[0];
				},
				error: () => {
					this.inputs.setLoading(false);
					this.resultado = false;
					this.loading = false;
					this.gerou = false;
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	extrairDatasPrecipitacao(
		preciptacaoAgrupada: RelatorioDesvio,
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacaoAgrupada.desvios
					.filter((el: any) => el.data)
					.map((el: any) => {
						const [ano, mes, dia] = el.data!.split('-');
						return new Date(+ano, (+mes || 1) - 1, +dia || 1);
					})
					.sort((a: any, b: any) => +a - +b)
					.map((data: any) => {
						let formato = 'MM/yyyy';
						if (periodoAgrupamento === 'anual') {
							formato = 'yyyy';
						}
						return format(data, formato, {
							timeZone: 'America/Sao_Paulo',
						});
					})
			),
		];
	}

	configurarChart(
		preciptacoesAgrupadas: any[],
		periodoAgrupamento: PeriodosLabel
	) {
		const sufixoTipo = this.tipoDesvio === 'milimetro' ? 'mm' : '%';
		const categorias = ['Observado', `Desvio ${sufixoTipo}`, 'Climatologia'];
		const dataSeries: string[] = this.extrairDatasPrecipitacao(
			preciptacoesAgrupadas[0],
			periodoAgrupamento
		);

		this.chartOptions.title = {
			text: `Pluviometria observada/climatologia(mm)/desvio(${sufixoTipo}) - ${this.periodoTitulo}`,
			align: 'center',
			style: {
				fontSize: '18px',
			},
		};

		this.chartOptions.series = categorias.map(serie => {
			let resultado: any[] = [];
			const y = serie !== `Desvio ${sufixoTipo}` ? 0 : 1;
			const sufixo = serie === 'Desvio %' ? '%' : 'mm';
			const tipo = serie == `Desvio ${sufixoTipo}` ? 'spline' : 'column';

			if (periodoAgrupamento === 'mensal') {
				if (serie === 'Observado') {
					let i = 0;
					resultado = preciptacoesAgrupadas[0].desvios.map((dados: any) => {
						const retorno = [dataSeries[i], dados?.chuva];
						i += 1;
						return retorno;
					});
				} else if (serie === 'Climatologia') {
					let i = 0;
					resultado = preciptacoesAgrupadas[0].desvios.map((dados: any) => {
						const retorno = [dataSeries[i], dados?.climatologia];
						i += 1;
						return retorno;
					});
				} else {
					let i = 0;
					resultado = preciptacoesAgrupadas[0].desvios.map((dados: any) => {
						const retorno = [
							dataSeries[i],
							this.tipoDesvio === 'milimetro'
								? dados?.desvioMilimetro
								: dados?.desvioPorcentagem,
						];
						i += 1;
						return retorno;
					});
				}
			} else {
				const dadosAgrupados = dataSeries.map(ano => {
					const dadosDoAno = preciptacoesAgrupadas[0].desvios.filter(
						(item: any) => item.data.startsWith(ano)
					);

					const chuvaAnual = dadosDoAno.reduce(
						(total: any, item: any) => total + item.chuva,
						0
					);
					const climatologiaAnual = dadosDoAno.reduce(
						(total: any, item: any) => total + item.climatologia,
						0
					);
					const desvioMilimetroAnual = dadosDoAno.reduce(
						(total: any, item: any) => total + item.desvioMilimetro,
						0
					);
					const desvioPorcentagemAnual =
						climatologiaAnual !== 0
							? (desvioMilimetroAnual / climatologiaAnual) * 100
							: 0;

					return {
						ano,
						chuvaAnual,
						climatologiaAnual,
						desvioMilimetroAnual,
						desvioPorcentagemAnual,
					};
				});
				if (serie === 'Observado') {
					resultado = dadosAgrupados.map(dados => [
						dados.ano,
						dados.chuvaAnual,
					]);
				} else if (serie === 'Climatologia') {
					resultado = dadosAgrupados.map(dados => [
						dados.ano,
						dados.climatologiaAnual,
					]);
				} else {
					resultado = dadosAgrupados.map(dados => [
						dados.ano,
						this.tipoDesvio === 'milimetro'
							? dados.desvioMilimetroAnual
							: dados.desvioPorcentagemAnual,
					]);
				}
			}

			return {
				name: serie,
				type: tipo,
				tooltip: {
					valueDecimals: 1,
					valueSuffix: sufixo,
				},
				yAxis: y,
				marker: {
					enabled: y,
					radius: 5,
				},
				zIndex: y * 10,
				data: resultado,
			} as Highcharts.SeriesColumnOptions;
		});

		this.chartOptions.xAxis = {
			categories: dataSeries,
		};
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (!this.chart || !this.resultado) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: `Relatório - Pluviometria observada/climatologia (mm) / desvio (${
					this.tipoDesvio === 'porcentagem' ? '%' : 'mm'
				}) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}
}
