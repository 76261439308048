export enum TipoEstacao {
	PCD = 'PCD',
	PLUVIOMETRO_CONVENCIONAL = ' PCO',
	FLUVIOMETRO = 'Fluviômetro',
}
export enum TipoPCD {
	AGROMETEOROLOGICA = 'Agrometeorológica',
	METEOROLOGICA = 'Meteorológica',
}
export enum TipoEstacaoBadgeBg {
	PCD = 'bg-info',
	PLUVIOMETRO_CONVENCIONAL = 'bg-secondary',
	FLUVIOMETRO = '',
}
