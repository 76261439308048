import { Component, inject, OnDestroy } from '@angular/core';
import {
	Estacao,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	QuantisRegiaoAnualTableValores,
	QuantisRegiaoMensalTableValores,
	QuantisResponse,
	Relatorios,
} from '../../../interfaces';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { QuantisMicrorregiaoService } from '../../../services';
import { Subject, takeUntil } from 'rxjs';
import { documentDefinitions } from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Content } from 'pdfmake/interfaces';
import { CondicaoMap } from '../../../utils';
import { isNotNuloOuUndefined } from '@utils';
import { Meses } from '../../../enum';
import { DocumentExporter } from '@utils/document-exporter';
import { Validators } from '@angular/forms';

@Component({
	selector: 'seira-pluviometro-tabela-quantis',
	templateUrl: './pluviometro-tabela-quantis.component.html',
	styleUrls: ['./pluviometro-tabela-quantis.component.scss'],
})
export class PluviometroTabelaQuantisComponent
	implements OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio = '';
	descricaoRelatorioMensal =
		'Define-se como desvio (mm), a diferença entre a quantidade de chuva registrada e a sua climatologia para um mesmo período, em um determinado local ou região.';

	descricaoRelatorioAnual =
		'Define-se como quantis (%) representa a variação da precipitação em relação à climatologia expresso em porcentagem, indicando se o acumulado de chuva foi superior ou inferior à climatologia.';

	inputs = inject(INPUTS_RELATORIOS);
	estacoes?: Estacao[] | undefined;
	isLoading = true;
	tableData?: Relatorios<
		QuantisRegiaoMensalTableValores | QuantisRegiaoAnualTableValores
	>;
	city = '';
	year = 0;
	isAnual = false;
	quantisType = '';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];
	private _destroyed = new Subject();

	constructor(private readonly quantisService: QuantisMicrorregiaoService) {
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		if (this.inputs.form?.invalid) {
			return;
		}
		const estacao = this.inputs.form?.get('estacao')?.value;
		const periodo = this.inputs.form?.get('periodo')?.value;
		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);

		this.descricaoRelatorio =
			tipoInput?.value === 'QUANTIS_MENSAL'
				? this.descricaoRelatorioMensal
				: this.descricaoRelatorioAnual;

		if (!estacao || !periodo || !this.estacoes?.length) {
			return;
		}
		this.handleFetchQuantisByType(tipoInput?.value, estacao, periodo);
		tipoInput?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: values => {
				this.handleFetchQuantisByType(values, estacao, periodo);
			},
		});
	}

	handleFetchQuantisByType(type: string, estacao: number, periodo: string) {
		if (type === 'QUANTIS_MENSAL') {
			this.quantisType = 'Quantis mensal';
			this.handleFetchQuantisMensal(estacao, periodo);
		}
		if (type === 'QUANTIS_ANUAL') {
			this.quantisType = 'Quantis anual';
			this.handleFetchQuantilAnual(estacao, periodo);
		}
	}

	handleFetchQuantisMensal(estacao: number, periodo: string) {
		this.isAnual = false;
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.quantisService
			.handleFetchQuantisMensalTableByEstacaoId(
				estacao,
				new Date(periodo).getFullYear()
			)
			.subscribe({
				next: val => {
					this.city = val.municipio!;
					this.year = val.ano;
					this.tableData = this.handleFormatToTableData(val);
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.isLoading = false;
				},
			});
	}

	handleFetchQuantilAnual(estacao: number, periodo: string) {
		this.isAnual = true;
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.quantisService
			.handleFetchQuantilAnualTableByEstacaoId(
				estacao,
				new Date(periodo).getFullYear()
			)
			.subscribe({
				next: val => {
					this.city = val.municipio!;
					this.year = val.ano;
					this.tableData = this.handleFormatToTableData(val);
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.isLoading = false;
				},
			});
	}

	handleFormatToTableData(
		originalData: QuantisResponse<
			QuantisRegiaoMensalTableValores[] | QuantisRegiaoAnualTableValores[]
		>
	): Relatorios<
		QuantisRegiaoMensalTableValores | QuantisRegiaoAnualTableValores
	> {
		return {
			data: originalData.quantis,
			municipio: originalData.municipio!,
			ano: originalData.ano.toString(),
		};
	}

	async exportarPDF() {
		const documentDefinition = await documentDefinitions();
		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);
		if (tipoInput?.value === 'QUANTIS_ANUAL') {
			documentDefinition.content = this.handleGenerateQuantilAnualPDFContent();
		}
		if (tipoInput?.value === 'QUANTIS_MENSAL') {
			documentDefinition.content =
				this.handleGenerateQuantisMensaisPDFContent();
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);
		if (tipoInput?.value === 'QUANTIS_ANUAL') {
			const csvContent = this.handleGenerateAnualExportTable(
				this.tableData as Relatorios<QuantisRegiaoAnualTableValores>
			);
			DocumentExporter.gerarCSV(
				csvContent,
				`relatorio-quantis-anual-${this.tableData?.municipio
					.toLocaleLowerCase()
					.replaceAll(' ', '-')}-${this.tableData?.ano}`
			);
		}
		if (tipoInput?.value === 'QUANTIS_MENSAL') {
			const csvContent = this.handleGenerateMensalExportTable(
				this.tableData as Relatorios<QuantisRegiaoMensalTableValores>
			);
			DocumentExporter.gerarCSV(
				csvContent,
				`relatorio-quantis-mensal-${this.tableData?.municipio
					.toLocaleLowerCase()
					.replaceAll(' ', '-')}-${this.tableData?.ano}`
			);
		}
	}

	exportarTXT() {
		let txtData = '';
		txtData += `Município: ${this.tableData?.municipio} \n`;
		txtData += `Ano: ${this.tableData?.ano} \n\n`;

		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);
		if (tipoInput?.value === 'QUANTIS_ANUAL') {
			txtData += this.handleGenerateAnualTxtDataExport(
				this.tableData as Relatorios<QuantisRegiaoAnualTableValores>
			);
			DocumentExporter.gerarTXT(
				txtData,
				`relatorio-quantis-anual-${this.tableData?.municipio
					.toLocaleLowerCase()
					.replaceAll(' ', '-')}-${this.tableData?.ano}`
			);
		}
		if (tipoInput?.value === 'QUANTIS_MENSAL') {
			txtData += this.handleGenerateMensalTxtDataExport(
				this.tableData as Relatorios<QuantisRegiaoMensalTableValores>
			);
			DocumentExporter.gerarTXT(
				txtData,
				`relatorio-quantis-mensal-${this.tableData?.municipio
					.toLocaleLowerCase()
					.replaceAll(' ', '-')}-${this.tableData?.ano}`
			);
		}
	}

	private handleGenerateQuantilAnualPDFContent(): Content {
		const response: Content = [
			{
				text: `Relatório - Quantis Anuais - ${this.tableData?.municipio} - ${this.tableData?.ano}`,
				fontSize: 12,
				alignment: 'center',
				margin: [0, 10],
			},
		];

		if (!this.tableData?.data.length) {
			response.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		response.push({
			table: {
				body: this.handleGenerateAnualExportTable(
					this.tableData as Relatorios<QuantisRegiaoAnualTableValores>
				),
			},
		});
		return response;
	}

	private handleGenerateQuantisMensaisPDFContent(): Content {
		const response: Content = [
			{
				text: `Relatório - Quantis Mensal - ${this.tableData?.municipio} - ${this.tableData?.ano}`,
				fontSize: 12,
				alignment: 'center',
				margin: [0, 10],
			},
		];

		if (!this.tableData?.data.length) {
			response.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		response.push({
			table: {
				body: this.handleGenerateMensalExportTable(
					this.tableData as Relatorios<QuantisRegiaoMensalTableValores>
				),
			},
		});
		return response;
	}

	private handleGenerateAnualExportTable(
		rawData: Relatorios<QuantisRegiaoAnualTableValores>
	) {
		const tableData: (string | number)[][] = [];

		tableData.push(['Tipo', 'Valor (mm)']);

		rawData.data.forEach(data =>
			tableData.push([
				CondicaoMap.get(data.tipo)!,
				this.formatNumber(data.valor),
			])
		);

		return tableData;
	}

	private handleGenerateMensalExportTable(
		rawData: Relatorios<QuantisRegiaoMensalTableValores>
	) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Tipo',
			...Array.from({ length: 12 }, (_, i) => `${Meses[i]} (mm)`),
		]);

		rawData.data.forEach(data =>
			tableData.push([
				CondicaoMap.get(data.tipo)!,
				...data.valores.map(valor => this.formatNumber(valor)),
			])
		);

		return tableData;
	}

	private handleGenerateAnualTxtDataExport(
		rawData: Relatorios<QuantisRegiaoAnualTableValores>
	) {
		return rawData.data
			.map(
				data =>
					`${CondicaoMap.get(data.tipo)!}: ${this.formatNumber(
						data.valor
					)} (mm) \n`
			)
			.reduce((prev, current) => prev + current);
	}

	private handleGenerateMensalTxtDataExport(
		rawData: Relatorios<QuantisRegiaoMensalTableValores>
	) {
		return rawData.data
			.map(data => {
				let response = `${CondicaoMap.get(data.tipo)!}: \n`;
				response += data.valores
					.map((valor, i) => `${Meses[i]}: ${this.formatNumber(valor)} (mm) \n`)
					.reduce((prev, curr) => prev + curr);

				return response + '\n';
			})
			.reduce((prev, current) => prev + current);
	}

	private formatNumber(value: number): string {
		if (value === 0) return Number(0).toFixed(1).replace('.', ',');
		return isNotNuloOuUndefined(value)
			? value.toFixed(1).replace('.', ',')
			: '-';
	}
}
